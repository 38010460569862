import { Col, Layout, Row, Tooltip } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { CheckboxExclamation, Checked, UnCheck } from "../../../../assets/svg";
import { Table, ThongTinThanhToanBenhNhan } from "../../../../components";
import { formatCurrency, isEmptyObject, rid } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import { layDsDichVuChuaThanhToan, layTatCaDvGoiTrangThai } from "../apisDvThanhToan/apisDvThanhToan";
import style from "../style.module.less";

const TongDichVu = (
  {
    patientInfo = {},
    checkRoleArr = {
      THEM: false,
      SUA: false,
      XOA: false,
      TAI_XUONG: false,
      HUY: false,
      IN_PDF: false,
      XUAT_EXCEL: false,
      XAC_NHAN: false,
      THUC_HIEN: false,
      THEM_MOI: false,
      THANH_TOAN: false,
      HOAN_TIEN: false,
      IN: false,
    }
  }) => {
  const [dataSource, setDataSource] = useState([]);
  const userProfile = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  const columnsTatCa = [
    {
      title: i18n.t(languageKeys.field_Ten_dich_vu),
      dataIndex: patientInfo.LICH_KHAM_ID ? "TEN_DICHVU" : "TEN_DV",
      key: "TEN_DICHVU",
      width: 500,
      render: (ten_dv, record) => {
        if (record.children) {
          return <b className={style["txt-highlight"]}>{ten_dv}</b>;
        } else {
          return <>{ten_dv}</>;
        }
      },
    },

    {
      title: i18n.t(languageKeys.field_don_gia),
      dataIndex: patientInfo.LICH_KHAM_ID ? "DON_GIA" : "DON_GIA_PHAN_BO",
      key: "DON_GIA",
      render: (money) => {
        return <span>{<> {formatCurrency(money, "đ", true)}</>} </span>;
      },
      width: 150,
    },
    {
      title: i18n.t(languageKeys.field_Mien_giam),
      dataIndex: "GIAM_TIEN",
      key: "GIAM_TIEN",
      render: (money) => {
        return <span>{<> {formatCurrency(money, "đ", true)}</>} </span>;
      },
      width: 150,
    },
    {
      title: i18n.t(languageKeys.field_So_luong),
      dataIndex: "SO_LUONG",
      key: "SO_LUONG",
      width: 120,
      render: (so_luong) => <div style={{ marginLeft: 18 }}>{so_luong}</div>,
    },
    {
      title: i18n.t(languageKeys.vien_phi_thanh_tien),
      dataIndex: "tong_tien",
      key: "tong_tien",
      render: (money, record) => {
        return record.children ? (
          <span className="green-txt">
            {money ? <> {formatCurrency(money, "đ", true)}</> : ""}{" "}
          </span>
        ) : (
          <span className="green-txt">
            {formatCurrency(
              record.THANH_TIEN_PHAN_BO
                ? record.THANH_TIEN_PHAN_BO - record.TONG_TIEN_GIAM
                : record.DON_GIA * record.SO_LUONG - record.TONG_TIEN_GIAM,
              "đ",
              true
            )}
          </span>
        );
      },
      width: 200,
    },
    {
      title: <span>{i18n.t(languageKeys.vienphi_da_thanh_toan)}</span>,
      dataIndex: "THANH_TOAN",
      key: "THANH_TOAN",
      width: 110,
      render: (status, record) => {
        if (record.TRANG_THAI === "HUY" && !!record.IS_HOAN_TIEN) {
          return (
            <Tooltip title={i18n.t(languageKeys.vienphi_da_hoan_tien)}>
              <CheckboxExclamation />
            </Tooltip>
          );
        } else if (status) {
          return (
            <div style={{}}>

              <Tooltip title={i18n.t(languageKeys.status_Da_thanh_toan)}>
                <Checked />
              </Tooltip>
            </div>
          );
        } else {
          return (
            <div style={{}}>
              <Tooltip title={i18n.t(languageKeys.status_Chua_thanh_toan)}>
                <UnCheck />
              </Tooltip>
            </div>
          );
        }
      },
    },
    {
      title: i18n.t(languageKeys.da_thuc_hien),
      dataIndex: "TRANG_THAI",
      key: "TRANG_THAI",
      width: 80,
      render: (status, record) => {
        if (record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN") {
          return (
            <div>
              <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
                <Checked />
              </Tooltip>
            </div>
          );
        }
        if (status) {
          if (patientInfo?.LOAI === "THANH_TOAN_MUA_GOI"
            ? (status === null || status === "CHUA_KHAM")
            : (record.TRANG_THAI_THUC_HIEN === "CHO_THUC_HIEN" || status === "CHO_XAC_NHAN" || status === "XAC_NHAN" || status === "CHO_KHAM")
          ) {
            return (
              <div style={{}}>
                <Tooltip title={i18n.t(languageKeys.chua_thuc_hien)}>
                  <UnCheck />
                </Tooltip>
              </div>
            );
          } else {
            return (
              <div style={{}}>
                <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
                  <Checked />
                </Tooltip>
              </div>
            );
          }
        } else {
          return <></>;
        }
      },
    },
  ];

  useEffect(() => {
    if (patientInfo && !isEmptyObject(patientInfo)) {
      layDsDichvu(patientInfo.BENH_NHAN_ID, patientInfo.NGAY, patientInfo.LICH_KHAM_ID, patientInfo.ID);
    }

    return () => {
      // setDataSource([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientInfo]);

  async function layDsDichvu(
    BENH_NHAN_ID,
    filterDate,
    LICH_KHAM_ID,
    ID
  ) {
    setLoading(true);
    let dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      BENH_NHAN_ID,
      LICH_KHAM_ID,
      ID,
      NGAY: filterDate ? filterDate : moment().format("YYYYMMDD"),
    };
    const res = LICH_KHAM_ID ? await layDsDichVuChuaThanhToan(dataRequest) : await layTatCaDvGoiTrangThai(dataRequest);
    if (res) {
      const data = LICH_KHAM_ID
        ? res.result.map((item) => ({ ...item, key: rid() }))
        : res.map((item) => ({ ...item, key: rid() }));
      let newData = data.map((item, index) => {
        return {
          ...item,
          tong_tien: ((LICH_KHAM_ID ? item.DON_GIA : item.DON_GIA_PHAN_BO) * item.SO_LUONG)
        }
      })
      setDataSource(newData);
    }
    setLoading(false);
  }

  return (
    <>
      <Layout.Content style={{ padding: "0 12px" }}>
        <Layout.Header style={{ backgroundColor: "transparent" }}>
          <Row>
            <Col flex={"auto"}>
              <div
                className={style["title"]}
                style={{ fontSize: 20, fontWeight: 600 }}
              >
                {i18n.t(languageKeys.vienphi_ds_cac_dichvu)}
              </div>
            </Col>
            {/* <Col style={{ marginLeft: "15px" }}>
              <Button icon={<ReloadOutlined />} type="primary"
                // onClick={() => onReloadList()}
              >
                {i18n.t(languageKeys.common_tai_lai)}
              </Button>
            </Col> */}
          </Row>
        </Layout.Header>
        <Layout.Content>
          <Table
            dataSource={dataSource}
            columns={columnsTatCa}
            // selectedRows={selectedService}
            // onSelectRows={setSelectedService}
            scroll={!!dataSource.length && { y: "calc(100vh - 220px)" }}
            className={style['table-custom']}
          
          />
        </Layout.Content>
      </Layout.Content>
    </>
  );
};
export default TongDichVu;
