import React, { useState, useEffect, useImperativeHandle, useCallback, useRef } from "react";
import { Form, Row, Col, DatePicker, Input, Select as SelectAntd, Button, Divider, InputNumber, Spin, notification, Space, Tooltip } from "antd";
import styles from "./phieuNhap.module.less";
import { useForm } from "antd/lib/form/Form";
import { DateInput, Table, Select, FormDrawer, ConfirmModal } from "../../../../../components";
import { PercentageOutlined, PlusOutlined, PrinterFilled, SearchOutlined } from "@ant-design/icons";
import ModalThemThuoc from "../../../PhieuNhapXuat/components/Modal/ModalThemThuoc";
import { hooksPhieuNhap } from "./servicesPhieuNhap";
import { useSelector } from "react-redux";
import moment from "moment";
import i18n, { languageKeys } from "../../../../../i18n";
import {
  common_post,
  convertDateToValue,
  convertValueToDate,
  currencyParserNotAlowNegativeNum,
  formatNumberToPrice,
  handleErrorHuyPhieu,
  HLog,
  rid,
} from "../../../../../helpers";
import SearchDrugPopup from "../SearchDrugPopup/SearchDrugPopup";
import { Delete } from "../../../../../assets/svg";
import { apis } from "../../../../../constants";
import ModalPDF from "../../../../../components/Modal/ModalPDF";
import cn from "classnames";
import { useEventListener } from "../../../../../hooks";
import { exportExcel_BienBanKiemNhapThuoc } from "../../../../../components/Excel/BienBanKiemNhapThuoc";
import { widthDrawerKeys } from "../../../../../constants/keys";
import { nanoid } from "@reduxjs/toolkit";
import { TooltipButton } from "components/Tooltip/CustomTooltip";
const { Item } = Form;

const TYPE_MIEN_GIAM = {
  TIEN: "TIEN",
  PHAN_TRAM: "PHAN_TRAM",
};

function ModalPhieuNhap({ onOK = () => {}, doAfterSuccess = () => {}, disableHuyPhieu = false, disableInPhieu = false }, ref) {

  const userProfile = useSelector((state) => state.auth.user);

  const addMedRef = useRef();
  const drugRef = useRef();
  const searchDrugRef = useRef();
  const pdfRef = useRef();
  const confirmRef = useRef();

  const [form] = useForm();
  const [formThuoc] = useForm();
  const soLuong = Form.useWatch(fieldNames.SO_LUONG, formThuoc)
  const giaNhap = Form.useWatch(fieldNames.GIA_NHAP, formThuoc)
  const vat = Form.useWatch(fieldNames.VAT_PHAN_TRAM, formThuoc)
  const gia_sau_vat = Form.useWatch(fieldNames.GIA_CO_VAT, formThuoc)
  const phan_tram_thang_du = Form.useWatch(fieldNames.PHAN_TRAM_THANG_DU, formThuoc)

  const [visible, setVisible] = useState(false);
  const [selectedThuoc, setSelectedThuoc] = useState();
  const [arr_THUOC, setArr_THUOC] = useState([]);
  const [drugSearchString, setDrugSearchString] = useState("");
  const [typeGiam, setTypeGiam] = useState(TYPE_MIEN_GIAM.TIEN);
  const [valueGiam, setValueGiam] = useState(0);
  const [tienTraNCC, setTienTraNCC] = useState(0);
  const [ghiChu, setGhiChu] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [readOnly, setReadOnly] = useState(true);
  const [phieuID, setPhieuId] = useState();
  const [print, setPrint] = useState(false);
  const [selectedKho, setSelectedKho] = useState();
  const [isDaHuy, setIsDaHuy] = useState(false);
  const [maxThangDu, setMaxThangDu] = useState();

  const { dsKhoThuoc, dsNCC, handleSearchNCC, handleSearchKhoThuoc, dsNguon } = hooksPhieuNhap({ visible, readOnly });

  useImperativeHandle(
    ref,
    () => ({
      openModal(item) {
        if (item) {
          //setReadOnly(true)
          getChiTietPhieu(item);
        } else {
          setReadOnly(false);
        }
        setVisible(true);
      },
      closeModal() {
        handleCloseModal();
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  // tính tiền vat và thành tiền sau khi nhập, số lượng VAT, giá nhập
  useEffect(() => {
    let gia_vat = giaNhap * (1 + vat / 100);
    formThuoc.setFields([
      {
        name: fieldNames.THANH_TIEN,
        value: Math.round(soLuong * gia_vat),
      },
      {
        name: fieldNames.GIA_CO_VAT,
        value: Math.round(gia_vat),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [soLuong, vat, giaNhap]);

  // tính thặng dư
  useEffect(() => {
    if (gia_sau_vat) {
      const currPercent = handleThangDu(gia_sau_vat, phan_tram_thang_du, setMaxThangDu)
      formThuoc.setFields([
        {
          name: fieldNames.PHAN_TRAM_THANG_DU,
          value: currPercent,
        },
        {
          name: fieldNames.GIA_THANG_DU,
          value: Math.round(gia_sau_vat * (1 + currPercent / 100)),
        },
      ]);
    }
  }, [gia_sau_vat, phan_tram_thang_du])

  // Xử lý thặng dư
  const handleThangDu = (gia_co_vat, phan_tram, setMax = () => {}) => {
    if (gia_co_vat <= 1000) {
      setMax(20)
      return phan_tram <= 20 ? phan_tram : 20;
    }
    if (gia_co_vat <= 5000) {
      setMax(15)
      return phan_tram <= 15 ? phan_tram : 15;
    }
    if (gia_co_vat <= 100000) {
      setMax(10)
      return phan_tram <= 10 ? phan_tram : 10;
    }
    if (gia_co_vat <= 1000000) {
      setMax(7)
      return phan_tram <= 7 ? phan_tram : 7;
    }
    if (gia_co_vat > 1000000) {
      setMax(5)
      return phan_tram <= 5 ? phan_tram : 5;
    }
  }


  // điền data vào form khi xem
  async function getChiTietPhieu(item) {
    setPhieuId(item.ID);
    setLoading(true);
    try {
      const response = await common_post(apis.lay_chi_tiet_phieu, {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        ID: item.ID,
      });
      if (response && response.status === "OK") {
        let { chi_tiet, data_thuoc } = response.result;
        setLoading(false);
        form.setFields([
          {
            name: "KHO_THUOC_ID",
            value: chi_tiet.TEN_KHO,
          },
          {
            name: "NCC_THUOC_ID",
            value: chi_tiet.TEN_NCC,
          },
          {
            name: "NGAY",
            value: moment(chi_tiet.NGAY + " " + chi_tiet.GIO, "YYYYMMDD HH:mm"),
          },
          {
            name: "NGUOI_TAO",
            value: chi_tiet.NGUOI_TAO,
          },
          {
            name: "MA_PHIEU",
            value: chi_tiet.MA_PHIEU,
          },
          {
            name: "MA_HOA_DON",
            value: chi_tiet.MA_HOA_DON,
          },
        ]);
        if (chi_tiet.GIAM_PHAN_TRAM) {
          setTypeGiam(TYPE_MIEN_GIAM.PHAN_TRAM);
          setValueGiam(chi_tiet.GIAM_PHAN_TRAM);
        }
        if (chi_tiet.GIAM_TIEN) {
          setTypeGiam(TYPE_MIEN_GIAM.TIEN);
          setValueGiam(chi_tiet.GIAM_TIEN);
        }
        if (chi_tiet.TRANG_THAI_PHIEU === "HUY") {
          setIsDaHuy(true);
        }
        setTienTraNCC(chi_tiet.TIEN_TRA_NCC);
        setGhiChu(chi_tiet.GHI_CHU);
        setArr_THUOC(
          data_thuoc.map((item, index) => {
            return {
              ...item,
              arr_LO_THUOC: item.data_LO_THUOC,
              STT: index + 1,
              key: rid(),
            };
          })
        );
      }
    } catch (error) {}
  }

  // khi thêm thuôc vào bảng hoặc chỉnh sửa thuốc
  function handleThemThuoc(value) {
    if (!selectedKho) {
      notification.warning({ message: i18n.t(languageKeys.duoc_chua_chon_kho_thuoc) });
      return;
    }
    if (!selectedThuoc) {
      notification.warning({ message: i18n.t(languageKeys.duoc_chua_chon_thuoc) });
      return;
    }
    if (value.GIA_BAN && value.GIA_BAN !== 0 && value.GIA_NHAP > value.GIA_BAN) {
      notification.warning({ message: i18n.t(languageKeys.duoc_gia_nhap_lon_hon_gia_ban) });
      formThuoc.setFields([
        {
          name: fieldNames.GIA_NHAP,
          errors: [""],
        },
      ]);
      return;
    }
    let new_data = {
      ...selectedThuoc,
      //STT : arr_THUOC.length + 1,
      GIA_NHAP: value.GIA_NHAP,
      VAT_TIEN: value.THANH_TIEN - value.GIA_NHAP * value.SO_LUONG,
      VAT_PHAN_TRAM: value.VAT_PHAN_TRAM || 0,
      THANH_TIEN: value.THANH_TIEN,
      GIAM_PHAN_TRAM: null,
      GIAM_TIEN: null,
      GIA_THANG_DU: value.GIA_THANG_DU,
      GIA_BAN: value.GIA_BAN,
      GIA_CO_VAT: value.GIA_CO_VAT,
      NGUON_NHAP_THUOC_ID: value.NGUON_NHAP_THUOC_ID,
      arr_LO_THUOC: [
        {
          MA_LO: value.MA_LO,
          HAN_SU_DUNG: convertValueToDate(value.HAN_SU_DUNG),
          SO_LUONG: value.SO_LUONG,
          THANH_TIEN: value.THANH_TIEN,
          GIA_THANG_DU: value.GIA_THANG_DU,
          GIA_BAN: value.GIA_BAN,
        },
      ],
    };
    if (!isEdit) {
      new_data = {
        ...new_data,
        STT: arr_THUOC.length + 1,
        THU_TU: arr_THUOC.length + 1,
        key: rid(),
      };
      setArr_THUOC(arr_THUOC.concat(new_data));
    } else {
      let new_arr = arr_THUOC.map((item) => {
        if (item.key === new_data.key) {
          return new_data;
        }
        return item;
      });
      setArr_THUOC(new_arr);
    }
    setSelectedThuoc();
    isEdit && setIsEdit(false);
    formThuoc.resetFields();
    handleClearDrugInput();
    setSelectedThuoc();
    setDrugSearchString("");
  }

  // tắt modal
  function handleCloseModal() {
    // document.removeEventListener('keydown', handleKeyPress);
    form.resetFields();
    formThuoc.resetFields();
    setArr_THUOC([]);
    setGhiChu("");
    setValueGiam(0);
    setTypeGiam(TYPE_MIEN_GIAM.TIEN);
    setTienTraNCC(0);
    setDrugSearchString("");
    selectedThuoc && setSelectedThuoc();
    setDrugSearchString("");
    setSelectedKho();
    phieuID && setPhieuId();
    print && setPrint(false);
    isDaHuy && setIsDaHuy(false);
    setReadOnly(true);
    setVisible(false);
    setMaxThangDu()
  }


  // chọn thuốc nhập
  function handleSelectThuoc(thuoc, addNew = false, valuesSubmitAddNew) {
    if (selectedThuoc && (selectedThuoc.ID !== thuoc.ID || selectedThuoc.LO_THUOC_ID !== thuoc.LO_THUOC_ID)) {
      formThuoc.resetFields();
      setIsEdit(false); //đạt sửa
    }
    if (addNew) {
      thuoc = {
        ID: thuoc.Thuoc_ID,
        Thuoc_ID: thuoc.Thuoc_ID,
        Thuoc_Code: thuoc.MA_THUOC,
        Thuoc_Ten: thuoc.TEN_THUOC,
        Thuoc_Dvt: valuesSubmitAddNew.TEN_DVT,
        Thuoc_HoatChat: valuesSubmitAddNew.TEN_HOAT_CHAT,
        TON_CUOI: 0,
        NONG_DO: thuoc.NONG_DO,
        MA_VACH: thuoc.MA_VACH,
        GIA_NHAP: thuoc.GIA_NHAP || 0,
        GIA_BAN: thuoc.GIA_BAN || 0,
        NOI_BAT: thuoc.NOI_BAT,
        IS_CHO_PHEP_MIEN_GIAM: thuoc.IS_CHO_PHEP_MIEN_GIAM,
        HAM_LUONG: thuoc.HAM_LUONG,
        IS_TAM_DUNG_NHAP_NCC: thuoc.IS_TAM_DUNG_NHAP_NCC,
        key: nanoid(),
      };
    }
    setSelectedThuoc(thuoc);
    formThuoc.setFields([
      {
        name: fieldNames.Thuoc_Code,
        value: thuoc[fieldNames.Thuoc_Code],
      },
      {
        name: fieldNames.Thuoc_Dvt,
        value: thuoc[fieldNames.Thuoc_Dvt],
      },
      {
        name: fieldNames.GIA_BAN,
        value: thuoc[fieldNames.GIA_BAN],
      },
      {
        name: fieldNames.GIA_NHAP,
        value: thuoc.GIA_NHAP,
      },
    ]);
  }

  const handleFocusDrugInput = () => {
    setTimeout(() => {
      drugRef.current?.focus();
    }, 100);
  };

  // hàm clear input tìm kiếm thuốc
  const handleClearDrugInput = () => {
    setDrugSearchString("");
  };

  // hàm xử lý tìm kiếm thuốc
  const handleSearchDrug = (e, kho = selectedKho) => {
    if (!selectedKho) {
      notification.warning({ message: i18n.t(languageKeys.duoc_chua_chon_kho_thuoc) });
      return;
    }
    //const { value } = e.target;
    selectedThuoc && setSelectedThuoc();
    ///setDrugSearchString(value);
    searchDrugRef.current?.searchData(e, kho);
  };

  // hàm xử lý tìm kiếm thuốc
  const handleChangeSearch = (e) => {
    if (!selectedKho) {
      notification.warning({ message: i18n.t(languageKeys.duoc_chua_chon_kho_thuoc) });
      return;
    }
    const { value } = e.target;
    selectedThuoc && setSelectedThuoc();
    setDrugSearchString(value);
    //searchDrugRef.current?.searchData(value, selectedKho);
  };

  // tính tổng tiền chưa miễn giảm
  function tinhTongTien() {
    return arr_THUOC.reduce((acc, item) => acc + item.THANH_TIEN, 0);
  }

  /// tiến số tiền được miễn giảm
  function tinhTienGiam() {
    let tong_tien = tinhTongTien();

    if (!valueGiam) {
      return 0;
    }
    if (typeGiam === TYPE_MIEN_GIAM.TIEN) {
      return valueGiam;
    } else {
      return Math.round((tong_tien / 100) * valueGiam);
    }
  }

  // tính tổng tiền trả chho nhà cung cấp
  function tinhTienTraNCC() {
    let tong_tien = tinhTongTien();
    return tong_tien - tinhTienGiam();
  }

  function tinhCongNo() {
    let tien_phai_tra = tinhTienTraNCC();
    if (!tienTraNCC) {
      return -tien_phai_tra;
    } else {
      return tienTraNCC - tien_phai_tra;
    }
  }

  // lưu phiếu
  async function handleSave(value) {
    if (arr_THUOC.length === 0) {
      notification.warning({ message: i18n.t(languageKeys.duoc_chua_nhap_thuoc) });
      return;
    }
    try {
      let time = value.NGAY;
      let NGAY = moment(time).format("YYYYMMDD");
      let GIO = moment(time).format("HH:mm");
      setLoading(true);
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        NHANSU_ID: userProfile.NHANSU_ID,
        ...value,
        NGAY: NGAY,
        GIO: GIO,
        KHO_THUOC_ID: JSON.parse(value.KHO_THUOC_ID).ID,
        GHI_CHU: ghiChu,
        PHAN_LOAI: "PHIEU_NHAP",
        TONG_CHI_PHI: tinhTongTien(),
        TONG_MIEN_GIAM: tinhTienGiam(),
        TONG_THANH_TOAN: tinhTienTraNCC(),
        GIAM_TIEN: typeGiam === TYPE_MIEN_GIAM.TIEN ? valueGiam : null,
        GIAM_PHAN_TRAM: typeGiam === TYPE_MIEN_GIAM.PHAN_TRAM ? valueGiam : null,
        TIEN_TRA_NCC: tienTraNCC,
        TIEN_CONG_NO: tinhCongNo(),
        arr_THUOC: arr_THUOC,
      };
      const response = await common_post(apis.luu_phieu_nhap_thuoc, dataRequest);
      if (response && response.status === "OK") {
        setLoading(false);
        if (print) {
          handlePrint(response.ID);
        }
        doAfterSuccess();
      } else if (response && response.status === "KO" && response.error_code === "001" && response.field === "MA_PHIEU") {
        notification.error({
          message: i18n.t(languageKeys.ma_phieu_bi_trung),
        });
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      HLog("errorr", error);
    }
  }

  function onEditItem(item) {
    setIsEdit(true);
    setSelectedThuoc(item);
    formThuoc.setFields([
      {
        name: fieldNames.Thuoc_Code,
        value: item[fieldNames.Thuoc_Code],
      },
      {
        name: fieldNames.Thuoc_Dvt,
        value: item[fieldNames.Thuoc_Dvt],
      },
      {
        name: fieldNames.GIA_BAN,
        value: item[fieldNames.GIA_BAN],
      },
    ]);
    let LO_THUOC = item.arr_LO_THUOC[0];
    formThuoc.setFields([
      {
        name: fieldNames.SO_LUONG,
        value: LO_THUOC.SO_LUONG,
      },
      {
        name: fieldNames.HAN_SU_DUNG,
        value: convertDateToValue(LO_THUOC.HAN_SU_DUNG),
      },
      {
        name: fieldNames.MA_LO,
        value: LO_THUOC.MA_LO,
      },
      {
        name: fieldNames.GIA_NHAP,
        value: item.GIA_NHAP,
      },
      {
        name: fieldNames.VAT_PHAN_TRAM,
        value: item.VAT_PHAN_TRAM,
      },
    ]);
  }

  async function handlePrint(PHIEU_THUOC_ID) {
    const dataRequest = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: PHIEU_THUOC_ID,
    };
    const api = apis.phieu_nhap_pdf;
    pdfRef.current.openModal(dataRequest, api);
    //handleExportExel(PHIEU_THUOC_ID)
  }

  const columns = [
    {
      title: i18n.t(languageKeys.sl),
      dataIndex: "STT",
      key: "STT",
      width: 80,
    },
    {
      title: i18n.t(languageKeys.field_ma_thuoc),
      dataIndex: "Thuoc_Code",
      key: "Thuoc_Code",
      render: (ma) => <div style={{ color: "#6576FF" }}>{ma}</div>,
      width: 100,
    },
    {
      title: i18n.t(languageKeys.field_ten_thuoc),
      dataIndex: "Thuoc_Ten",
      key: "Thuoc_Ten",
      width: 200,
    },
    {
      title: i18n.t(languageKeys.field_Don_vi),
      dataIndex: "Thuoc_Dvt",
      key: "Thuoc_Dvt",
    },
    {
      title: i18n.t(languageKeys.field_ma_lo),
      dataIndex: "VAT_PHAN_TRAM",
      key: "VAT_PHAN_TRAM",
      render: (_, record) => record.arr_LO_THUOC[0].MA_LO,
      width: 80,
    },
    {
      title: i18n.t(languageKeys.field_han_su_dung),
      dataIndex: "",
      key: "",
      width: 120,
      render: (_, record) => convertDateToValue(record.arr_LO_THUOC[0].HAN_SU_DUNG),
    },
    {
      title: i18n.t(languageKeys.field_gia_nhap),
      dataIndex: "GIA_NHAP",
      key: "GIA_NHAP",
      render: (GIA_NHAP) => formatNumberToPrice(GIA_NHAP),
      width: 120,
    },
    {
      title: i18n.t(languageKeys.field_gia_ban),
      dataIndex: "",
      key: "",
      render: (_, record) => formatNumberToPrice(record.GIA_BAN),
      width: 100,
    },
    {
      title: i18n.t(languageKeys.field_Gia_thang_du),
      dataIndex: "",
      key: "",
      render: (_, record) => formatNumberToPrice(record.GIA_THANG_DU),
      width: 120,
    },
    {
      title: i18n.t(languageKeys.sl),
      dataIndex: "Thuoc_Dvt",
      key: "Thuoc_Dvt",
      render: (_, record) => record.arr_LO_THUOC[0].SO_LUONG,
      width: 80,
    },
    {
      title: "VAT",
      dataIndex: "VAT_PHAN_TRAM",
      key: "VAT_PHAN_TRAM",
      render: (VAT_PHAN_TRAM) => `${VAT_PHAN_TRAM || 0}` + "%",
      width: 80,
    },
    {
      title: i18n.t(languageKeys.duoc_gia_sau_vat),
      dataIndex: "GIA_CO_VAT",
      key: "GIA_CO_VAT",
      render: (GIA_CO_VAT) => formatNumberToPrice(GIA_CO_VAT),
      width: 120,
    },
    {
      title: i18n.t(languageKeys.vien_phi_thanh_tien),
      dataIndex: "THANH_TIEN",
      key: "THANH_TIEN",
      render: (THANH_TIEN) => <div style={{ color: "#2CB2A5" }}>{formatNumberToPrice(THANH_TIEN)}</div>,
      width: 120,
    },
  ];

  // hàm thay đổi giá trị miễn giảm
  const onChangeDisCountValue = (value, type) => {
    let tong_giam = tinhTongTien();
    setValueGiam(value);
    // if(type === TYPE_MIEN_GIAM.PHAN_TRAM){
    //     value > 100 ? setValueGiam(100) : setValueGiam(value)
    // }else if(type === TYPE_MIEN_GIAM.TIEN){
    //     value > tong_giam ? setValueGiam(tong_giam) : setValueGiam(value)
    // }
  };
  // Hàm đổi loại miễn giảm
  const onChangeDiscountType = (type) => {
    setTypeGiam(type);
    // if(type === TYPE_MIEN_GIAM.PHAN_TRAM){
    //     valueGiam > 100 ? setValueGiam(100) : setValueGiam(0)
    // }
  };

  // xử lý event phím tắt
  const keydownEvent = (event) => {
    const stopDefaultAction = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };
    if (!readOnly) {
      if ((event.ctrlKey || event.metaKey) && (event.keyCode === 78 || event.key === "a")) {
        stopDefaultAction(event);
        formThuoc.submit();
      } else if (event.ctrlKey && event.key === "l") {
        stopDefaultAction(event);
        setPrint(true);
        form.submit();
      } else if (event.ctrlKey && event.key === "s") {
        stopDefaultAction(event);
        setPrint(false);
        form.submit();
      }
    } else {
      if (event.ctrlKey && event.key === "i" && !isDaHuy) {
        stopDefaultAction(event);
        handlePrint(phieuID);
      }
    }
  };

  useEventListener("keydown", keydownEvent, window.document, visible);

  // const handleExportExel = async (ID) => {
  //   let req = {
  //     partner_code: userProfile.partner_code,
  //     BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  //     PHIEU_THUOC_ID: ID,
  //   };
  //   try {
  //     const response = await common_post(apis.lay_bao_cao_kiem_nhap, req);
  //     if (response && response.status === "OK") {
  //       exportExcel_BienBanKiemNhapThuoc({
  //         benh_vien: userProfile.benh_vien,
  //         data: response.data.BAO_CAO.map((item, index) => [
  //           index + 1,
  //           item.MA_THUOC,
  //           item.TEN_THUOC,
  //           item.TEN_DON_VI_TINH,
  //           "",
  //           item.TEN_NUOC_SAN_XUAT || "-",
  //           convertDateToValue(item.HAN_SU_DUNG),
  //           formatNumberToPrice(item.GIA_NHAP),
  //           item.SO_LUONG,
  //           formatNumberToPrice(item.THANH_TIEN),
  //           "",
  //         ]),
  //       })
  //         .then(() => {
  //           notification.success({ message: i18n.t(languageKeys.thao_tac_thanh_cong) });
  //         })
  //         .catch((error) => console.log(error));
  //     }
  //   } catch (error) {}
  // };

  const handleHuyPhieu = async () => {
    let req = {
      partner_code: userProfile.partner_code,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      PHIEU_THUOC_ID: phieuID,
      NGUOI_HUY_ID: userProfile.NHANSU_ID,
      PHAN_LOAI: "PHIEU_NHAP",
    };
    try {
      confirmRef.current.loading();
      const res = await common_post(apis.huy_phieu_nhap_xuat, req);
      if (res && res.status === "OK") {
        confirmRef.current.close();
        doAfterSuccess();
      } else if (res && res.status === "KO") {
        confirmRef.current.close();
        notification.warning({ message: handleErrorHuyPhieu(res.error_code || null) });
      }
    } catch (error) {}
  };

  return (
    <FormDrawer
      visible={visible}
      onCancel={handleCloseModal}
      width={widthDrawerKeys.large}
      centered={true}
      hiddenTopAction={true}
      // showPlusIcon={!readOnly}
      title={readOnly ? i18n.t(languageKeys.title_phieu_nhap_ncc) : i18n.t(languageKeys.title_them_phieu_nhap_ncc)}
      footer={
        <Row
          justify="end"
          // gutter={10}
          style={{ backgroundColor: "#FFF", zIndex: 999 }}
        >
          {/* {readOnly && <Col>
                        <Button
                            icon={<DownloadOutlined/>}
                            type="primary"
                            onClick={() => handleExportExel(phieuID)}
                        >
                            <span>Xuất báo cáo kiểm nhập</span>
                        </Button>
                    </Col>} */}
          <Col style={{ marginLeft: "10px" }}>
            <Button onClick={handleCloseModal} ghost type="primary">
              {i18n.t(languageKeys.common_Thoat)}
              {" (ESC)"}
            </Button>
          </Col>
          {readOnly && (
            <Col style={{ marginLeft: "10px" }}>
              <TooltipButton title={isDaHuy ? "" : disableHuyPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                <Button type="primary" onClick={() => confirmRef.current.open()} ghost danger disabled={disableHuyPhieu || isDaHuy}>
                  {i18n.t(languageKeys.huy_phieu)}
                </Button>
              </TooltipButton>
            </Col>
          )}

          <Row justify="end">
            <Col style={{ marginLeft: "10px" }}>
              {readOnly ? (
                <Col>
                  <TooltipButton title={isDaHuy ? "" : disableInPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                    <Button icon={<PrinterFilled />} type="primary" onClick={() => handlePrint(phieuID)} disabled={disableInPhieu || isDaHuy}>
                      <span>
                        {i18n.t(languageKeys.field_In_phieu)} {" (CTRL + I)"}
                      </span>
                    </Button>
                  </TooltipButton>
                </Col>
              ) : (
                <TooltipButton title={disableInPhieu ? i18n.t(languageKeys.khong_co_quyen) : ""}>
                  <Button
                    icon={<PrinterFilled />}
                    type="primary"
                    onClick={() => {
                      setPrint(true);
                      form.submit();
                    }}
                    loading={loading}
                    disabled={disableInPhieu}
                  >
                    <span>
                      {" "}
                      {i18n.t(languageKeys.luu_va_in)} {" (CTRL + L)"}
                    </span>
                  </Button>
                </TooltipButton>
              )}
            </Col>
            {!readOnly && (
              <Col style={{ marginLeft: "10px" }}>
                <Button onClick={() => form.submit()} type="primary" loading={loading}>
                  {i18n.t(languageKeys.common_Luu)} {" (CTRL + S)"}
                </Button>
              </Col>
            )}
          </Row>
        </Row>
      }
    >
      <Spin spinning={loading}>
        <div className={styles.container}>
          <Form form={form} layout="vertical" onFinish={handleSave}>
            <Row
              style={{ paddingInline: 10 }}
              // gutter={5}
              align="bottom"
            >
              <Col span={4}>
                <Item
                  label={i18n.t(languageKeys.field_Kho_thuoc)}
                  name="KHO_THUOC_ID"
                  rules={[{ required: true }]}
                >
                  <Select
                    dataSource={dsKhoThuoc}
                    titleKey="TEN_KHO"
                    //valueKey="ID"
                    disabled={readOnly}
                    showSearch
                    onSearch={handleSearchKhoThuoc}
                    onSelect={async (item) => {
                      let kho = JSON.parse(item);
                      if (selectedKho && kho.ID != selectedKho.ID) {
                        setArr_THUOC([]);
                        formThuoc.resetFields();
                        setDrugSearchString("");
                        selectedThuoc && setSelectedThuoc();
                        handleSearchDrug("", kho);
                      }
                      setSelectedKho(JSON.parse(item));
                    }}
                  />
                </Item>
              </Col>

              <Col span={4}>
                <Item label={i18n.t(languageKeys.ten_nha_cung_cap)} name="NCC_THUOC_ID">
                  <Select
                    dataSource={dsNCC}
                    titleKey="TEN_NCC"
                    valueKey="ID"
                    disabled={readOnly}
                    showSearch
                    onSearch={handleSearchNCC}
                    allowClear
                  />
                </Item>
              </Col>

              <Col span={4}>
                <Item label={i18n.t(languageKeys.field_Ma_phieu)} name="MA_PHIEU">
                  <Input placeholder={i18n.t(languageKeys.placeholder_Tao_tu_dong)} readOnly={readOnly} />
                </Item>
              </Col>

              <Col span={4}>
                <Item
                  label={i18n.t(languageKeys.fieldMa_hoa_don)}
                  name="MA_HOA_DON"
                >
                  <Input readOnly={readOnly} placeholder={i18n.t(languageKeys.common_Nhap)} />
                </Item>
              </Col>

              <Col span={4}>
                <Item
                  label={i18n.t(languageKeys.field_Ngay_tao)}
                  name="NGAY"
                  initialValue={moment()}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    defaultValue={moment()}
                    showTime={{ format: "HH:mm" }}
                    format="DD/MM/YYYY - HH:mm"
                    disabled
                  />
                </Item>
              </Col>

              <Col span={4}>
                <Item label={i18n.t(languageKeys.field_Nguoi_tao)} name={"NGUOI_TAO"} initialValue={userProfile.HO + " " + userProfile.TEN}>
                  <Input readOnly value={userProfile.HO + " " + userProfile.TEN} />
                </Item>
              </Col>

            </Row>
          </Form>

          <Divider style={{ marginTop: "5px" }} />

          {!readOnly && (
            <Form form={formThuoc} layout="vertical" onFinish={handleThemThuoc}>
              <Row style={{ paddingInline: 10, marginTop: "-20px" }} align="bottom">
                <Col span={8}>
                  <Row wrap={false} gutter={10} style={{ marginBottom: 6 }}>

                    <Col flex="auto">
                      <SearchDrugPopup
                        ref={searchDrugRef}
                        onAddDrug={handleSelectThuoc}
                        focusInput={handleFocusDrugInput}
                        clearInput={handleClearDrugInput}
                        kho_thuoc={selectedKho}
                      >
                        <Input
                          value={selectedThuoc ? selectedThuoc.Thuoc_Ten : drugSearchString}
                          onChange={handleChangeSearch}
                          onPressEnter={(e) => handleSearchDrug(e.target.value)}
                          prefix={<SearchOutlined className="blue-txt" />}
                          placeholder={i18n.t(languageKeys.common_Tim_kiem)}
                          ref={drugRef}
                          onFocus={() => {
                            if (!selectedKho) {
                              notification.warning({
                                message: i18n.t(languageKeys.duoc_chua_chon_kho_thuoc),
                              });
                            }
                          }}
                          allowClear
                        />
                      </SearchDrugPopup>
                    </Col>

                    <Col>
                      <Tooltip title={i18n.t(languageKeys.field_Them_moi_thuoc)}>
                        <Button
                          type="primary"
                          icon={<PlusOutlined style={{ fontSize: 15 }} />}
                          onClick={() => addMedRef.current.open()}
                          style={{ width: "34px" }}
                        ></Button>
                      </Tooltip>
                    </Col>

                  </Row>
                </Col>

                <Col span={2}>
                  <Item label={i18n.t(languageKeys.field_ma_thuoc)} name={fieldNames.Thuoc_Code}>
                    <Input readOnly />
                  </Item>
                </Col>
                <Col span={2}>
                  <Item label={i18n.t(languageKeys.field_Don_vi)} name={fieldNames.Thuoc_Dvt}>
                    <Input readOnly />
                  </Item>
                </Col>

                <Col span={4}>
                  <Item label={i18n.t(languageKeys.txt_So_luong)} name={fieldNames.SO_LUONG} rules={rulesItem}>
                    <InputNumber
                      style={{ width: "100%" }}
                      min={0}
                      placeholder={i18n.t(languageKeys.common_Nhap)}
                    />
                  </Item>
                </Col>

                <Col span={2}>
                  <Item label={i18n.t(languageKeys.field_ma_lo)} name={fieldNames.MA_LO} style={{ marginBottom: "0px" }} rules={[{ required: true }]}>
                    <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                  </Item>
                </Col>

                <Col span={2}>
                  <DateInput
                    label={i18n.t(languageKeys.field_han_su_dung)}
                    name={fieldNames.HAN_SU_DUNG}
                    form={formThuoc}
                    required={true}
                  // styleInput={{ border: "1px solid #e2e2e2", }}
                  />
                </Col>

                <Col span={4}>
                  <Item label={i18n.t(languageKeys.cate_nguon)} name={fieldNames.NGUON_NHAP}>
                    <Select dataSource={dsNguon} valueKey="ID" titleKey="TEN_NGUON" />
                  </Item>
                </Col>

              </Row>

              {/* "ROW 2" */}
              <Row style={{ paddingInline: 10 }} align="bottom">

                <Col flex={"10%"}>
                  <Item label={i18n.t(languageKeys.field_gia_ban)} name={fieldNames.GIA_BAN}>
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={currencyFormatter}
                      parser={currencyParserNotAlowNegativeNum}
                    />
                  </Item>
                </Col>

                <Col flex={"10%"}>
                  <Item label={i18n.t(languageKeys.field_gia_nhap)} name={fieldNames.GIA_NHAP} rules={rulesItem} style={{ marginBottom: 0 }}>
                    <InputNumber
                      style={{ width: "100%" }}
                      formatter={currencyFormatter}
                      parser={currencyParserNotAlowNegativeNum}
                      min={0}
                      placeholder={i18n.t(languageKeys.common_Nhap)}
                    />
                  </Item>
                </Col>

                <Col flex={"10%"}>
                  <Item label="VAT" name={fieldNames.VAT_PHAN_TRAM} initialValue={0}>
                    <InputNumber
                      min={0}
                      max={100}
                      onFocus={(e) => e.target.select()}
                      style={{ width: "100%" }}
                      addonAfter={"%"}
                    />
                  </Item>
                </Col>

                <Col flex={"10%"}>
                  <Item label={i18n.t(languageKeys.gia_co_vat)} name={fieldNames.GIA_CO_VAT}>
                    <InputNumber
                      formatter={currencyFormatter}
                      parser={currencyParserNotAlowNegativeNum}
                      style={{ width: "100%" }}
                      readOnly
                    />
                  </Item>
                </Col>

                <Col flex={"10%"}>
                  <Item label={i18n.t(languageKeys.vien_phi_thanh_tien)} name={fieldNames.THANH_TIEN}>
                    <InputNumber
                      formatter={currencyFormatter}
                      parser={currencyParserNotAlowNegativeNum}
                      style={{ width: "100%" }}
                      readOnly
                    />
                  </Item>
                </Col>

                <Col flex={"10%"}>
                  <Item label={i18n.t(languageKeys.ph_tram_thang_du)} name={fieldNames.PHAN_TRAM_THANG_DU} initialValue={0}>
                    <InputNumber
                      defaultValue={0}
                      min={0}
                      max={maxThangDu}
                      style={{ width: "100%" }}
                      addonAfter={"%"}
                    />
                  </Item>
                </Col>

                <Col flex={"10%"}>
                  <Item label={i18n.t(languageKeys.field_Gia_thang_du)} name={fieldNames.GIA_THANG_DU}>
                    <InputNumber
                      formatter={currencyFormatter}
                      parser={currencyParserNotAlowNegativeNum}
                      style={{ width: "100%" }}
                      readOnly
                    />
                  </Item>
                </Col>

                <Col flex={"15%"}>
                  <Item label={i18n.t(languageKeys.field_Ghi_chu)} name="GHI_CHU" style={{ marginBottom: 0 }}>
                    <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                  </Item>
                </Col>

                <Col flex={"auto"}>
                  <Button type="primary" style={{ width: "100%", marginBottom: "5px" }} onClick={() => formThuoc.submit()}>
                    {i18n.t(languageKeys.them)} {`(Ctrl + A)`}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}

          {/* TABLE THUỐC  */}

          <Row style={{ marginTop: !readOnly ? "10px" : "-10px", flex: 1 }} wrap={false}>
            <Col flex={"auto"} style={{ backgroundColor: "#F5F7FB", padding: "10px 15px 0px 15px" }}>
              <h4>{i18n.t(languageKeys.title_Danh_sach_thuoc_nhap)}</h4>
              <Table
                dataSource={arr_THUOC}
                columns={
                  readOnly
                    ? columns
                    : columns.concat([
                      {
                        title: "",
                        key: "",
                        dataIndex: "",
                        // fixed: "right",
                        width: "60px",
                        render: (_, record) => (
                          <Delete
                            className={styles.del_icon}
                            onClick={(e) => {
                              e.stopPropagation();
                              let newData = arr_THUOC.filter((item) => item.key !== record.key);
                              setArr_THUOC(newData);
                            }}
                          />
                        ),
                      },
                    ])
                }
                onClickRow={(item) => !readOnly && onEditItem(item)}
                scroll={{
                  x: columns.reduce((sum, item) => sum + (item.width || 120), 0),
                  y: !readOnly ? "calc(100vh - 430px)" : "calc(100vh - 280px)",
                }}
              />
            </Col>

            <Col flex={"300px"} style={{ marginRight: 5 }}>
              <div className={cn(!readOnly ? styles.payment : styles.payment_detail)}>
                <h4>{i18n.t(languageKeys.txt_thanh_toan)}</h4>
                <Divider style={{ marginTop: "-5px", marginBottom: 0 }} />

                <Row justify="space-between" style={{ marginTop: "10px" }}>
                  <Col>
                    <h5>{i18n.t(languageKeys.field_Tong_chi_phi)}</h5>
                  </Col>
                  <Col>
                    <h5 className={styles.money}>{formatNumberToPrice(tinhTongTien())}</h5>
                  </Col>
                </Row>

                <Row style={{ marginTop: 10 }} justify="space-between" align="middle">
                  <Col span={9}>
                    <h5>{i18n.t(languageKeys.field_Mien_giam)}</h5>
                  </Col>
                  <Col span={15}>
                    <InputNumber
                      min={0}
                      // max={typeGiam === TYPE_MIEN_GIAM.PHAN_TRAM ? 100 : Number.MAX_SAFE_INTEGER}
                      formatter={typeGiam === TYPE_MIEN_GIAM.TIEN ? formatNumberToPrice : (value) => `${value}`}
                      parser={typeGiam === TYPE_MIEN_GIAM.TIEN ? currencyParserNotAlowNegativeNum : (value) => value && value.replace("%", "")}
                      className={styles.input}
                      controls={false}
                      addonAfter={
                        <SelectAntd value={typeGiam} style={{ width: 60 }} onChange={(value) => onChangeDiscountType(value)} disabled={readOnly}>
                          <SelectAntd.Option value={TYPE_MIEN_GIAM.TIEN}>đ</SelectAntd.Option>
                          <SelectAntd.Option value={TYPE_MIEN_GIAM.PHAN_TRAM}>%</SelectAntd.Option>
                        </SelectAntd>
                      }
                      value={!valueGiam ? null : valueGiam}
                      onChange={(val) => {
                        let type = typeGiam;
                        if (val > 100) {
                          if (typeGiam === TYPE_MIEN_GIAM.PHAN_TRAM) {
                            setTypeGiam(TYPE_MIEN_GIAM.TIEN);
                            type = TYPE_MIEN_GIAM.TIEN;
                          }
                        } else {
                          if (typeGiam === TYPE_MIEN_GIAM.TIEN) {
                            setTypeGiam(TYPE_MIEN_GIAM.PHAN_TRAM);
                            type = TYPE_MIEN_GIAM.PHAN_TRAM;
                          }
                        }
                        console.log("type", type, val);
                        onChangeDisCountValue(val, type);
                      }}
                      placeholder={0}
                      // style={{ width: "100%" }}
                      readOnly={readOnly}
                      onFocus={(e) => e.target.select()}
                    />
                  </Col>
                </Row>

                <Row justify="space-between" style={{ marginTop: "10px" }}>
                  <Col>
                    <h5>{i18n.t(languageKeys.txt_can_tra_ncc)}</h5>
                  </Col>
                  <Col>
                    <h5 className={styles.money_special}>{formatNumberToPrice(tinhTienTraNCC())}</h5>
                  </Col>
                </Row>
                <Divider style={{ marginBlock: 0 }} />
                <h5 style={{ marginTop: "10px" }}>{i18n.t(languageKeys.txt_tien_tra_ncc)}</h5>
                <InputNumber
                  width={"100%"}
                  style={{ marginTop: "10px", width: "100%" }}
                  formatter={currencyFormatter}
                  parser={currencyParserNotAlowNegativeNum}
                  onChange={setTienTraNCC}
                  value={tienTraNCC}
                  readOnly={readOnly}
                  className={styles.input}
                  controls={false}
                />

                <Row justify="space-between" style={{ marginTop: 5 }}>
                  <Col>
                    <h5>{i18n.t(languageKeys.txt_tinh_vao_cong_no)}</h5>
                  </Col>
                  <Col>
                    <h5 className={tinhCongNo() >= 0 ? styles.money_special : styles.money_special_red}>
                      {formatNumberToPrice(Math.abs(tinhCongNo()))}
                    </h5>
                  </Col>
                </Row>
                <Divider style={{ marginBlock: 10 }} />
                <h5>{i18n.t(languageKeys.field_Ghi_chu)}</h5>
                <Input.TextArea
                  width={"100%"}
                  rows={3}
                  onChange={(e) => setGhiChu(e.target.value)}
                  value={ghiChu}
                  readOnly={readOnly}
                  onFocus={(e) => e.target.select()}
                />
              </div>
            </Col>
          </Row>
          {/* </div> */}
        </div>
      </Spin>
      <ModalThemThuoc ref={addMedRef} doAfterSubmit={(thuoc, valuesSubmit) => handleSelectThuoc(thuoc.result, true, valuesSubmit)} />
      <ModalPDF ref={pdfRef} />
      <ConfirmModal
        danger={true}
        ref={confirmRef}
        content={i18n.t(languageKeys.confirm_cancel_ticket)}
        onOk={() => handleHuyPhieu()}
        onCancel={() => {
          confirmRef.current.close();
        }}
        okText={i18n.t(languageKeys.title_Xac_nhan)}
      />
    </FormDrawer>
  );
}

export default React.forwardRef(ModalPhieuNhap);

const fieldNames = {
  Thuoc_Code: "Thuoc_Code",
  Thuoc_Dvt: "Thuoc_Dvt",
  GIA_BAN: "GIA_BAN",
  SO_LUONG: "SO_LUONG",
  GIA_NHAP: "GIA_NHAP",
  THANH_TIEN: "THANH_TIEN",
  MA_LO: "MA_LO",
  HAN_SU_DUNG: "HAN_SU_DUNG",
  VAT_PHAN_TRAM: "VAT_PHAN_TRAM",
  VAT_TIEN: "VAT_TIEN",
  VAT_THANH_TIEN: "VAT_THANH_TIEN",
  GIA_CO_VAT: "GIA_CO_VAT",
  GIA_THANG_DU: "GIA_THANG_DU",
  NGUON_NHAP: "NGUON_NHAP_THUOC_ID",
  PHAN_TRAM_THANG_DU: "PHAN_TRAM_THANG_DU"
};

function currencyFormatter(value) {
  if (!!value) {
    return formatNumberToPrice(value);
  }
}

const rulesItem = [
  {
    required: true,
    message: i18n.t(languageKeys.vui_long_nhap),
  },
];

// trả thừa tiền thì xanh và dấu cộng
// trả thiếu tiền thì đỏ và dấu trừ

// function tinhGiaThangDu(gia_co_vat) {
//   if (gia_co_vat <= 1000) {
//     return tinhGiaPhanTram(gia_co_vat, 15);
//   }
//   if (gia_co_vat <= 5000) {
//     return tinhGiaPhanTram(gia_co_vat, 10);
//   }
//   if (gia_co_vat <= 100000) {
//     return tinhGiaPhanTram(gia_co_vat, 7);
//   }
//   if (gia_co_vat <= 1000000) {
//     return tinhGiaPhanTram(gia_co_vat, 5);
//   }
//   if (gia_co_vat > 1000000) {
//     return tinhGiaPhanTram(gia_co_vat, 2);
//   }
//   return 0;
// }

// function tinhGiaPhanTram(gia, phan_tram) {
//   return Math.round(gia * (1 + phan_tram / 100));
// }
