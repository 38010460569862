import { Col, Form, Input, Row, Switch } from "antd";
import { useEffect, useState } from "react";
import { Select } from "../../../../components";
import i18n, { languageKeys } from "../../../../i18n";
import { fieldKhoa } from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";
import { apiLayDsKhoaByt } from "../apisDanhMuc/apisDmKhoa";
import { danhSachLoaiKhoa } from "../../../../constants/data";

const FormKhoa = () => {
  const [dsKhoaByt, setDsKhoaByt] = useState([]);

  useEffect(() => {
    layDsKhoaCuaByt();
    return () => setDsKhoaByt([]);
  }, []);

  const layDsKhoaCuaByt = async () => {
    const data = await apiLayDsKhoaByt();
    setDsKhoaByt(data);
  };

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldKhoa.ma_khoa}
                label={i18n.t(languageKeys.field_Ma_khoa)}
                rules={[
                  // {
                  //   required: true,
                  //   message: i18n.t(languageKeys.vui_long_nhap),
                  // },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldKhoa.ten_khoa}
                label={i18n.t(languageKeys.field_Ten_khoa)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldKhoa.ma_khoa_theo_byt}
                label={i18n.t(languageKeys.field_Ma_khoa_byt)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  showSearch
                  dataSource={dsKhoaByt}
                  customTitle={(item) => (
                    <>{`${item[fieldKhoa.ma_byt]} - ${
                      item[fieldKhoa.ten_khoa_cua_ds_byt]
                    }`}</>
                  )}
                  valueKey={fieldKhoa.id_khoa_cua_ds_byt}
                  filterOption={(input, option) =>
                    option.children.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldKhoa.truong_khoa}
                label={i18n.t(languageKeys.field_Truong_khoa)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldKhoa.loai_khoa}
                label={i18n.t(languageKeys.field_Loai_khoa)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  showSearch
                  dataSource={danhSachLoaiKhoa}
                  titleKey="name"
                  valueKey="key"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

        <Form.Item name={fieldKhoa.ghi_chu}>
          <Input.TextArea
            rows={3}
            placeholder={i18n.t(languageKeys.common_Nhap)}
          />
        </Form.Item>

        <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

        <Form.Item
          name={fieldKhoa.hien_thi}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </div>
    </>
  );
};

export default FormKhoa;
