import { Button, Row, Tooltip, Typography } from "antd";
import Icon from "@ant-design/icons";
import styles from "./mauDanhMuc.module.less";
import { FileDownload } from "../../../assets/svg";
import {
  FileMauDmDichVu,
  FileMauDmDonViTinh,
  FileMauDmDuongDung,
  FileMauDmHangSanXuat,
  FileMauDmHoatChat,
  FileMauDmKhoa,
  FileMauDmLoaiDichVu,
  FileMauDmNhomDichVu,
  FileMauDmPhong,
  FileMauDmThuoc,
  FolderTemplatesImport,
} from "../../../assets/HDSD/mauDm";
import { Excel } from "../../../assets/img";
import i18n, { languageKeys } from "../../../i18n";

const listMauDM = [
  {
    file: FileMauDmKhoa,
    name: i18n.t(languageKeys.mau_dm_khoa),
    template: "mau_dm_khoa",
  },
  {
    file: FileMauDmPhong,
    name: i18n.t(languageKeys.mau_dm_phong),
    template: "mau_dm_phong",
  },
  {
    file: FileMauDmDonViTinh,
    name: i18n.t(languageKeys.mau_dm_don_vi_tinh),
    template: "mau_dm_don_vi_tinh",
  },
  {
    file: FileMauDmNhomDichVu,
    name: i18n.t(languageKeys.mau_dm_nhom_dich_vu),
    template: "mau_dm_nhom_dich_vu",
  },
  {
    file: FileMauDmLoaiDichVu,
    name: i18n.t(languageKeys.mau_dm_loai_dich_vu),
    template: "mau_dm_loai_dich_vu",
  },
  {
    file: FileMauDmDichVu,
    name: i18n.t(languageKeys.mau_dm_dich_vu),
    template: "mau_dm_dich_vu",
  },
  {
    file: FileMauDmThuoc,
    name: i18n.t(languageKeys.mau_dm_thuoc),
    template: "mau_dm_thuoc",
  },
  {
    file: FileMauDmHangSanXuat,
    name: i18n.t(languageKeys.mau_dm_hang_san_xuat),
    template: "mau_dm_hang_san_xuat",
  },
  {
    file: FileMauDmHoatChat,
    name: i18n.t(languageKeys.mau_dm_hoat_chat),
    template: "mau_dm_hoat_chat",
  },
  {
    file: FileMauDmDuongDung,
    name: i18n.t(languageKeys.mau_dm_duong_dung),
    template: "mau_dm_duong_dung",
  },
];

export const MauDanhMuc = () => {
  return (
    <div className={styles["container"]}>
      <div className={styles["inner"]}>
        <Row justify="space-between" className={styles["topbar"]}>
          <h3>{i18n.t(languageKeys.tong_hop_template_mau_danh_muc)}</h3>

          <a href={FolderTemplatesImport} arget="_blank" rel="noopener noreferrer" download="danh_sach_mau_dm">
            <Button type="primary" icon={<Icon component={FileDownload} style={{ fontSize: 15 }} />}>
              {i18n.t(languageKeys.tai_tat_ca_cac_mau)}
            </Button>
          </a>
        </Row>

        <div className={styles["main-wrap"]}>
          <div className={styles["main"]}>
            {listMauDM.map((mauDm) => (
              <div className={styles["item"]} key={mauDm.template}>
                <img src={Excel} alt="excel" />

                <Typography.Text ellipsis={{ tooltip: "" || "Mẫu DM" }} className="navy-txt">
                  {mauDm.name || "Mẫu DM"}
                </Typography.Text>

                <a href={mauDm.file} target="_blank" rel="noopener noreferrer" download={mauDm.template}>
                  <Tooltip title={i18n.t(languageKeys.common_Tai_mau)}>
                    <Icon component={FileDownload} className={styles["download-icon"]} />
                  </Tooltip>
                </a>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
