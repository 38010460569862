import { notification } from "antd";
import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";

export const apiThemDmHinhThucThanhToan = async ({ data, BENH_VIEN_ID, partner_code, NHANSU_ID }) => {
    let dataRequest = { ...data, BENH_VIEN_ID, partner_code, NHANSU_ID, GHI_CHU:data.GHI_CHU ? data.GHI_CHU : "" };
    HLog("data request::::", dataRequest);
    try {
        let response = await common_post(apis.dm_them_hinh_thuc_thanh_toan, dataRequest, false);
        return response;
    } catch (error) {
    }
};
export const apiSuaDmHinhThucThanhToan = async ({ data }) => {
    let dataRequest = { ...data,GHI_CHU:data.GHI_CHU ? data.GHI_CHU : ""  };
    delete dataRequest['key'];
    // delete dataRequest['_THU_TU'];
    // delete dataRequest['DUNG_CHO_CIS'];
    HLog("sua doi tuong", dataRequest);
    try {
        let response = await common_post(apis.dm_sua_hinh_thuc_thanh_toan, dataRequest, false);
        if (response && response.status === "OK") {
            return response;
        }
    } catch (error) {
    }
};
export const apiXoaDmHinhThucThanhToan= async ({ data, BENH_VIEN_ID, partner_code,NHANSU_ID }) => {
    try {
        if (!Array.isArray(data)) data = [data];
        let body = {
            BENH_VIEN_ID,
            partner_code,
            NHANSU_ID,
            listId: data.map((item) => item.ID),
        };

        HLog("xoa doi tuong::", body);
        let res = await common_post(apis.dm_xoa_hinh_thuc_thanh_toan, body, false);

        return res;
    } catch (error) {
    }
};
export const apiKhoaDmHinhThucThanhToan = async ({ lock, data, BENH_VIEN_ID, partner_code , NHANSU_ID}) => {
    try {
        if (!Array.isArray(data)) data = [data];

        let body = {
            partner_code,
            BENH_VIEN_ID,
            listId: data.map(item => !!item.ID && item.ID),
            isKhoa: Number(lock),
            NHANSU_ID : NHANSU_ID
        };
        let res = await common_post(apis.dm_khoa_hinh_thuc_thanh_toan, body, false);
        return res;
    } catch (error) {
        HLog("=======================", error);
    }
};
export const apiLayHinhThucThanhToan = async ({ search_string, page, BENH_VIEN_ID, partner_code }) => {
    try {
        let req = {
            BENH_VIEN_ID,
            partner_code,
            search_string,
            page,
            is_get_all : 1,
        };

        const res = await common_post(apis.dm_lay_hinh_thuc_thanh_toan, req);
        if (res.status === "OK") {
            return res;
        } else {
            notification.error({placement:'bottomLeft',message:i18n.t(languageKeys.noti_lay_danh_sach_that_bai)});
        };
    } catch (error) {
        HLog(error);
    }
};
// export const apiExportDmCachDung= async ({ BENH_VIEN_ID, partner_code }) => {
//     try {
//         let res = await common_post(apis.dm_export_cach_dung, { BENH_VIEN_ID, partner_code }, false);
//         if (res && res.status === "OK") {
//             let { result } = res;
//             res.result = result.map((item) => {
//               let { ID, TEN_CACH_DUNG,THU_TU, GHI_CHU, KHOA } = item;
//               return {
//                 ID,
//                 TEN_CACH_DUNG,
//                 THU_TU,
//                 GHI_CHU,
//                 KHOA,
//               };
//             });
//           }
//         return res;
//     } catch (error) {
//     }
// };
// export const apiImportDmCachDung = async ({ NHANSU_ID, data, BENH_VIEN_ID, partner_code }) => {
//     try {
//         let body = {
//             NHANSU_ID,
//             BENH_VIEN_ID,
//             partner_code,
//             data: data.map((item) => ({ ...item, BENH_VIEN_ID })),
//         };
//         let res = await common_post(apis.dm_import_cach_dung, body, false);
//         return res;
//     } catch (error) {
//         HLog("errorIMPortDoiTUong::", error);
//     }
// };
