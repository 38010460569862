import { trangThaiGoiDv } from "pages/QuanLyGoiDichVu/constants";
import React from "react";
import cn from "classnames";
import style from './style.module.less';
import i18n, { languageKeys } from "../../../../i18n";

const TrangThaiGoiDV = ({ state, ...props }) => {
  const renderUi = () => {
    switch (state) {
      case trangThaiGoiDv.chua_hoat_dong:
        return <div className={cn(style["state"], style['not-active-yet'])}>{i18n.t(languageKeys.chua_hoat_dong)}</div>;
      case trangThaiGoiDv.hoan_thanh:
        return <div className={cn(style["state"], style['completed'])}>{i18n.t(languageKeys.common_Hoan_thanh)}</div>;
      case trangThaiGoiDv.hoat_dong:
        return <div className={cn(style["state"], style['active'])}>{i18n.t(languageKeys.hoat_dong)}</div>;
      case trangThaiGoiDv.tam_ngung:
        return <div className={cn(style["state"], style['pending'])}>{i18n.t(languageKeys.tam_ngung)}</div>;
      case trangThaiGoiDv.sap_het_han:
        return <div className={cn(style["state"], style['expired-soon'])}>{i18n.t(languageKeys.sap_het_han)}</div>;
      case trangThaiGoiDv.het_han:
        return <div className={cn(style["state"], style['expired'])}>{i18n.t(languageKeys.het_han)}</div>;
      default:
        return <></>;
    }
  };
  return (
    state ? renderUi() : <></>
  )
};

export default TrangThaiGoiDV;
