import { apis, keys } from "../../../constants";
import { common_post, HLog } from "../../../helpers";

export const getListUser = async ({
  partner_code,
  BENH_VIEN_ID,
  limit = keys.limit,
  page = 1,
  search_string = "",
  NHOM_NHAN_SU
}) => {
  let dataRequest = { partner_code, BENH_VIEN_ID, limit, page, search_string,NHOM_NHAN_SU };
  try {
    const response = await common_post(apis.dm_lay_ds_user, dataRequest, true);
    HLog("Lấy danh sách user", response);
    if (response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("Lấy danh sách user lỗi ", error);
    return null;
  }
};

export const layPhongTheoKhoa = async ({
  partner_code,
  KHOA_ID,
  search_string = "",
}) => {
  let dataRequest = { partner_code, KHOA_ID, search_string };
  try {
    const response = await common_post(
      apis.dm_lay_phong_theo_khoa,
      dataRequest,
      false
    );
    if (response.status === "OK") {
      return response.result;
    }
    return null;
  } catch (error) {
    HLog(error);
    return null;
  }
};

export const addUser = async ({
  data = [],
  partner_code = "",
  BENH_VIEN_ID = "",
  ...rest
}) => {
  try {
    const dataRequest = {
      data,
      partner_code,
      BENH_VIEN_ID,
      ...rest
    };
    const response = await common_post(apis.dm_them_moi_user, dataRequest);
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteUser = async (dataRequest) => {
  try {
    const response = await common_post(apis.dm_xoa_ds_user, dataRequest);
    if (response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog(error);
    return null;
  }
};

export const updateUser = async (dataRequest) => {
  try {
    const response = await common_post(apis.dm_cap_nhat_user, dataRequest);
    return response;
  } catch (error) {
    return null;
  }
};

export const apiLayDanhSachQuyen = async (dataRequest) => {
  try {
    const response = await common_post(apis.dm_user_lay_ds_quyen, dataRequest);
    if (!!response) {
      return response.result;
    }
  } catch (error) {
    return null;
  }
};

export const apiLayDsNhomNhanSu = async (GOI_PHAN_MEM_ID ) => {
  try {
    const response = await common_post(apis.lay_nhom_nhan_su, {GOI_PHAN_MEM_ID});
    if (!!response) {
      return response.result;
    }
  } catch (error) {
    return null;
  }
};
