import { Button, Modal, Image } from "antd";
import { HoiDap } from "../../../../assets/img";
import i18n, { languageKeys } from "../../../../i18n";
import style from "./style.module.less";

const HoiDapModal = ({ visible = false, onOk = () => {} }) => {
  return (
    <Modal visible={visible} onOK={() => onOk} width={375} footer={null} closable={false} className={style["wrapper"]}>
      <div className={style["wrapper"]}>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Image src={HoiDap} preview={false} />
        </div>
        <div className={style["title-content"]}>
          <h1>{i18n.t(languageKeys.dat_cau_hoi_thanh_cong)}!</h1>
          <p>{i18n.t(languageKeys.content_sau_khi_dat_cau_hoi_thanh_cong)}</p>
        </div>
        <Button style={{ width: "100%" }} type="primary" onClick={onOk}>
          {i18n.t(languageKeys.dong_y)}
        </Button>
      </div>
    </Modal>
  );
};

export default HoiDapModal;
