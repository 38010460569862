import { useLocation } from "react-router-dom";
import { Tmedical } from "./Tmedical/Tmedical";
import { MediTv1 } from "./MediTv1/MediTv1";
import { MediTv2 } from "./MediTv2/MediTv2";
import { brands } from "../../constants";
import { useMemo } from "react";

export const TvMonitor = () => {
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const hospital_id = query.get("hospital_id");
  const partner_code = query.get("partner_code");
  const brand = query.get("brand");
  const room_id_1 = query.get("room_id_1");
  const room_id_2 = query.get("room_id_2");
  const room_id_3 = query.get("room_id_3");
  const room_id_4 = query.get("room_id_4");
  const version = query.get("version");
  const payment_required_in_bits = query.get("payment_required");

  const payment_required = useMemo(() => {
    // nếu là định dạng số
    if (!isNaN(payment_required_in_bits)) {
      // nếu số đó <= 0
      if (Number(payment_required_in_bits) <= 0) return false;
      return true;
    }
    return false;
  }, [payment_required_in_bits]);

  if (!!brand) {
    switch (brand) {
      case brands.medihub:
        return !isNaN(Number(version)) ? (
          Number(version) <= 0 ? (
            <MediTv1
              room_id={room_id_1}
              hospital_id={hospital_id}
              partner_code={partner_code}
              payment_required={payment_required}
            />
          ) : (
            <MediTv2
              room_id={room_id_1}
              hospital_id={hospital_id}
              partner_code={partner_code}
              payment_required={payment_required}
            />
          )
        ) : (
          <MediTv2
            room_id={room_id_1}
            hospital_id={hospital_id}
            partner_code={partner_code}
            payment_required={payment_required}
          />
        );
      case brands.tmedical:
        return (
          <Tmedical
            room_id_1={room_id_1}
            room_id_2={room_id_2}
            room_id_3={room_id_3}
            room_id_4={room_id_4}
            hospital_id={hospital_id}
            partner_code={partner_code}
            payment_required={payment_required}
          />
        );
      default:
        return (
          <MediTv2
            room_id={room_id_1}
            hospital_id={hospital_id}
            partner_code={partner_code}
            payment_required={payment_required}
          />
        );
    }
  }

  return <></>;
};
