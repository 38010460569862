import { useCallback, useEffect, useState } from "react";
import { apiLayDsTinh } from "../apisDanhMuc/apisDanhMuc";
import { debounce } from "lodash";
import formDanhMuc from "./formDanhMuc.module.less";
import i18n, { languageKeys } from "../../../../i18n";
import { Col, Form, Input, Row } from "antd";
import { fieldHuyen, fieldTinh } from "../fieldsDanhMuc";
import { Select } from "../../../../components";
import { HLog } from "../../../../helpers";

const FormHuyen = ({
  initData = {},
  visible = false,
  isCreateNew = false,
  form,
}) => {
  const [tinhTp, setTinhTp] = useState([]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceSearch = useCallback(
    debounce((value) => searchTinh(value), 1000),
    []
  );

  useEffect(() => {
    if (visible) {
      layDsTinhTP();

      if (!isCreateNew) {
        HLog(initData);

        form.setFields([
          {
            name: fieldHuyen.ma_tinh,
            value: initData[fieldTinh.ten_tinh],
          },
        ]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const layDsTinhTP = async (search_string) => {
    try {
      const data = await apiLayDsTinh({
        page: 1,
        search_string: search_string,
      });
      if (data.status === "OK") {
        setTinhTp(data.result);
      }
    } catch (error) {}
  };

  const searchTinh = (value) => {
    layDsTinhTP(value);
  };

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldHuyen.id_huyen}
                label={i18n.t(languageKeys.field_Ma_huyen_byt)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldHuyen.ma_huyen_byt}
                label={i18n.t(languageKeys.field_Ma_huyen_byt)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldHuyen.ten_huyen}
                label={i18n.t(languageKeys.field_Ten_huyen)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldHuyen.ma_tinh}
                label={i18n.t(languageKeys.field_Tinh_thanh)}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  onSearch={debounceSearch}
                  dataSource={tinhTp}
                  titleKey={fieldTinh.ten_tinh}
                  showSearch
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default FormHuyen;
