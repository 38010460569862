import { AntProvider } from "./AntProvider";
import { KeycloakProvider } from "./KeycloakProvider";
import { ReduxProvider } from "./ReduxProvider";

export const Provider = ({ children }) => {
  return (
    <ReduxProvider>
      <KeycloakProvider>
        <AntProvider>{children}</AntProvider>
      </KeycloakProvider>
    </ReduxProvider>
  );
};
