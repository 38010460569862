import { Col, Form, Input, Row, Switch } from "antd";
import { memo, useCallback, useEffect, useState } from "react";
import { Select } from "../../../../components";
import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import { fieldLoaiDvKiThuat, fieldNhomDvKiThuat, fieldNhomBhyt } from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";
import { debounce } from "lodash";
import { useSelector } from "react-redux";

const key_id_nhom_dvkt = fieldLoaiDvKiThuat.nhom_dv_ki_thuat;
const key_ten_nhom_dvkt = fieldLoaiDvKiThuat.ten_nhom_dvkt;

const FormLoaiDvkt = ({
  is_create_new = false,
  visible = false,
  initData = {},
  form,
}) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [dsNhomDvkt, setDsNhomDvkt] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [dsNhomBhyt, setDsNhomBhyt] = useState([]);
  const [selectedNhomBhyt, setSelectedNhomBhyt] = useState({});

  useEffect(() => {
    if (visible) {
      // const nhomDvktStringify = JSON.stringify({
      //   [key_id_nhom_dvkt]: initData[key_id_nhom_dvkt],
      //   [key_ten_nhom_dvkt]: initData[key_ten_nhom_dvkt],
      // });
      apiLayDsNhomBhyt("", initData[fieldLoaiDvKiThuat.nhom_bhyt]);
      form.setFields(
        Object.values(fieldLoaiDvKiThuat).map((name) => {
          if (name === fieldLoaiDvKiThuat.nhom_dv_ki_thuat) {
            return {
              name,
              value: initData[fieldLoaiDvKiThuat.ten_nhom_dvkt],
            };
          }

          return {
            name,
            value: initData[name],
          };
        })
      );
    } else {
      setDsNhomDvkt([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const apiLayDsNhomDvkt = async (search_string = "") => {
    try {
      setLoadingSearch(true);

      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        partner_code: userProfile.partner_code,
      };

      const res = await common_post(
        apis.dm_nhom_dvkt_lay_ds_co_hieu_luc,
        requestData
      );

      if (res.status === "OK") {
        const data = res.result.map((item) => ({
          [key_id_nhom_dvkt]: item[fieldNhomDvKiThuat.id],
          [key_ten_nhom_dvkt]: item[fieldNhomDvKiThuat.ten_nhom],
        }));

        setDsNhomDvkt(data);
      }

      setLoadingSearch(false);
    } catch (error) {
      HLog("Api lay ds nhom dvkt co hieu luc error", error);
      setLoadingSearch(false);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchNhomDvkt = useCallback(
    debounce((value) => apiLayDsNhomDvkt(value), 1000),
    []
  );

  const apiLayDsNhomBhyt = async (search_string = "", ID_NHOM_BHYT) => {
    console.log(ID_NHOM_BHYT);
    try {
      const requestData = {
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        partner_code: userProfile.partner_code,
        limit: 1000,
      };

      const res = await common_post(apis.dm_nhom_bhyt_lay_ds, requestData);

      if (res.status === "OK") {
        setDsNhomBhyt(res.result);
        if (!!ID_NHOM_BHYT) {
          setSelectedNhomBhyt(
            res.result[
              res.result
                .map(function (e) {
                  return e.ID;
                })
                .indexOf(ID_NHOM_BHYT)
            ]
          );
        }
      }
    } catch (error) {
      HLog("Api lay ds nhom bhyt error", error);
    }
  };

  function onSelectNhomBHYT(id) {
    setSelectedNhomBhyt(
      dsNhomBhyt[
        dsNhomBhyt
          .map(function (e) {
            return e.ID;
          })
          .indexOf(id)
      ]
    );
  }

  const handleSelectNhom = async (id) => {
    let nhom = dsNhomBhyt.filter((item) => {
      return item.ID === id;
    });
  };

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            {/* <Col span={12}>
              <Form.Item
                name={fieldLoaiDvKiThuat.stt}
                label={i18n.t(languageKeys.field_Stt)}
              >
                <InputNumber
                  min={1}
                  placeholder={i18n.t(languageKeys.common_Nhap)}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col> */}

            <Col span={12}>
              <Form.Item
                name={fieldLoaiDvKiThuat.id_loai_dv_ki_thuat}
                label={i18n.t(languageKeys.field_Id_loai_dv_ki_thuat)}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: i18n.t(languageKeys.vui_long_nhap),
                    // },
                  ]
                }
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldLoaiDvKiThuat.nhom_dv_ki_thuat}
                label={i18n.t(languageKeys.field_Nhom_dv_ki_thuat)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsNhomDvkt}
                  titleKey={fieldLoaiDvKiThuat.ten_nhom_dvkt}
                  loading={loadingSearch}
                  showSearch
                  onSearch={searchNhomDvkt}
                  onDropdownVisibleChange={(bool) => bool && apiLayDsNhomDvkt()}
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldLoaiDvKiThuat.ten_loai_dv_ki_thuat}
                label={i18n.t(languageKeys.field_Ten_loai_dv_ki_thuat)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={fieldLoaiDvKiThuat.nhom_bhyt}
                label={i18n.t(languageKeys.field_Nhom_bhyt)}
                // rules={[
                //   {
                //     required: true,
                //     message: i18n.t(languageKeys.vui_long_nhap),
                //   },
                // ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsNhomBhyt}
                  titleKey={fieldNhomBhyt.ten_nhom}
                  valueKey={fieldNhomBhyt.id}
                  showSearch
                  onSelect={(event) => {
                    handleSelectNhom(event);
                    onSelectNhomBHYT(event);
                  }}
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

        <Form.Item
          name={fieldLoaiDvKiThuat.hien_thi}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </div>
    </>
  );
};

export default memo(FormLoaiDvkt);
