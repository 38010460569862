import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";

export const layDanhSachHoaDon = async ({
  partner_code,
  BENH_NHAN_ID = "",
  limit = keys.limit,
  BENH_VIEN_ID,
  LICH_KHAM_ID,
  page = 1,
  PHAN_LOAI
}) => {
  let dataRequest = {
    partner_code,
    BENH_NHAN_ID,
    BENH_VIEN_ID,
    LICH_KHAM_ID,
    limit,
    page,
    PHAN_LOAI
  };
  try {
    const response = await common_post(
      apis.vien_phi_lay_danh_sach_hoa_don_theo_benh_nhan,
      dataRequest,
      true
    );
    HLog("Lấy danh sách hóa đơn", response);
    if (response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("Lấy danh sách hóa đơn lỗi ", error);
    return null;
  }
};

export const layPhongTheoKhoa = async ({
  partner_code,
  KHOA_ID,
  search_string = "",
}) => {
  let dataRequest = { partner_code, KHOA_ID, search_string };
  try {
    const response = await common_post(
      apis.dm_lay_phong_theo_khoa,
      dataRequest,
      false
    );
    if (response.status === "OK") {
      return response.result;
    }
    return null;
  } catch (error) {
    HLog(error);
    return null;
  }
};

export const addUser = async (dataRequest) => {
  try {
    const response = await common_post(apis.dm_them_moi_user, dataRequest);
    return response;
  } catch (error) {
    return null;
  }
};

export const deleteUser = async (dataRequest) => {
  try {
    const response = await common_post(apis.dm_xoa_ds_user, dataRequest);
    if (response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog(error);
    return null;
  }
};

export const updateUser = async (dataRequest) => {
  try {
    const response = await common_post(apis.dm_cap_nhat_user, dataRequest);
    return response;
  } catch (error) {
    return null;
  }
};

export const layDanhSachHoaDon2 = async ({
  partner_code,
  limit = keys.limit,
  BENH_VIEN_ID,
  page = 1,
  // GOI_DV_ID,
  NGAY,
  BENH_NHAN_ID,
}) => {
  let dataRequest = {
    partner_code,
    BENH_VIEN_ID,
    limit,
    page,
    // GOI_DV_ID,
    PHAN_LOAI:"THANH_TOAN",
    NGAY,
    BENH_NHAN_ID,
  };
  try {
    const response = await common_post(
      apis.lay_ds_hoa_don_theo_benh_nhan_2,
      dataRequest,
      true
    );
    HLog("Lấy danh sách hóa đơn", response);
    if (response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("Lấy danh sách hóa đơn lỗi ", error);
    return null;
  }
};