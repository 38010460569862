import { Col, Form, Input, Row, Switch } from "antd";
import { Select } from "../../../../components";
import i18n, { languageKeys } from "../../../../i18n";
import { fieldKhoTuTruc } from "../fieldsDanhMuc";
import formDanhMuc from "./formDanhMuc.module.less";
import { danhSachKieuKho, danhSachLoaiKho } from "../../../../constants/data";

const FormKhoaTuTruc = ({
  initData = {},
  visible = false,
  isCreateNew = false,
  form,
}) => {
  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldKhoTuTruc.ma_kho}
                label={i18n.t(languageKeys.field_Ma_kho)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldKhoTuTruc.ten_kho}
                label={i18n.t(languageKeys.field_Ten_kho)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldKhoTuTruc.thu_kho}
                label={i18n.t(languageKeys.field_Thu_kho)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldKhoTuTruc.loai_kho}
                label={i18n.t(languageKeys.field_Loai_kho)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={danhSachLoaiKho}
                  titleKey="name"
                  valueKey="key"
                  // showSearch
                  // filterOption={(input, option) =>
                  //   option.props.children
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0 ||
                  //   option.props.value
                  //     .toLowerCase()
                  //     .indexOf(input.toLowerCase()) >= 0
                  // }
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldKhoTuTruc.kieu_kho}
                label={i18n.t(languageKeys.field_Kieu_kho)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  //showSearch
                  dataSource={danhSachKieuKho}
                  titleKey="name"
                  valueKey="key"
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

        <Form.Item name={fieldKhoTuTruc.ghi_chu}>
          <Input.TextArea
            rows={3}
            placeholder={i18n.t(languageKeys.common_Nhap)}
          />
        </Form.Item>

        <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

        <Form.Item
          name={fieldKhoTuTruc.hien_thi}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </div>
    </>
  );
};

export default FormKhoaTuTruc;
