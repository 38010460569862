import { Tooltip, Button, notification, Row } from 'antd'
import moment from 'moment'
import React, {useEffect, useState, useImperativeHandle, memo, useRef} from 'react'
import { useSelector } from 'react-redux'
import { apis ,keys} from '../../../../../../constants'
import { Table, ActionButton, ConfirmModal, WarningModal } from '../../../../../../components'
// import { apis, keys } from '../../../../../constants'
import { common_post, formatCurrency, handleCheckPermissions, handleErrorHuyPhieu, rid } from '../../../../../../helpers'
import { StarCheck } from '../../../../KhoThuoc/components'
import style from "./style.module.less"
import { initDataFilter } from '../../../NhapXuat'
import i18n, { languageKeys } from '../../../../../../i18n'

function DanhSachPhieuXuat({
    onShowDetail = () => {},
    dataFilter = initDataFilter,
    phanLoai = "",
    onPrint = () => {},
    disableDelete = false,
    disablePrint = false,
    tooltipInTheoQuyen = false,
    tooltipXoaTheoQuyen = false,
    tooltipDuyetTheoQuyen = false,
}, ref) {
    const confirmRef = useRef()
    const warningRef = useRef()
    const userProfile = useSelector(state => state.auth.user)
    const [dsPhieu, setDsPhieu] = useState([])
    const [total, setTotal] = useState(0)
    const [currentPage, setCurrrentPage] = useState(1)
    const [loading, setLoading] = useState(false)
    const [deleteItem , setDeleteItem] = useState()
    const [warningContent, setWarningContent] = useState("")

    useImperativeHandle(
        ref,
        () => ({
            reload() {
                getDanhSachPhieu({})
            },

        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    )

    useEffect(() => {
        getDanhSachPhieu({filter :dataFilter})
    }, [dataFilter])

   async function getDanhSachPhieu({
       page = currentPage,
       filter = dataFilter,
       SAP_XEP = { COT: "", KIEU: "ASC" },
   }){
      setLoading(true)
       try {
           let dataRequest = {
                partner_code : userProfile.partner_code,
                BENH_VIEN_ID : userProfile.BENH_VIEN_ID ,
                LOAI_PHIEU : "PHIEU_XUAT",
                ...filter ,
                limit : keys.limit,
                page,
                SAP_XEP
           }
           let response = await common_post(apis.lay_ds_phieu_nhap_xuat, dataRequest)
           if (response && response.status === "OK") {
               setDsPhieu(response.result.map(item => ({...item, key : rid()})))
               setTotal(response.total)
               setCurrrentPage(response.current_page)
               setLoading(false)
           }
       } catch (error) {

       }
   }


   async function updateStatus(dataRequest){
       try {
        const response = await common_post(
            apis.cai_dat_noi_bat_phieu,
            dataRequest
          );
          if (response && response.status === "OK") {
            getDanhSachPhieu({})
          }
       } catch (error) {

       }
   }


  async function handlePrint(item){
        const api = item.PHAN_LOAI === "XUAT_TRA_NCC" ?  apis.xuat_tra_pdf : apis.xuat_huy_pdf;
        onPrint(api, item.ID)
  }

  async function handleCancelPhieu(item){
    let req = {
      partner_code : userProfile.partner_code,
      BENH_VIEN_ID : userProfile.BENH_VIEN_ID ,
      PHIEU_THUOC_ID : item.ID,
      NGUOI_HUY_ID : userProfile.NHANSU_ID,
      PHAN_LOAI : item.PHAN_LOAI

  }
  try {
    const res = await common_post(apis.huy_phieu_nhap_xuat, req)
    if (res && res.status === "OK") {
      notification.success({message : i18n.t(languageKeys.cap_nhat_thanh_cong)})
      getDanhSachPhieu({})
      deleteItem && setDeleteItem()
      confirmRef.current.close()
    }else if (res && res.status === "KO") {
        setWarningContent(handleErrorHuyPhieu(res.error_code || null))
        deleteItem && setDeleteItem()
        confirmRef.current.close()
        warningRef.current.open()
    }
  } catch (error) {

  }
  }

   const columnsNhap = [
    {
      title: i18n.t(languageKeys.field_Ngay_tao),
      dataIndex: "",
      key: "NGAY_GIO",
      width : 150,
      render: (_, record) => {
        return (
          <>
            {record.GIO} - {moment(record.NGAY).format('DD/MM/YYYY')}
          </>
        );
      },
    },
    {
      title: i18n.t(languageKeys.ma_phieu),
       dataIndex: "MA_PHIEU",
       key: "MA_PHIEU",
       width : 100,
       render : (ma) => <div style={{color :"#6576FF"}}>{ma}</div>
    },
    {
      title: i18n.t(languageKeys.loai_phieu),
      dataIndex: "PHAN_LOAI",
      key: "PHAN_LOAI",
      width : 120,
      render: (PHAN_LOAI,record) => {
        return (
          <Tooltip
            placement="bottom"
            title={DATA_PHAN_LOAI[PHAN_LOAI]}
          >
            <span className={style["text-ellipsis"]}>
              {DATA_PHAN_LOAI[PHAN_LOAI]}
            </span>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t(languageKeys.field_Ten_kho),
      dataIndex: "TEN_KHO",
      key: "TEN_KHO",
      width : 130,
      render: (record) => {
        return (
          <Tooltip placement="bottom" title={record}>
            <span className={style["text-ellipsis"]}>{record}</span>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t(languageKeys.field_Nguoi_tao),
      dataIndex: "NGUOI_TAO",
      key: "NGUOI_TAO",
      width : 130,
      render: (record) => {
        return (
          <Tooltip placement="bottom" title={record}>
            <span className={style["text-ellipsis"]}>{record}</span>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t(languageKeys.nha_cung_cap),
      dataIndex: "TEN_NCC",
      key: "TEN_NCC",
      width : 150,
      render: (record) => {
        return (
          <Tooltip placement="bottom" title={record}>
            <span className={style["text-ellipsis"]}>{record || "-"}</span>
          </Tooltip>
        );
      },
    },
    {
      title: i18n.t(languageKeys.tong_tien),
      dataIndex: "TONG_CHI_PHI",
      key: "TONG_CHI_PHI",
      width : 120,
      render: (val) => <div>{formatCurrency(val, "đ", true)}</div>,
    },
    {
      title: i18n.t(languageKeys.field_Mien_giam),
      dataIndex: "TONG_MIEN_GIAM",
      key: "TONG_MIEN_GIAM",
      width : 110,
      render: (val) => <div>{formatCurrency(val, "đ", true)}</div>,
    },
    {
      title: i18n.t(languageKeys.tong_tt),
      dataIndex: "TONG_THANH_TOAN",
      key: "TONG_THANH_TOAN",
      width : 110,
      render: (val) => (
        <div style={{ color: "#2CB2A5" }}>{formatCurrency(val, "đ", true)}</div>
      ),
    },
    {
      title: i18n.t(languageKeys.trang_thai),
      dataIndex: "TRANG_THAI_PHIEU",
      key: "TRANG_THAI_PHIEU",
      width : 100,
      render: (TRANG_THAI_PHIEU) => (
        <div style={{ color:  getColor(TRANG_THAI_PHIEU) }}>{getTrangThaiPhieu(TRANG_THAI_PHIEU)}</div>
      ),
    },
  ];

  const getTrangThaiPhieu = (status) => {
    switch (status) {
      case "HUY":
        return i18n.t(languageKeys.status_Da_huy)
      case "HOAN_THANH" :
        return i18n.t(languageKeys.da_HT)
      case "DA_XAC_NHAN" :
        return i18n.t(languageKeys.da_xac_nhan)
      case "CHO_DUYET":
        return i18n.t(languageKeys.cho_duyet)
      default:
        return ""
    }
  }

  const getColor = (status) => {
    switch (status) {
      case "HUY":
        return "red"
      case "HOAN_THANH" :
        return "#2CB2A5"
      default:
        return "#4263eb"
    }
  }

  return (
    <div style={{padding : "20px"}}>
      <Table
          dataSource={dsPhieu}
          limit={keys.limit}
          totalResult={total}
          showPagination
          //style={{padding : "10px"}}
          columns={[
              // {
              //     title: () => {
              //       return <StarCheck onClickStar = {filterNoiBat} isChecked = {isFilterNoiBat}/>;
              //     },
              //     key: "action_btn",
              //     dataIndex: "action_btn",
              //     width: "30px",
              //     render: (_, record) => <StarCheck onClickStar = {onClickStar} dataItem = {record} isChecked = {record.NOI_BAT}/>,
              //   },
                ...columnsNhap,
                {
                  key:"action",
                  fixed: "right",
                  width: 80,
                  render:(_,record) => (<ActionButton
                    tooltipXoaTheoQuyen={tooltipXoaTheoQuyen}
                    tooltipInTheoQuyen={tooltipInTheoQuyen}
                    showDelete={true}
                    showEdit={false}
                    deleteDisabled = {disableDelete ||record.TRANG_THAI_PHIEU === "HUY" || record.PHAN_LOAI === "BAN_BENH_NHAN"}
                    disabledPrint= { disablePrint || record.TRANG_THAI_PHIEU === "HUY" || (record.PHAN_LOAI === "BAN_BENH_NHAN" && record.TRANG_THAI_PHIEU !== "HOAN_THANH")}
                    onDelete = {() => {
                      setDeleteItem(record)
                      confirmRef.current.open()
                    }}
                    showPrint
                    onPrint={e => {handlePrint(record)}}
                  />)
                }
          ]}
          currentPage={currentPage}
          onClickRow ={onShowDetail}
          onNext = {() => getDanhSachPhieu({page : currentPage + 1})}
          onPrev = {() => getDanhSachPhieu({page : currentPage - 1})}
          loading={loading}
          scroll={{x : columnsNhap.reduce(
            (sum, item) => sum + (item.width || 100),
            0
          ) + 80, y: 'calc(100vh - 250px)' }}
      />
        <ConfirmModal
                danger = {true}
                ref={confirmRef}
                content={i18n.t(languageKeys.confirm_cancel_ticket)}
                onOk={() => handleCancelPhieu(deleteItem)}
                onCancel = {() => {
                  confirmRef.current.close()
                  setDeleteItem()
              }}
              okText = {i18n.t(languageKeys.title_Xac_nhan)}

          />
          <WarningModal ref={warningRef}
            title = {i18n.t(languageKeys.common_Canh_bao)}
            content = {warningContent}
            children = {<Row justify='center' style={{marginTop : "10px",}}>
              <Button type="primary" ghost onClick={() => warningRef.current.close()} style={{ width : "150px"}}>{i18n.t(languageKeys.common_Thoat)}</Button>
            </Row>}
          />
    </div>
  )
}

export default memo(React.forwardRef(DanhSachPhieuXuat))

const DATA_PHAN_LOAI = {
  XUAT_TRA_NCC : i18n.t(languageKeys.phan_loai_xuat_tra_ncc),
  XUAT_HUY : i18n.t(languageKeys.phan_loai_xuat_huy),
  BAN_BENH_NHAN : i18n.t(languageKeys.phan_loai_ban_benh_nhan),
  XUAT_KHAC : i18n.t(languageKeys.phan_loai_xuat_khac),
  XUAT_VO_HONG : i18n.t(languageKeys.phan_loai_xuat_vo_hong),
  XUAT_HET_HAN : i18n.t(languageKeys.phan_loai_xuat_het_han)
}
