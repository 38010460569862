import { apis, keys } from "../../../../constants";
import { common_post, HLog, formatPriceToNumber } from "../../../../helpers";
import { fieldDichVuKiThuat, fieldDonViTinh, fieldLoaiDvKiThuat, fieldNhomDvKiThuat, fieldNhomHoaDon, fieldPhong } from "../fieldsDanhMuc";

export const apiLayDsDvKiThuat = async ({
  BENH_VIEN_ID = "",
  search_string = "",
  partner_code = "",
  page = 1,
  limit = keys.limit,
  LOAI_DV_ID = "",
}) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      partner_code,
      page,
      limit,
      LOAI_DV_ID,
    };

    const response = await common_post(apis.dm_dvkt_lay_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api lay ds dvkt error", error);
  }
};

/**
 * API thêm dvkt
 */
export const apiThemDvkt = async ({ BENH_VIEN_ID = "", data = {}, partner_code = "" }) => {
  try {
    const key_nhom_hoa_don = fieldDichVuKiThuat.nhom_hoa_don;
    const key_loai_dv = fieldDichVuKiThuat.loai_dich_vu;
    const key_nhom_dv = fieldDichVuKiThuat.nhom_dich_vu;
    const key_phong_thuc_hien = fieldDichVuKiThuat.phong_thuc_hien;
    const key_phong_lay_mau = fieldDichVuKiThuat.phong_lay_mau;
    const key_gia_thu_phi = fieldDichVuKiThuat.gia_thu_phi;
    const key_gia_bhyt = fieldDichVuKiThuat.gia_bhyt;
    const key_gia_dich_vu = fieldDichVuKiThuat.gia_dich_vu;
    const key_gia_nuoc_ngoai = fieldDichVuKiThuat.gia_nuoc_ngoai;
    const key_dvt = fieldDichVuKiThuat.don_vi_tinh;

    if (!!data[key_dvt]) {
      data[key_dvt] = JSON.parse(data[key_dvt])[fieldDonViTinh.id];
    }

    if (!!data[key_nhom_hoa_don]) {
      data[key_nhom_hoa_don] = JSON.parse(data[key_nhom_hoa_don])[fieldNhomHoaDon.id];
    }

    if (!!data[key_loai_dv]) {
      data[key_loai_dv] = JSON.parse(data[key_loai_dv])[fieldLoaiDvKiThuat.id];
    }

    if (!!data[key_nhom_dv]) {
      data[key_nhom_dv] = JSON.parse(data[key_nhom_dv])[fieldNhomDvKiThuat.id];
    }

    if (!!data[key_phong_thuc_hien] && data[key_phong_thuc_hien].length > 0) {
      data[key_phong_thuc_hien] = data[key_phong_thuc_hien].map((item) => JSON.parse(item)[fieldPhong.id]);
    }

    if (!!data[key_phong_lay_mau] && data[key_phong_lay_mau].length > 0) {
      data[key_phong_lay_mau] = data[key_phong_lay_mau].map((item) => JSON.parse(item)[fieldPhong.id]);
    }

    if (!!data[key_gia_thu_phi]) {
      data[key_gia_thu_phi] = formatPriceToNumber(data[key_gia_thu_phi]);
    }

    if (!!data[key_gia_bhyt]) {
      data[key_gia_bhyt] = formatPriceToNumber(data[key_gia_bhyt]);
    }

    if (!!data[key_gia_dich_vu]) {
      data[key_gia_dich_vu] = formatPriceToNumber(data[key_gia_dich_vu]);
    }

    if (!!data[key_gia_nuoc_ngoai]) {
      data[key_gia_nuoc_ngoai] = formatPriceToNumber(data[key_gia_nuoc_ngoai]);
    }

    if (!data[fieldDichVuKiThuat.ti_le_dung_tuyen]) {
      data[fieldDichVuKiThuat.ti_le_dung_tuyen] = 0;
    }

    if (!data[fieldDichVuKiThuat.ti_le_trai_tuyen]) {
      data[fieldDichVuKiThuat.ti_le_trai_tuyen] = 0;
    }

    if (!data[fieldDichVuKiThuat.so_ngay_tai_chi_dinh]) {
      data[fieldDichVuKiThuat.so_ngay_tai_chi_dinh] = 0;
    }

    let requestData = {
      data: [data],
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_dvkt_them_moi, requestData);
  } catch (error) {
    HLog("Api them moi dvkt error", error);
  }
};

export const apiNhapDsDvkt = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        if (key === "GIA_THU_PHI" || key === "GIA_BHYT" || key === "GIA_NUOC_NGOAI" || key === "STT_DV") {
          item[key] = !!item[key] ? Number(item[key]) : null;
        } else if (key === "ID_DVT") {
          item[key] = !!item[key] ? item[key].toString() : null;
        } else item[key] = item[key].toString();
      });

      return item;
    });

    let requestData = {
      data,
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_dvkt_them_moi, requestData);
  } catch (error) {
    HLog("Api nhap ds dvkt error", error);
  }
};

/**
 * API sửa dvkt
 */
export const apiSuaDvkt = async ({ data = {}, currentData = {} }) => {
  try {
    const key_nhom_hoa_don = fieldDichVuKiThuat.nhom_hoa_don;
    const key_loai_dv = fieldDichVuKiThuat.loai_dich_vu;
    const key_nhom_dv = fieldDichVuKiThuat.nhom_dich_vu;
    const key_phong_thuc_hien = fieldDichVuKiThuat.phong_thuc_hien;
    const key_phong_lay_mau = fieldDichVuKiThuat.phong_lay_mau;
    const key_gia_thu_phi = fieldDichVuKiThuat.gia_thu_phi;
    const key_gia_bhyt = fieldDichVuKiThuat.gia_bhyt;
    const key_gia_dich_vu = fieldDichVuKiThuat.gia_dich_vu;
    const key_gia_nuoc_ngoai = fieldDichVuKiThuat.gia_nuoc_ngoai;
    const key_id_dvt = fieldDichVuKiThuat.don_vi_tinh;

    // eslint-disable-next-line no-useless-escape
    if (!!data[key_id_dvt] && typeof data[key_id_dvt] === "string" && data[key_id_dvt].includes(`{\"`)) {
      data[key_id_dvt] = JSON.parse(data[key_id_dvt])[fieldDonViTinh.id];
    } else {
      data[key_id_dvt] = currentData[key_id_dvt];
    }

    // eslint-disable-next-line no-useless-escape
    if (typeof data[key_nhom_dv] === "string" && data[key_nhom_dv].includes(`{\"`)) {
      data[key_nhom_dv] = JSON.parse(data[key_nhom_dv])[fieldNhomDvKiThuat.id];
    } else {
      data[key_nhom_dv] = currentData[key_nhom_dv];
    }

    // eslint-disable-next-line no-useless-escape
    if (typeof data[key_loai_dv] === "string" && data[key_loai_dv].includes(`{\"`)) {
      data[key_loai_dv] = JSON.parse(data[key_loai_dv])[fieldLoaiDvKiThuat.id];
    } else {
      data[key_loai_dv] = currentData[key_loai_dv];
    }

    // eslint-disable-next-line no-useless-escape
    if (!!data[key_nhom_hoa_don] && typeof data[key_nhom_hoa_don] === "string" && data[key_nhom_hoa_don].includes(`{\"`)) {
      data[key_nhom_hoa_don] = JSON.parse(data[key_nhom_hoa_don])[fieldNhomHoaDon.id];
    } else {
      data[key_nhom_hoa_don] = currentData[key_nhom_hoa_don];
    }

    if (!!data[key_phong_thuc_hien] && data[key_phong_thuc_hien].length > 0) {
      data[key_phong_thuc_hien] = data[key_phong_thuc_hien].map((item) => JSON.parse(item)[fieldPhong.id]);
    }

    if (!!data[key_phong_lay_mau] && data[key_phong_lay_mau].length > 0) {
      data[key_phong_lay_mau] = data[key_phong_lay_mau].map((item) => JSON.parse(item)[fieldPhong.id]);
    }

    if (!!data[key_gia_thu_phi]) {
      data[key_gia_thu_phi] = formatPriceToNumber(data[key_gia_thu_phi]);
    }

    if (!!data[key_gia_bhyt]) {
      data[key_gia_bhyt] = formatPriceToNumber(data[key_gia_bhyt]);
    }

    if (!!data[key_gia_dich_vu]) {
      data[key_gia_dich_vu] = formatPriceToNumber(data[key_gia_dich_vu]);
    }

    if (!!data[key_gia_nuoc_ngoai]) {
      data[key_gia_nuoc_ngoai] = formatPriceToNumber(data[key_gia_nuoc_ngoai]);
    }

    if (!data[fieldDichVuKiThuat.ti_le_dung_tuyen]) {
      data[fieldDichVuKiThuat.ti_le_dung_tuyen] = 0;
    }

    if (!data[fieldDichVuKiThuat.ti_le_trai_tuyen]) {
      data[fieldDichVuKiThuat.ti_le_trai_tuyen] = 0;
    }

    if (!data[fieldDichVuKiThuat.so_ngay_tai_chi_dinh]) {
      data[fieldDichVuKiThuat.so_ngay_tai_chi_dinh] = 0;
    }

    let requestData = { ...data };

    return await common_post(apis.dm_dvkt_cap_nhat, requestData);
  } catch (error) {
    HLog("Api cap nhat dvkt error", error);
  }
};

/**
 * API xóa dvkt
 */
export const apiXoaDvkt = async ({ partner_code = "", data = {} }) => {
  try {
    const listId = [data[fieldLoaiDvKiThuat.id]];

    let requestData = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_dvkt_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa dvkt error", error);
  }
};

/**
 * API xóa ds dvkt
 */
export const apiXoaDsDvkt = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldDichVuKiThuat.id]);

    let requestData = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_dvkt_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa ds dvkt error", error);
  }
};

/**
 * API khoá danh sách dvkt
 */
export const apiKhoaDsDvkt = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldDichVuKiThuat.id]);
    const req = { partner_code, listId };
    const api = lock ? apis.dm_dvkt_lock_ds : apis.dm_dvkt_unlock_ds;
    const response = await common_post(api, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach dvkt error", error);
  }
};

export const apiExportDvkt = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_dvkt_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template dvkt error" : "Export ds dvkt error");
  }
};
