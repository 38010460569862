import { useStore } from "react-redux";
import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldNhomDvKiThuat } from "../fieldsDanhMuc";
import store from "../../../../ducks/configStore"

/**
 * API thêm nhóm dvkt
 */
export const apiLayDsNhomDvkt = async ({ BENH_VIEN_ID = "", search_string = "", partner_code = "", page = 1, limit = keys.limit }) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      partner_code,
      page,
      limit,
    };

    const response = await common_post(apis.dm_nhom_dvkt_lay_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api lay ds nhom dvkt error", error);
  }
};

export const apiLayDsNhomDvktCoHieuLuc = async ({ BENH_VIEN_ID = "", search_string = "", partner_code = "", page = 1, limit = keys.limit }) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      partner_code,
      page,
      limit,
    };

    const response = await common_post(apis.dm_nhom_dvkt_lay_ds_co_hieu_luc, requestData);

    return response;
  } catch (error) {
    HLog("Api lay ds nhom dvkt co hieu luc error", error);
  }
};

/**
 * API thêm nhóm dvkt
 */
export const apiThemNhomDvkt = async ({ BENH_VIEN_ID = "", data = {}, partner_code = "" }) => {
  try {
    const _store = store.getState()
    let { auth} = _store
    let requestData = {
      data: [data],
      BENH_VIEN_ID,
      partner_code,
      NGUOI_TAO : auth.user ? auth.user.NHANSU_ID : null
    };

    return await common_post(apis.dm_nhom_dvkt_them_moi, requestData);
  } catch (error) {
    HLog("Api them moi nhom dvkt error", error);
  }
};

export const apiNhapDsNhomDvkt = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        item[key] = item[key].toString();
      });
      return item;
    });
    let requestData = {
      data,
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_nhom_dvkt_them_moi, requestData);
  } catch (error) {
    HLog("Api nhap ds nhom dvkt error", error);
  }
};

/**
 * API sửa nhóm dvkt
 */
export const apiSuaNhomDvkt = async ({ data = {}, NHANSU_ID = ""}) => {
  HLog("apiSuaNhomDvkt USERNAME: ", NHANSU_ID);
  try {
    const key_nguoi_sua = fieldNhomDvKiThuat.nguoi_sua;
    data[key_nguoi_sua] = NHANSU_ID;
    let requestData = { ...data };

    return await common_post(apis.dm_nhom_dvkt_cap_nhat, requestData);
  } catch (error) {
    HLog("Api cap nhat nhom dvkt error", error);
  }
};

/**
 * API xóa nhóm dvkt
 */
export const apiXoaNhomDvkt = async ({ partner_code = "", data = {} }) => {
  try {
    const listId = [data[fieldNhomDvKiThuat.id]];

    let requestData = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_nhom_dvkt_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa nhom dvkt error", error);
  }
};

/**
 * API xóa ds nhóm dvkt
 */
export const apiXoaDsNhomDvkt = async ({ partner_code = "", data = {} }) => {
  try {
    const listId = data.map((item) => item[fieldNhomDvKiThuat.id]);

    let requestData = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_nhom_dvkt_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa ds nhom dvkt error", error);
  }
};

/**
 * API khoá danh sách nhom dvkt
 */
export const apiKhoaDsNhomDvkt = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldNhomDvKiThuat.id]);
    const req = { partner_code, listId };
    const api = lock ? apis.dm_nhom_dvkt_lock_ds : apis.dm_nhom_dvkt_unlock_ds;
    const response = await common_post(api, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach nhom dvkt error", error);
  }
};

export const apiExportNhomDvkt = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_nhom_dvkt_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template nhom dvkt error" : "Export ds nhom dvkt error");
  }
};
