"use strict";

import { Component } from "react";
import ConnectionManager from "./ConnectionManager";

var mEventSource = null;

export default class extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConnectInternet: true,
      eventConnection: null,
      callEventHandlers: null,
    };
  }

  componentDidMount() {
    console.log("EventSourceClient profile: ", this.props.userProfile);
    this._initData();
    this._initListener();
  }

  componentWillUnmount() {
    console.log("EventSourceClient componentWillUnmount...");
  }

  //Hàm connect đến sv deepcare để lắng nghe sự kiện
  connectServer = (url) => {
    console.log("EventSourceClient connectServer...");
    ConnectionManager.getInstance()._connectServer(url);
    if (!mEventSource) {
      this._initData();
      this._initListener();
    }
  };

  //hàm kiểm tra trạng thái connect lên sv deepcare lắng nghe sự kiện
  isConnected = () => {
    return ConnectionManager.getInstance().isConnected();
  };

  //đóng connect event source
  onCloseConnect = () => {
    ConnectionManager.getInstance()._closeConnect();
  };

  //hàm thực hiện khởi tạo dữ liệu, khởi tạo callback
  _initData = () => {
    if (!mEventSource) {
      mEventSource = null;
    }
    mEventSource = ConnectionManager.getInstance().getEventSource();
    this.setState({
      eventConnection: this.props.eventConnection,
      callEventHandlers: this.props.callEventHandlers,
    });
  };

  //hàm khởi tạo các sự kiện lắng nghe
  _initListener = () => {
    if (mEventSource != null) {
      console.log("EventSourceClient _initListener...");

      //các events liên quan đến xử lí videocall
      //event khi có cuộc gọi đến
      mEventSource.addEventListener("responseReloadCIS", (event) => {
        console.log("EventSourceClient responseReloadCIS event: " + event.data);
        let dataEvent = {};
        if (event.data && event.data !== "") {
          dataEvent = JSON.parse(event.data);
        }
        let callEventHandlers = this.state.callEventHandlers;
        if (callEventHandlers && callEventHandlers.reloadAppointment)
          callEventHandlers.reloadAppointment(dataEvent);
      });
    }
  };

  //clear listener đang tồn tại
  removeAllListener() {
    if (mEventSource) {
      console.log("EventSourceClient removeAllListener...");
      ConnectionManager.getInstance()._removeAllListeners();
    }
  }

  render() {
    return null;
  }
}
