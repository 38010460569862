import { Button, Form, notification } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { FormDrawer } from "../../../../../components";
import { HLog, rid } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import { apiThemThuoc } from "../../../../QuanLyHeThong/QuanLyDanhMuc/apisDanhMuc/apisDmThuoc";
import { ThemMoiThuoc } from "../../../KhoThuoc/components";
import modal from "./modal.module.less";
import { useSelector } from "react-redux";
import { widthDrawerKeys } from "../../../../../constants/keys";

const ModalThemThuoc = forwardRef(({ onOk = () => {}, onCancel = () => {}, width = 400, doAfterSubmit = (data) => {} }, ref) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const forceRender = useState();

  const [form] = Form.useForm();

  useImperativeHandle(ref, () => ({
    open() {
      setVisible(true);
    },
    close() {
      onClose();
    },
    reset() {
      resetList();
    },
    visible,
    loading(bool) {
      setLoading(bool);
    },
  }));
  const onClose = () => {
    onCancel();
    if (loading) setLoading(false);
    setVisible(false);
  };

  const resetList = () => {
    setLoading(false);
    form.resetFields();
  };

  useEffect(() => {
    HLog(visible);
    forceRender[1](rid());
  }, [visible]);
  //hàm xử lí thêm mới thuốc
  const handleThemThuocMoi = async (values) => {
    HLog("THUOC MOI thuoc values: ", values);
    let body = {
      data: values,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
    };

    let response = await apiThemThuoc(body);
    if (!!response && response.status === "OK") {
      doAfterSubmit(response, values);
      onClose();
      resetList();
    } else {
      notification.error({
        message: i18n.t(languageKeys.noti_them_thuoc_that_bai),
        placement: "topLeft",
      });
      return;
    }
  };

  return (
    <FormDrawer
      width={widthDrawerKeys.large}
      title={i18n.t(languageKeys.field_Them_moi_thuoc)}
      visible={visible}
      back
      // onClickBack={onClose}
      onOk={() => form.submit()}
      onCancel={onClose}
      loading={loading}
      hiddenTopAction
      footer={
        <div className={modal['footer-group']}>
          <Button className={modal['footer-btn']} type="primary" ghost onClick={onClose}>
            {i18n.t(languageKeys.common_Thoat)} (ESC)
          </Button>{" "}
          <Button className={modal['footer-btn']} type="primary" onClick={() => form.submit()}>{i18n.t(languageKeys.common_Luu)}</Button>
        </div>
      }
      cancelText={i18n.t(languageKeys.common_Thoat) + " (ESC)"}
      okText={i18n.t(languageKeys.common_Luu)}
    >
      `{" "}
      <Form layout="vertical" form={form} className={modal["wrapper"]} onFinish={handleThemThuocMoi}>
        <ThemMoiThuoc form={form} showDelete={true} visible={visible} />
      </Form>
      `
    </FormDrawer>
  );
});

export default ModalThemThuoc;
