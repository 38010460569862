import { Col, Form, Input, Row, Switch } from "antd";
import { Select } from "../../../../components";
import { common_post, HLog, isEmptyObject } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";
import { apis, keys } from "../../../../constants";
import formDanhMuc from "./formDanhMuc.module.less";
import { useState, useCallback, useEffect } from "react";
import { debounce } from "lodash";
import { fieldGiuong } from "../fieldsDanhMuc";
import { useSelector } from "react-redux";
import { dataLoaiGiuong } from "../../../../constants/data";


export default function FormGiuong({
  initData,
  visible = false,
  isCreateNew = false,
  form,
}) {
    const userProfile = useSelector(state => state.auth.user)
    const [dsPhong, setDsPhong] = useState([])
    const [dsDv, setDsDv] = useState([])
    const [selectedListDv, setSelectedListDv] = useState([])
    const [dsLoaiGiuong, setDSLoaiGiuong] = useState(dataLoaiGiuong); //Chọn loại giường    

    useEffect(() => {
        if (visible) {
          layDsPhongNoiTru()
          layDsDichVu()
          
          if (!isEmptyObject(initData)) {
            layChiTietGiuong(initData.ID, initData.DV_GIUONG)
          }
        }else{
          resetForm()
        } 
      
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible])
    
  
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const timKiemPhong = useCallback(
    debounce((val) => layDsPhongNoiTru(val), 1000),
    []
  );

   // eslint-disable-next-line react-hooks/exhaustive-deps
   const timKiemDichVu = useCallback(
    debounce((val) => layDsDichVu(val), 1000),
    []
  );

  function resetForm(){
    form.resetFields()
    setSelectedListDv([ ])
  }

  async function layDsPhongNoiTru (search_string = "") {
    try {
      let dataRequest = {
        page : 1,
        limit : keys.limit,
        search_string ,
        BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
        partner_code : userProfile.partner_code
      }
      const response = await common_post(apis.lay_ds_phong_noi_tru, dataRequest)
      if (response && response.status === "OK") {
        setDsPhong(response.result)
      }
    } catch (error) {
      HLog("errorr" , error)
    }
  }

  async function layDsDichVu (search_string = "") {
    try {
      let dataRequest = {
        page : 1,
        limit : keys.limit ,
        search_string ,
        NHOM_BHYT_ID: 15,
        BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
        partner_code : userProfile.partner_code
      }
      const response = await common_post(apis.dm_dvkt_lay_ds_co_hieu_luc, dataRequest)
      if (response && response.status === "OK") {
        setDsDv(response.result.map(item => ({
          TEN_DICHVU: item.TEN_DICHVU,
          ID : item.ID
        })))
      }
    } catch (error) {
      HLog("errorr" , error)
    }
  }

  async function layChiTietGiuong (ID, DV_GIUONG) {
    try {
      let dataRequest = {
        ID,
        DV_GIUONG : DV_GIUONG || "",
        BENH_VIEN_ID : userProfile.BENH_VIEN_ID,
        partner_code : userProfile.partner_code
      }
      const response = await common_post(apis.dm_lay_chi_tiet_giuong_benh, dataRequest)
      if (response && response.status === "OK") {
        setDataForm(response)
      }
    } catch (error) {
      HLog("errorr" , error)
    }
  }

  function setDataForm (data){
    let {data_dv , data_phong} = data;
    let convertData = data_dv.map(item => JSON.stringify({TEN_DICHVU : item.TEN_DICHVU, ID : item.ID}))
    setSelectedListDv(convertData)
    form.setFields([
      {
        name : fieldGiuong.phong,
        value : initData[fieldGiuong.ten_phong]
      },
      {
        name : fieldGiuong.dich_vu,
        value : convertData
      },
    ])

  }

  //hàm xử lí chọn loại giường
  const onSelectLoaiGiuong = (data) => {
    HLog("onSelectLoaiGiuong data: ", data)
    
  }

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>
        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldGiuong.ma_giuong}
                label= {i18n.t(languageKeys.dm_giuong_ma_giuong)}
                // rules={
                //   [
                //     {
                //       required: true,
                //       message: i18n.t(languageKeys.vui_long_nhap),
                //     },
                //   ]
                // }
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldGiuong.ma_giuong_bhyt}
                label= {i18n.t(languageKeys.dm_giuong_ma_giuong_bhyt)}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldGiuong.ten_giuong}
                label= {i18n.t(languageKeys.dm_giuong_ten_giuong)}
                rules={
                  [
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_nhap),
                    },
                  ]
                }
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldGiuong.phong}
                label = "Phòng"
                rules={
                  [
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_chon),
                    },
                  ]
                }
              >
                <Select
                    dataSource={dsPhong}
                    titleKey = "TEN_KHOA_PHONG"
                    valueKey= "ID"
                    showSearch = {true}
                    onSearch = {timKiemPhong}
                    
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldGiuong.dich_vu}
                label = {i18n.t(languageKeys.field_Dich_vu_su_dung)}
              >
                <Select
                    dataSource={dsDv}
                    multiple = {true}
                    titleKey = "TEN_DICHVU"
                    //valueKey= "ID"
                    showSearch = {true}
                    onSearch = {timKiemDichVu}
                    onChange={setSelectedListDv}
                    selectedList = {selectedListDv}
                    setSelectedList={(value) => {
                      setSelectedListDv(value);
                      form.setFields([
                        { name: fieldGiuong.dich_vu, value: value },
                      ]);
                    }}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name={fieldGiuong.ma_loai_giuong}
                label = {i18n.t(languageKeys.loai_giuong)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.common_Nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={dsLoaiGiuong}
                  titleKey = "name"
                  onSelect={onSelectLoaiGiuong}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name={fieldGiuong.ghi_chu}
                label = {i18n.t(languageKeys.field_Ghi_chu)}
              >
                <Input.TextArea
                  rows={2}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </div>
      {!isEmptyObject(initData) &&  <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>
        <Form.Item
          name={fieldGiuong.hien_thi}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </div>}
    </>
  );
}
