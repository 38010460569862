import formDanhMuc from "./formDanhMuc.module.less";
import i18n, { languageKeys } from '../../../../i18n'
import { Col, Form, Input, Row, Select, Switch } from "antd";
import { fieldDanhMucNguonKhach } from "../fieldsDanhMuc";
import { useEffect, useState } from "react";
import { common_post } from "helpers";
import { apis } from "../../../../constants";

export const FormNguonKhach = ({form}) => {
    const [nhomDoiTuongNguoiDungList, setNhomDoiTuongNguoiDungList] = useState([])
    const [tenDoiTuongList, setTenDoiTuongList] = useState([])
    const valueNhomDoiTuongNguoiDung = Form.useWatch(fieldDanhMucNguonKhach.nhom_doi_tuong_nguoi_dung, form)

    useEffect(() => {
        fetchNhomDoiTuongNguoiDung()
    }, [])

    useEffect(() => {
        if(valueNhomDoiTuongNguoiDung === 3 || valueNhomDoiTuongNguoiDung === 4 || valueNhomDoiTuongNguoiDung === 5) {
            fetchDSBacSi()
        } else if(valueNhomDoiTuongNguoiDung === 6) {
            fetchPKVeTinh()
        }
    }, [valueNhomDoiTuongNguoiDung])


    const fetchNhomDoiTuongNguoiDung = async () => {
        const res = await common_post(apis.getNhomNguonKhach, {
            limit: 100,
        })
        if(!!res && res.status === 'OK') {
            setNhomDoiTuongNguoiDungList(res.result)
        }
    }

    const fetchDSBacSi = async () => {
        const res = await common_post(apis.lay_danh_sach_bac_si, {
            NHANSU_ID: null
        })
        if(!!res && res.status === 'OK') {
            setTenDoiTuongList(res.result)
        }
    }

    const fetchPKVeTinh = async () => {
        const res = await common_post(apis.get_pkvt, {})
        if(!!res && res.status === 'OK') {
            setTenDoiTuongList(res.result)
        }
    }

    const formatOptionNhomDoiTuongNguoiDung = (list = []) => {
        return list.map(item => (
            {
                label: item.TEN_DM_NHOM_NGUON_KHACH,
                value: item.ID
            }
        ))
    }

    const checkRequired = (id) => {
        let obj = {
            required: false,
            name: ''
        }
        if(id === 3 || id === 4 || id === 5) {
            obj.required = true
            obj.name = fieldDanhMucNguonKhach.nhan_su_id
        } else if(id === 6) {
            obj.required = true
            obj.name = fieldDanhMucNguonKhach.ve_tinh_id
        } else {
            obj.required = false
            obj.name = fieldDanhMucNguonKhach.ten_doi_tuong
        }
        return obj
    }

    const formatOptions = (arr = []) => {
        if(valueNhomDoiTuongNguoiDung === 3 || valueNhomDoiTuongNguoiDung === 4 || valueNhomDoiTuongNguoiDung === 5) {
            return arr.map(item => ({
                label: `${item.HO} ${item.TEN}`,
                value: JSON.stringify({label: `${item.HO} ${item.TEN}`, value: item.NHANSU_ID })
            }))
        } else if(valueNhomDoiTuongNguoiDung === 6) {
            return arr.map(item => ({
                label: item.TEN_CSKCB,
                value: JSON.stringify({label: item.TEN_CSKCB, value: item.ID}) 
            }))
        } else {
            return []
        }
    }

    const handleFillCustomerName = (val) => {
        const {label} = JSON.parse(val)
        form.setFieldValue(fieldDanhMucNguonKhach.ten_nguon_khach, label)
    }

    return (
        <>
            <div className={formDanhMuc["wrapper"]}>
                <h4>{i18n.t(languageKeys.field_Thong_tin_co_ban)}</h4>
        
                <div className={formDanhMuc["inner"]}>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            name={fieldDanhMucNguonKhach.ma_nguon_khach}
                            label={i18n.t(languageKeys.field_ma_nguon_khach)}
                            rules={[
                            {
                                required: true,
                                whitespace: true,
                                message: i18n.t(languageKeys.vui_long_nhap),
                            },
                            {
                                max: 30,
                                message: i18n.t(languageKeys.toi_da_ky_tu),
                            },
                            ]}
                        >
                            <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            name={fieldDanhMucNguonKhach.nhom_doi_tuong_nguoi_dung}
                            label={i18n.t(languageKeys.nhom_doi_tuong_nguoi_dung)}
                            rules={[
                            {
                                required: true,
                                message: i18n.t(languageKeys.please_chon),
                            },
                            ]}
                        >
                            <Select placeholder={i18n.t(languageKeys.common_Chon)} options={formatOptionNhomDoiTuongNguoiDung(nhomDoiTuongNguoiDungList)} onChange={() => {
                                form.setFields([{name: checkRequired(valueNhomDoiTuongNguoiDung).name, value: undefined}, {name: fieldDanhMucNguonKhach.ten_nguon_khach, value: undefined}])
                            }} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={10}>
                    <Col span={12}>
                        <Form.Item
                            rules={[{ required: checkRequired(valueNhomDoiTuongNguoiDung).required, message: i18n.t(languageKeys.please_chon) }]}
                            name={checkRequired(valueNhomDoiTuongNguoiDung).name}
                            label={i18n.t(languageKeys.ten_doi_tuong)}
                        >
                        <Select placeholder={i18n.t(languageKeys.common_Chon)} options={formatOptions(tenDoiTuongList)} onSelect={handleFillCustomerName} />
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item
                            rules={[{ required: true, whitespace: true, message: i18n.t(languageKeys.vui_long_nhap) }]}
                            name={fieldDanhMucNguonKhach.ten_nguon_khach}
                            label={i18n.t(languageKeys.field_ten_nguon_khach)}
                        >
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
                        </Form.Item>
                    </Col>
                </Row>
                <Col span={24}>
                    <Form.Item name={fieldDanhMucNguonKhach.ghi_chu} label={i18n.t(languageKeys.field_Ghi_chu)}>
                    <Input.TextArea rows={5} placeholder={i18n.t(languageKeys.common_Nhap)} />
                    </Form.Item>
                </Col>
                <Row gutter={10}>
                    <Col>
                    <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>
                    </Col>
                    <Col>
                    <Form.Item name={fieldDanhMucNguonKhach.hien_thi} valuePropName="checked" initialValue={false}>
                        <Switch />
                    </Form.Item>
                    </Col>
                </Row>
                </div>
            </div>
        </>
    )
}