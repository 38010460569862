import { Result, Button } from "antd";
import i18n, { languageKeys } from "../../i18n";
const InternalError = () => {
  return (
    <Result
    status="500"
    title="500"
    subTitle={i18n.t(languageKeys.noti_loi_tai_trang)}
    extra={<Button type="primary" onClick={() => window.location.reload(true)}>Tải lại</Button>}
  />
  )
}

export default InternalError