import { Layout } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
// import { resetTitle, updateTitle } from "../../ducks/slices/titlePageSlice";
import CongCuHoTro from "./CongCuHoTro/CongCuHoTro";

import SideBar from "./layout/SideBar";
import style from "./style.module.less";
import HoiDap from "./HoiDap/HoiDap";
import HDSDBangDieuKhien from "./HDSD/HDSDBangDieuKhien";
import { MauDanhMuc } from "./MauDanhMuc/MauDanhMuc";
import { defineKeys, HDSD } from "./keys";

const SEARCH_KEY_PAGE = "page";

const Support = (props) => {
  
  const [realKey, setRealKey] = useState("");
  let query = useQuery();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(updateTitle("Hỗ trợ khách hàng"));
  //   return () => {
  //     dispatch(resetTitle());
  //   };
  //   }, []);

  const pageKey = query.get(SEARCH_KEY_PAGE) || defineKeys.HDSDBangDieuKhien;

  useEffect(() => {
    setRealKey(pageKey);
    if (
      pageKey !== defineKeys.HDSDBangDieuKhien &&
      pageKey !== defineKeys.HDSDBaocao &&
      pageKey !== defineKeys.HDSDTiepDon &&
      pageKey !== defineKeys.HDSDVienPhi &&
      pageKey !== defineKeys.HDSDKhamBenh &&
      pageKey !== defineKeys.HDSDDuoc &&
      pageKey !== defineKeys.HDSDRisNhapTay &&
      pageKey !== defineKeys.HDSDHeThong &&
      pageKey !== defineKeys.hoiDap &&
      pageKey !== defineKeys.congCuHoTro &&
      pageKey !== defineKeys.mauDanhMuc
    ) {
      setRealKey(defineKeys.HDSDBangDieuKhien);
    }
  }, [pageKey]);

  const menuChange = (page) => {
    // console.log("đâsdasdasdasdasd", page);

    switch (page) {
      case defineKeys.HDSDBangDieuKhien:
        return <HDSDBangDieuKhien data={HDSD[0]} />;
      case defineKeys.HDSDBaocao:
        return <HDSDBangDieuKhien data={HDSD[1]} />;
      case defineKeys.HDSDTiepDon:
        return <HDSDBangDieuKhien data={HDSD[2]} />;
      case defineKeys.HDSDVienPhi:
        return <HDSDBangDieuKhien data={HDSD[3]} />;
      case defineKeys.HDSDKhamBenh:
        return <HDSDBangDieuKhien data={HDSD[4]} />;
      case defineKeys.HDSDDuoc:
        return <HDSDBangDieuKhien data={HDSD[5]} />;
      case defineKeys.HDSDRisNhapTay:
        return <HDSDBangDieuKhien data={HDSD[6]} />;
      case defineKeys.HDSDHeThong:
        return <HDSDBangDieuKhien data={HDSD[7]} />;
      case defineKeys.hoiDap:
        return <HoiDap />;
      case defineKeys.congCuHoTro:
        return <CongCuHoTro />;
      case defineKeys.mauDanhMuc:
        return <MauDanhMuc />;
      default:
        return <></>;
    }
  };

  return (
    <Layout>
      <Layout.Sider width={254}>
        <SideBar />
      </Layout.Sider>

      <Layout.Content className={style["wrap"]}>{menuChange(realKey)}</Layout.Content>
    </Layout>
  );
};

export default Support;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
