import { apis, keys } from "../../../../constants";
import { common_post, formatPriceToNumber, HLog, isEmptyObject, isJsonString } from "../../../../helpers";
import {
  fieldDonViTinh,
  fieldDuongDung,
  fieldHangSanXuat,
  fieldHoatChat,
  fieldKhoTuTruc,
  fieldLoaiDvKiThuat,
  fieldNhomDvKiThuat,
  fieldNhomHoaDon,
  fieldQuocGia,
  fieldThuoc,
} from "../fieldsDanhMuc";

/**
 * API Đường dùng
 */
export const apiLayDsDuongDung = async ({ BENH_VIEN_ID, search_string, page, partner_code, limit = keys.limit }) => {
  let dataRequest = { BENH_VIEN_ID, search_string, page, partner_code, limit };
  try {
    let response = await common_post(apis.dm_lay_ds_duong_dung, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiThemDuongDung = async (data) => {
  let dataRequest = {
    ...data,
    data: [data.data],
  };

  try {
    return await common_post(apis.dm_them_duong_dung, dataRequest);
  } catch (error) {
    return null;
  }
};

export const apiSuaDuongDung = async (dataRequest, TRANG_THAI = 1) => {
  try {
    return await common_post(apis.dm_sua_duong_dung, {
      ...dataRequest.data,
      TRANG_THAI,
    });
  } catch (error) {
    return null;
  }
};

/**
 * API Hãng sản xuất
 */

export const apiLayDsHangSx = async ({ BENH_VIEN_ID, search_string, page, partner_code, limit = keys.limit }) => {
  let dataRequest = { BENH_VIEN_ID, search_string, page, partner_code, limit };
  try {
    let response = await common_post(apis.dm_lay_ds_hang_sx, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiLayDsHangSxCoHieuLuc = async ({ BENH_VIEN_ID, search_string, page, partner_code, limit = keys.limit }) => {
  let dataRequest = { BENH_VIEN_ID, search_string, page, partner_code, limit };
  try {
    let response = await common_post(apis.dm_lay_ds_hang_sx_co_hieu_luc, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiThemHangSx = async (data, TRANG_THAI = 1) => {
  let dataRequest = {
    ...data,
    data: [data.data],
    TRANG_THAI,
  };

  try {
    return await common_post(apis.dm_them_hang_sx, dataRequest);
  } catch (error) {
    return null;
  }
};

export const apiSuaHangSx = async ({ data }) => {
  try {
    return await common_post(apis.dm_sua_hang_sx, data);
  } catch (error) {
    HLog("api sua hang sx", error);
    return null;
  }
};

/**
 * API Hoạt chất
 */

export const apiLayDsHoatChat = async ({ BENH_VIEN_ID, search_string, page, partner_code, limit = keys.limit }) => {
  let dataRequest = { BENH_VIEN_ID, search_string, page, partner_code, limit };
  try {
    let response = await common_post(apis.dm_lay_ds_hoat_chat, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiLayDsHoatChatCoHieuLuc = async ({ BENH_VIEN_ID, search_string, page, partner_code, limit = keys.limit }) => {
  let dataRequest = { BENH_VIEN_ID, search_string, page, partner_code, limit };
  try {
    let response = await common_post(apis.dm_lay_ds_hoat_chat_co_hieu_luc, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiThemHoatChat = async (data) => {
  let item = data.data;

  let duong_dung = {};

  if (!!item.ID_DUONG_DUNG_BYT && isJsonString(item.ID_DUONG_DUNG_BYT)) {
    duong_dung = {
      ID_DUONG_DUNG_BYT: JSON.parse(item.ID_DUONG_DUNG_BYT)[fieldDuongDung.id],
      TEN_DUONG_DUNG_BYT: JSON.parse(item.ID_DUONG_DUNG_BYT)[fieldDuongDung.ten_duong_dung],
    };
  }

  let dataRequest = {
    ...data,
    data: [
      {
        ...item,
        ...duong_dung,
      },
    ],
  };

  try {
    return await common_post(apis.dm_them_hoat_chat, dataRequest);
  } catch (error) {
    return null;
  }
};

export const apiSuaHoatChat = async ({ data = {}, currentData = {} }) => {
  let dataRequest = {
    ...data,
  };

  const key_duong_dung = fieldHoatChat.duong_dung;
  const key_ten_duong_dung = fieldHoatChat.ten_duong_dung;

  // nếu là json string (chọn mới đường dùng)
  // eslint-disable-next-line no-useless-escape
  if (!!dataRequest[key_duong_dung] && typeof dataRequest[key_duong_dung] === "string" && dataRequest[key_duong_dung].includes(`{\"`)) {
    const parseVal = JSON.parse(dataRequest[key_duong_dung]);
    dataRequest[key_duong_dung] = parseVal[fieldDuongDung.id].toString();
    dataRequest[key_ten_duong_dung] = parseVal[fieldDuongDung.ten_duong_dung];
  }
  // nếu là string tên đường dùng (không chọn mới đường dùng)
  else {
    dataRequest[key_duong_dung] = currentData[key_duong_dung];
  }

  try {
    return await common_post(apis.dm_sua_hoat_chat, dataRequest);
  } catch (error) {
    return null;
  }
};

/**
 * API Thuốc
 */

export const apiLayDsThuoc = async ({ BENH_VIEN_ID, search_string, page, partner_code, limit = keys.limit }) => {
  let dataRequest = { BENH_VIEN_ID, search_string, page, partner_code, limit };
  try {
    let response = await common_post(apis.dm_lay_ds_thuoc, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiThemThuoc = async ({ BENH_VIEN_ID = "", partner_code = "", data = {}, currentData = {} }) => {
  HLog("apisDmThuoc apiThemThuoc BENH_VIEN_ID: " + BENH_VIEN_ID + " partner_code: " + partner_code + " data: " + JSON.stringify(data));
  // đơn vị tính
  data[fieldThuoc.ten_dvt] = JSON.parse(data[fieldThuoc.ma_dvt])[fieldDonViTinh.ten_dvt];
  data[fieldThuoc.ma_dvt] = JSON.parse(data[fieldThuoc.ma_dvt])[fieldDonViTinh.id];

  //nhóm chi phí BHYT
  const ID_NHOM_CHIPHI_BHYT = "5";
  data[fieldThuoc.ma_nhom_chi_phi_bhyt] = ID_NHOM_CHIPHI_BHYT;

  // hoạt chất
  if (!!data[fieldThuoc.ma_hoat_chat] && isJsonString(data[fieldThuoc.ma_hoat_chat])) {
    data[fieldThuoc.ten_hoat_chat] = JSON.parse(data[fieldThuoc.ma_hoat_chat])[fieldHoatChat.ten_hoat_chat];
    data[fieldThuoc.ma_hoat_chat] = JSON.parse(data[fieldThuoc.ma_hoat_chat])[fieldHoatChat.id];
  }

  // nước sản xuất
  if (!!data[fieldThuoc.ma_nsx] && isJsonString(data[fieldThuoc.ma_nsx])) {
    data[fieldThuoc.ten_nsx] = JSON.parse(data[fieldThuoc.ma_nsx])[fieldQuocGia.ten_quoc_gia];
    data[fieldThuoc.ma_nsx] = JSON.parse(data[fieldThuoc.ma_nsx])[fieldQuocGia.id];
  }

  // hãng sản xuất
  if (!!data[fieldThuoc.ma_hsx] && isJsonString(data[fieldThuoc.ma_hsx])) {
    data[fieldThuoc.ten_hsx] = JSON.parse(data[fieldThuoc.ma_hsx])[fieldHangSanXuat.ten_hang_sx];
    data[fieldThuoc.ma_hsx] = JSON.parse(data[fieldThuoc.ma_hsx])[fieldHangSanXuat.id];
  }

  // nhóm dvkt
  if (data[fieldThuoc.ma_nhom_dv] && data[fieldThuoc.ma_nhom_dv] !== "") {
    data[fieldThuoc.ten_nhom_dv] = JSON.parse(data[fieldThuoc.ma_nhom_dv])[fieldNhomDvKiThuat.ten_nhom];
    data[fieldThuoc.ma_nhom_dv] = JSON.parse(data[fieldThuoc.ma_nhom_dv])[fieldNhomDvKiThuat.id];
  }

  // loại dvkt
  if (data[fieldThuoc.ma_loai_dv] && data[fieldThuoc.ma_loai_dv] !== "") {
    data[fieldThuoc.ten_loai_dv] = JSON.parse(data[fieldThuoc.ma_loai_dv])[fieldLoaiDvKiThuat.ten_loai_dv_ki_thuat];
    data[fieldThuoc.ma_loai_dv] = JSON.parse(data[fieldThuoc.ma_loai_dv])[fieldLoaiDvKiThuat.id];
  }

  //nhóm Thuốc
  if (data[fieldThuoc.ma_nhom_thuoc] && data[fieldThuoc.ma_nhom_thuoc] !== "") {
    if (isJsonString(data[fieldThuoc.ma_nhom_thuoc])) {
      let dataNhomThuoc = JSON.parse(data[fieldThuoc.ma_nhom_thuoc]);
      HLog("apisDmThuoc dataNhomThuoc: ", dataNhomThuoc);
      if (dataNhomThuoc && !isEmptyObject(dataNhomThuoc)) {
        data[fieldThuoc.ma_nhom_thuoc] = dataNhomThuoc["ID"];
      }
    }
  }

  // nhóm hoá đơn
  if (!!data[fieldThuoc.ma_nhom_hoa_don] && isJsonString(data[fieldThuoc.ma_nhom_hoa_don])) {
    data[fieldThuoc.ten_nhom_hoa_don] = JSON.parse(data[fieldThuoc.ma_nhom_hoa_don])[fieldNhomHoaDon.ten_nhom];
    data[fieldThuoc.ma_nhom_hoa_don] = JSON.parse(data[fieldThuoc.ma_nhom_hoa_don])[fieldNhomHoaDon.id];
  }

  // giá nhập
  if (!!data[fieldThuoc.gia_nhap]) {
    data[fieldThuoc.gia_nhap] = formatPriceToNumber(data[fieldThuoc.gia_nhap]);
  } else {
    data[fieldThuoc.gia_nhap] = undefined;
  }

  // giá bán
  if (!!data[fieldThuoc.gia_ban]) {
    data[fieldThuoc.gia_ban] = formatPriceToNumber(data[fieldThuoc.gia_ban]);
  } else {
    data[fieldThuoc.gia_ban] = undefined;
  }

  const req = {
    BENH_VIEN_ID,
    partner_code,
    data: [data],
  };
  try {
    return await common_post(apis.dm_them_thuoc, req);
  } catch (error) {
    HLog("apisDmThuoc apiThemThuoc eror: ", error);
  }
};

export const apiSuaThuoc = async ({ data = {}, currentData = {} }) => {
  try {
    // đơn vị tính
    if (typeof data[fieldThuoc.ma_dvt] === "string" && data[fieldThuoc.ma_dvt].includes(`{\"`)) {
      data[fieldThuoc.ten_dvt] = JSON.parse(data[fieldThuoc.ma_dvt])[fieldDonViTinh.ten_dvt];
      data[fieldThuoc.ma_dvt] = JSON.parse(data[fieldThuoc.ma_dvt])[fieldDonViTinh.id];
    } else {
      data[fieldThuoc.ma_dvt] = currentData[fieldThuoc.ma_dvt];
    }

    //nhóm chi phí BHYT
    let ID_NHOM_CHIPHI_BHYT = data.ID_NHOM_CHIPHI_BHYT;
    if (currentData.TEN_NHOM_BHYT === ID_NHOM_CHIPHI_BHYT) {
      //vì khi vào màn hình sửa mà không chọn lại nhóm chi phí, thì nó đang tự động hiển thị id = tên, nên phải sét lại ID_NHOM_CHIPHI_BHYT
      ID_NHOM_CHIPHI_BHYT = currentData.ID_NHOM_CHIPHI_BHYT;
      data[fieldThuoc.ma_nhom_chi_phi_bhyt] = ID_NHOM_CHIPHI_BHYT;
    }

    // hoạt chất
    if (typeof data[fieldThuoc.ma_hoat_chat] === "string" && data[fieldThuoc.ma_hoat_chat].includes(`{\"`)) {
      data[fieldThuoc.ten_hoat_chat] = JSON.parse(data[fieldThuoc.ma_hoat_chat])[fieldHoatChat.ten_hoat_chat];
      data[fieldThuoc.ma_hoat_chat] = JSON.parse(data[fieldThuoc.ma_hoat_chat])[fieldHoatChat.id];
    } else {
      data[fieldThuoc.ma_hoat_chat] = currentData[fieldThuoc.ma_hoat_chat];
    }

    // nước sản xuất
    if (!!data[fieldThuoc.ma_nsx] && typeof data[fieldThuoc.ma_nsx] === "string" && data[fieldThuoc.ma_nsx].includes(`{\"`)) {
      data[fieldThuoc.ten_nsx] = JSON.parse(data[fieldThuoc.ma_nsx])[fieldQuocGia.ten_quoc_gia];
      data[fieldThuoc.ma_nsx] = JSON.parse(data[fieldThuoc.ma_nsx])[fieldQuocGia.id];
    } else {
      data[fieldThuoc.ma_nsx] = currentData[fieldThuoc.ma_nsx];
    }

    // hãng sản xuất
    if (!!data[fieldThuoc.ma_hsx] && typeof data[fieldThuoc.ma_hsx] === "string" && data[fieldThuoc.ma_hsx].includes(`{\"`)) {
      data[fieldThuoc.ten_hsx] = JSON.parse(data[fieldThuoc.ma_hsx])[fieldHangSanXuat.ten_hang_sx];
      data[fieldThuoc.ma_hsx] = JSON.parse(data[fieldThuoc.ma_hsx])[fieldHangSanXuat.id];
    } else {
      data[fieldThuoc.ma_hsx] = currentData[fieldThuoc.ma_hsx];
    }

    // nhóm dvkt
    if (!!data[fieldThuoc.ma_nhom_dv] && typeof data[fieldThuoc.ma_nhom_dv] === "string" && data[fieldThuoc.ma_nhom_dv].includes(`{\"`)) {
      data[fieldThuoc.ten_nhom_dv] = JSON.parse(data[fieldThuoc.ma_nhom_dv])[fieldNhomDvKiThuat.ten_nhom];
      data[fieldThuoc.ma_nhom_dv] = JSON.parse(data[fieldThuoc.ma_nhom_dv])[fieldNhomDvKiThuat.id];
    } else {
      data[fieldThuoc.ma_nhom_dv] = currentData[fieldThuoc.ma_nhom_dv];
    }

    // loại dvkt
    if (!!data[fieldThuoc.ma_loai_dv] && typeof data[fieldThuoc.ma_loai_dv] === "string" && data[fieldThuoc.ma_loai_dv].includes(`{\"`)) {
      data[fieldThuoc.ten_loai_dv] = JSON.parse(data[fieldThuoc.ma_loai_dv])[fieldLoaiDvKiThuat.ten_loai_dv_ki_thuat];
      data[fieldThuoc.ma_loai_dv] = JSON.parse(data[fieldThuoc.ma_loai_dv])[fieldLoaiDvKiThuat.id];
    } else {
      data[fieldThuoc.ma_loai_dv] = currentData[fieldThuoc.ma_loai_dv];
    }

    // nhóm hoá đơn
    if (
      !!data[fieldThuoc.ma_nhom_hoa_don] &&
      typeof data[fieldThuoc.ma_nhom_hoa_don] === "string" &&
      data[fieldThuoc.ma_nhom_hoa_don].includes(`{\"`)
    ) {
      data[fieldThuoc.ten_nhom_hoa_don] = JSON.parse(data[fieldThuoc.ma_nhom_hoa_don])[fieldNhomHoaDon.ten_nhom];
      data[fieldThuoc.ma_nhom_hoa_don] = JSON.parse(data[fieldThuoc.ma_nhom_hoa_don])[fieldNhomHoaDon.id];
    } else {
      data[fieldThuoc.ma_nhom_hoa_don] = currentData[fieldThuoc.ma_nhom_hoa_don];
    }

    // giá nhập
    if (!!data[fieldThuoc.gia_nhap]) {
      data[fieldThuoc.gia_nhap] = formatPriceToNumber(data[fieldThuoc.gia_nhap]);
    } else {
      data[fieldThuoc.gia_nhap] = undefined;
    }

    // giá bán
    if (!!data[fieldThuoc.gia_ban]) {
      data[fieldThuoc.gia_ban] = formatPriceToNumber(data[fieldThuoc.gia_ban]);
    } else {
      data[fieldThuoc.gia_ban] = undefined;
    }

    //nhóm Thuốc
    if (data[fieldThuoc.ma_nhom_thuoc] && data[fieldThuoc.ma_nhom_thuoc] !== "") {
      if (isJsonString(data[fieldThuoc.ma_nhom_thuoc])) {
        let dataNhomThuoc = JSON.parse(data[fieldThuoc.ma_nhom_thuoc]);
        HLog("apisDmThuoc dataNhomThuoc: ", dataNhomThuoc);
        if (dataNhomThuoc && !isEmptyObject(dataNhomThuoc)) {
          data[fieldThuoc.ma_nhom_thuoc] = dataNhomThuoc["ID"];
        }
      } else {
        data[fieldThuoc.ma_nhom_thuoc] = currentData[fieldThuoc.ma_nhom_thuoc];
      }
    }

    // số đăng ký thuốc
    if (data[fieldThuoc.so_dang_ky_thuoc] === "") {
      data[fieldThuoc.so_dang_ky_thuoc] = null;
    }

    const req = { ...data };
    HLog("apisDmThuoc apiSuaThuoc req: ", req, " currentData: ", currentData);
    return await common_post(apis.dm_sua_thuoc, req);
  } catch (error) {
    HLog("[ERROR] api sua dm thuoc", error);
    return null;
  }
};

export const apiXoaThuoc = async ({ data = {}, partner_code = "" }) => {
  try {
    const listId = [data[fieldThuoc.id]];
    const req = { partner_code, listId };
    return await common_post(apis.dm_xoa_ds_thuoc, req);
  } catch (error) {
    HLog("Api xoa thuoc error", error);
  }
};

export const apiXoaHangSx = async (item) => {
  let dataRequest = {
    ID: item.data.ID,
    TRANG_THAI: 0,
    partner_code: item.partner_code,
  };
  try {
    return await common_post(apis.dm_sua_hang_sx, dataRequest);
  } catch (error) {
    HLog("api xoa hang SX", error);
  }
};

export const apiXoaHoatChat = async (item) => {
  let dataRequest = {
    ID: item.data.ID,
    TRANG_THAI: 0,
    partner_code: item.partner_code,
  };
  try {
    return await common_post(apis.dm_sua_hoat_chat, dataRequest);
  } catch (error) {
    HLog("api xoa hoat chat", error);
  }
};

export const apiXoaDuongDung = async (item) => {
  let dataRequest = {
    ID: item.data.ID,
    TRANG_THAI: 0,
    partner_code: item.partner_code,
  };
  try {
    return await common_post(apis.dm_sua_duong_dung, dataRequest);
  } catch (error) {
    return null;
  }
};

/// danh mục kho tủ trực
export const apiLayDsKhoTuTruc = async ({ BENH_VIEN_ID, search_string, page, partner_code, ID = "", limit = keys.limit }) => {
  let dataRequest = {
    BENH_VIEN_ID,
    search_string,
    page,
    partner_code,
    ID,
    limit,
  };
  try {
    let response = await common_post(apis.dm_lay_ds_kho_tutruc, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiThemKhoTuTruc = async (data) => {
  let dataSumit = data.data;
  let dataRequest = {
    ...data,
    data: [dataSumit],
  };

  try {
    return await common_post(apis.dm_them_kho_tutruc, dataRequest);
  } catch (error) {
    return null;
  }
};

export const apiSuaKhoTuTruc = async ({ data }, currentData) => {
  try {
    return await common_post(apis.dm_sua_kho_tutruc, data);
  } catch (error) {
    return null;
  }
};

export const apiXoaKhoTuTruc = async (item) => {
  let dataRequest = {
    partner_code: item.partner_code,
    ID: item.data.ID,
    TRANG_THAI: 0,
  };
  try {
    let response = await common_post(apis.dm_sua_kho_tutruc, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const apiNhapDsKhoTuTruc = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    const req = { data, partner_code, BENH_VIEN_ID };
    let response = await common_post(apis.dm_them_kho_tutruc, req);
    return response;
  } catch (error) {
    HLog("Api nhap danh sach kho tu truc error", error);
  }
};

export const apiXoaDsKhoTuTruc = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldKhoTuTruc.id]);
    const req = { partner_code, listId };
    const response = await common_post(apis.dm_xoa_ds_kho_tutruc, req);
    return response;
  } catch (error) {
    HLog("Api xoa danh sach kho tu truc error", error);
  }
};

export const apiKhoaDsKhoTuTruc = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldKhoTuTruc.id]);
    const req = { partner_code, listId, isKhoa: lock ? 1 : 0 };
    const response = await common_post(apis.dm_khoa_ds_kho_tutruc, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach kho tu truc error", error);
  }
};

export const apiNhapDsHoatChat = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        if (key === "ID_DUONG_DUNG_BYT" && !item[key]) {
          item[key] = null;
        } else {
          item[key] = item[key].toString();
        }
      });
      return item;
    });
    const req = { data, partner_code, BENH_VIEN_ID };
    let response = await common_post(apis.dm_them_hoat_chat, req);
    return response;
  } catch (error) {
    HLog("Api nhap danh sach hoat chat error", error);
  }
};

export const apiXoaDsHoatChat = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldKhoTuTruc.id]);
    const req = { partner_code, listId };
    const response = await common_post(apis.dm_xoa_ds_hoat_chat, req);
    return response;
  } catch (error) {
    HLog("Api xoa danh sach hoat chat error", error);
  }
};

export const apiKhoaDsHoatChat = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldKhoTuTruc.id]);
    const req = { partner_code, listId, isKhoa: lock ? 1 : 0 };
    const response = await common_post(apis.dm_khoa_ds_hoat_chat, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach hoat chat error", error);
  }
};

export const apiNhapDsDuongDung = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        item[key] = item[key].toString();
      });
      return item;
    });
    const req = { data, partner_code, BENH_VIEN_ID };
    let response = await common_post(apis.dm_them_duong_dung, req);
    return response;
  } catch (error) {
    HLog("Api nhap danh sach duong dung error", error);
  }
};

export const apiXoaDsDuongDung = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldKhoTuTruc.id]);
    const req = { partner_code, listId };
    const response = await common_post(apis.dm_xoa_ds_duong_dung, req);
    return response;
  } catch (error) {
    HLog("Api xoa danh sach duong dung error", error);
  }
};

export const apiKhoaDsDuongDung = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldKhoTuTruc.id]);
    const req = { partner_code, listId, isKhoa: lock ? 1 : 0 };
    const response = await common_post(apis.dm_khoa_ds_duong_dung, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach duong dung error", error);
  }
};

export const apiNhapDsHangSx = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        item[key] = item[key].toString();
      });
      return item;
    });
    const req = { data, partner_code, BENH_VIEN_ID };
    let response = await common_post(apis.dm_them_hang_sx, req);
    return response;
  } catch (error) {
    HLog("Api nhap danh sach hang sx error", error);
  }
};

export const apiXoaDsHangSx = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldKhoTuTruc.id]);
    const req = { partner_code, listId };
    const response = await common_post(apis.dm_xoa_ds_hang_sx, req);
    return response;
  } catch (error) {
    HLog("Api xoa danh sach hang sx error", error);
  }
};

export const apiKhoaDsHangSx = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldKhoTuTruc.id]);
    const req = { partner_code, listId, isKhoa: lock ? 1 : 0 };
    const response = await common_post(apis.dm_khoa_ds_hang_sx, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach hang sx error", error);
  }
};

export const apiNhapDsThuoc = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        if (
          (key === "IS_THUOC_KHANG_SINH" && !item[key]) ||
          (key === "IS_THUOC_TAN_DUOC" && !item[key]) ||
          (key === "IS_THUOC_YHCT" && !item[key]) ||
          (key === "IS_CHEPHAM_YHCT" && !item[key]) ||
          (key === "IS_THUOC_KE_DON" && !item[key]) ||
          (key === "IS_THUC_PHAM_CHUC_NANG" && !item[key]) ||
          (key === "GIA_NHAP" && !item[key]) ||
          (key === "GIA_BAN" && !item[key]) ||
          (key === "IS_CHO_PHEP_MIEN_GIAM" && !item[key]) ||
          (key === "IS_TAM_DUNG_NHAP_NCC" && !item[key])
        ) {
          if (item[key] === 0) {
            item[key] = 0;
          } else item[key] = null;
        } else item[key] = item[key].toString();
      });
      return item;
    });
    console.log({ data, partner_code, BENH_VIEN_ID });
    const req = { data, partner_code, BENH_VIEN_ID };
    let response = await common_post(apis.dm_them_thuoc_v2, req);
    return response;
  } catch (error) {
    HLog("Api nhap danh sach thuoc error", error);
  }
};

export const apiXoaDsThuoc = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldThuoc.id]);
    const req = { partner_code, listId };
    const response = await common_post(apis.dm_xoa_ds_thuoc, req);
    return response;
  } catch (error) {
    HLog("Api xoa danh sach thuoc error", error);
  }
};

export const apiKhoaDsThuoc = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldThuoc.id]);
    const req = { partner_code, listId, isKhoa: lock ? 1 : 0 };
    const response = await common_post(apis.dm_khoa_ds_thuoc, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach thuoc error", error);
  }
};

export const apiExportThuoc = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_thuoc_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template thuoc error" : "Export ds thuoc error");
  }
};

export const apiExportHangSx = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_hang_sx_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template hanh sx error" : "Export ds hanh sx error");
  }
};

export const apiExportDuongDung = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_duong_dung_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template duong dung error" : "Export ds duong dung error");
  }
};

export const apiExportKhoTuTruc = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_kho_tu_truc_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template kho tu truc error" : "Export ds kho tu truc error");
  }
};

export const apiExportHoatChat = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_hoat_chat_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template hoat chat error" : "Export ds hoat chat error");
  }
};

export const genMaThuoc = async (partner_code, BENH_VIEN_ID) => {
  try {
    const res = await common_post(apis.gen_Ma_thuoc, {
      partner_code,
      BENH_VIEN_ID,
    });
    if (res.status === "OK") return res;
  } catch (error) {
    HLog("Tạo mã thuốc lỗi ", error);
  }
};
