import { Layout, Table as AntTable, Button, Tooltip } from "antd";
import { ConfirmModal, ModalPDF, Table } from "components";
import i18n, { languageKeys } from "i18n";
import FormHoanTien from "./Form/FormHoanTien";
import { useRef } from "react";
import { QlyTamUngData, cancelPhTamUng, getDsPhTamUng } from "ducks/slices/QlyVienPhi/QlyTamUng.slice";
import { useDispatch, useSelector } from "react-redux";
import { tableLoadingState } from "ducks/slices/loadingSlice";
import { formatCurrency } from "helpers";
import moment from "moment";
import { CloseSquare, MoneyWithdrawal, Reload } from "assets/svg";
import style from "./qltu.module.less";
import { useEffect } from "react";
import { LOAI_PHIEU_TamUng } from "constants/data";
import { PrinterOutlined } from "@ant-design/icons";
import FormDsChuaTT from "./Form/FormThanhToan/FormDsChuaTT";
import { apis } from "../../../constants";

const TableTamUng = ({ formTURef, checkPermissions }) => {

    const formHTRef = useRef()
    const formTTRef = useRef()
    const deleteRef = useRef()
    const pdfRef = useRef();

    const dispatch = useDispatch()
    const { result, total, total_money, limit, current_page } = useSelector(QlyTamUngData)
    const userProfile = useSelector((state) => state.auth.user);
    const loading = useSelector(tableLoadingState);

    useEffect(() => {
        deleteRef.current?.close();
    }, [result]);

    useEffect(() => {
        !loading && deleteRef.current?.loading(false);
    }, [loading]);

    const checkDisablePrint = (record) => {
        if (record.LOAI_PHIEU === "TAM_UNG") return checkPermissions.THEM_MOI
        if (record.LOAI_PHIEU === "HOAN_UNG") return checkPermissions.HOAN_TIEN
        if (record.LOAI_PHIEU === "THANH_TOAN") return checkPermissions.THANH_TOAN
    }

    const renderAction = (_, record) => (
        <div onClick={(e) => e.stopPropagation()} className={style['action']}>

            <Tooltip title={checkDisablePrint(record) ? i18n.t(languageKeys.field_In_phieu) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                <Button
                    icon={<PrinterOutlined className={style['print']} />}
                    type="link"
                    disabled={record.LOAI_PHIEU === "HUY" || !checkDisablePrint(record)}
                    onClick={() => {
                        record.LOAI_PHIEU === "TAM_UNG" && pdfRef.current?.openModalWithData({ ...record, template: "phieuTamUng" }, "phieuTamUng")
                        record.LOAI_PHIEU === "HOAN_UNG" && pdfRef.current?.openModalWithData({ ...record, template: "phieuHoanUng" }, "phieuHoanUng")
                        record.LOAI_PHIEU === "THANH_TOAN" && printReceipt(record)
                    }}
                />
            </Tooltip>

            <Tooltip title={checkPermissions.HOAN_TIEN ? i18n.t(languageKeys.vien_phi_Hoan_ung) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                <Button
                    icon={<Reload className={style['reload']} />}
                    type="link"
                    disabled={record.LOAI_PHIEU !== "TAM_UNG" || record.SO_TIEN_THUC === 0 || record.PHAN_LOAI === 'TAT_TOAN_GOI' || !checkPermissions.HOAN_TIEN}
                    onClick={() => formHTRef.current?.open(record, true)}
                />
            </Tooltip>

            <Tooltip title={checkPermissions.THANH_TOAN ? i18n.t(languageKeys.txt_thanh_toan) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                <Button
                    icon={<MoneyWithdrawal className={style['money']} />}
                    type="link"
                    disabled={record.LOAI_PHIEU !== "TAM_UNG" || record.SO_TIEN_THUC === 0 || !checkPermissions.THANH_TOAN}
                    onClick={() => formTTRef.current?.open(record)}
                />
            </Tooltip>

            <Tooltip title={checkPermissions.HUY ? i18n.t(languageKeys.huy_phieu) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                <Button
                    icon={<CloseSquare className={style['delete']} />}
                    type="link"
                    disabled={record.TRANG_THAI_HIEN_TAI !== "TAM_UNG" || !checkPermissions.HUY}
                    onClick={() => deleteRef.current?.open(record)}
                />
            </Tooltip>
        </div>
    )

    const handleDelete = (data) => {
        deleteRef.current?.loading(true);
        dispatch(cancelPhTamUng({
            ID: data.ID,
            NHANSU_ID: userProfile.NHANSU_ID
        }));
    };

    const handleClickRow = (data) => {
        switch (data.LOAI_PHIEU) {
            case 'TAM_UNG':
                formTURef.current?.fillForm(data)
                break;
            case 'HUY':
                formTURef.current?.fillForm(data)
                break;
            case 'HOAN_UNG':
                formHTRef.current?.open(data, false)
                break;
            default:
                break;
        }
    }

    const printReceipt = ({ HOA_DON_ID, LICH_KHAM_ID, BENH_NHAN_ID }) => {
        const dataRequest = {
            HOA_DON_ID,
            partner_code: userProfile.partner_code,
            arr_HOA_DON_ID: LICH_KHAM_ID ? [HOA_DON_ID] : HOA_DON_ID,
            LICH_KHAM_ID,
            BENH_NHAN_ID
        };
        const api = LICH_KHAM_ID ? apis.in_cac_phieu_dich_vu_da_thanh_toan : apis.in_phieu_dtt_mua_goi_dv;
        pdfRef.current.openModal(dataRequest, api);
    };

    return (
        <Layout.Content style={{ padding: 20, height: "calc(100vh - 120px)" }}>
            <Table
                columns={[
                    ...columns,
                    {
                        title: i18n.t(languageKeys.field_Thao_tac),
                        dataIndex: "",
                        key: "",
                        fixed: "right",
                        width: 110,
                        render: renderAction
                    },
                ]}
                dataSource={result}
                scroll={{ x: "max-content", y: `calc(100vh - 275px)` }}
                showPagination
                loading={loading}
                rowKey={'ID'}
                totalResult={total}
                currentPage={current_page}
                limit={limit}
                onNext={() => dispatch(getDsPhTamUng({ page: current_page + 1, limit: 15 }))}
                onPrev={() => dispatch(getDsPhTamUng({ page: current_page - 1, limit: 15 }))}
                onClickRow={handleClickRow}
                summary={() => (
                    <AntTable.Summary fixed="bottom">
                        <AntTable.Summary.Row>
                            <AntTable.Summary.Cell index={0} colSpan={3}></AntTable.Summary.Cell>
                            <AntTable.Summary.Cell index={1} colSpan={1}>
                                <b className="bold-txt">{i18n.t(languageKeys.tong_cong)}:</b>
                            </AntTable.Summary.Cell>
                            <AntTable.Summary.Cell index={2} colSpan={6}>
                                <b className="bold-txt">{formatCurrency(total_money, "", true)}</b>
                            </AntTable.Summary.Cell>
                            <AntTable.Summary.Cell index={3} colSpan={1}></AntTable.Summary.Cell>
                        </AntTable.Summary.Row>
                    </AntTable.Summary>
                )}
            />

            <FormHoanTien ref={formHTRef} checkPermissions={checkPermissions} />
            <FormDsChuaTT ref={formTTRef} />

            <ConfirmModal
                ref={deleteRef}
                content={i18n.t(languageKeys.confirm_cancel_ticket)}
                danger={true}
                onOk={handleDelete}
            />

            <ModalPDF ref={pdfRef} />
        </Layout.Content>
    )
}

export default TableTamUng

// Cột tạm ứng
const columns = [
    {
        title: i18n.t(languageKeys.field_Ma_phieu),
        dataIndex: "MA_PHIEU",
        key: "MA_PHIEU",
        width: 110,
    },
    {
        title: i18n.t(languageKeys.loai_phieu),
        dataIndex: "LOAI_PHIEU",
        key: "LOAI_PHIEU",
        width: 150,
        render: (data) => LOAI_PHIEU_TamUng.find(item => item.value === data)?.label
    },
    {
        title: i18n.t(languageKeys.ma_benh_nhan),
        dataIndex: "MA_BENH_NHAN",
        key: "MA_BENH_NHAN",
        width: 160,
    },
    {
        title: i18n.t(languageKeys.ho_va_ten),
        dataIndex: "TEN",
        key: "TEN",
        width: 170,
    },
    {
        title: i18n.t(languageKeys.txt_So_tien),
        dataIndex: "SO_TIEN",
        key: "SO_TIEN",
        width: 140,
        render: (MONEY) => formatCurrency(MONEY, "", true)
    },
    {
        title: i18n.t(languageKeys.ly_do_tam_hoan_ung),
        dataIndex: "LY_DO",
        key: "LY_DO",
        width: 250,
        render: (txt) => {
            switch (txt) {
                case "THANH_TOAN_TAM_UNG":
                    return i18n.t(languageKeys.thanh_toan_tam_ung)
                case "TIEN THUA":
                    return i18n.t(languageKeys.vien_phi_tien_thua)
                default:
                    return txt
            }
        }
    },
    {
        title: i18n.t(languageKeys.field_hinh_thuc_thanh_toan),
        dataIndex: "TEN_HINH_THUC_THANH_TOAN",
        key: "TEN_HINH_THUC_THANH_TOAN",
        width: 170,
    },
    {
        title: i18n.t(languageKeys.txt_nguoi_tao_phieu),
        dataIndex: "NGUOI_TAO",
        key: "NGUOI_TAO",
        width: 170,
    },
    {
        title: i18n.t(languageKeys.ngay_tao_phieu),
        dataIndex: "NGAY_TAO",
        key: "NGAY_TAO",
        width: 150,
        render: (DATE) => moment(DATE, "YYYYMMDD").format("DD/MM/YYYY")
    },
]