import { Col, Row } from "antd";
import style from "./mediTv1.module.less";
import { HLog, rid } from "../../../helpers";
import { useEffect, useState } from "react";
import moment from "moment";
import { _layDSBenhNhanChoKham } from "../../QuanLyKhamBenh/apisKhamBenh/apisSiderBar";
import { apis } from "../../../constants";
import Constants from "../../../constants/Constants";
import { useSelector } from "react-redux";

const TYPE_CHOKHAM = "CHO_KHAM"; //Type ds khách hàng chờ khám

var _eventSource = null;

export const MediTv1 = ({
  room_id,
  hospital_id,
  partner_code,
  payment_required = false,
}) => {
  const [dataSource, setDataSource] = useState([]);
  const eventData = useSelector((state) => state.eventSlice.event);

  useEffect(() => {
    if (!!eventData.type) {
      HLog("MediTv1 useEffect eventData: ", eventData);
      // nếu yêu cầu bắt buộc phải thanh toán trước khi được vào khám
      if (!!payment_required) {
        // nếu có lượt điều trị được thanh toán thì reload
        if (eventData.type === Constants.EVENT_THANH_TOAN_LUOT_DIEU_TRI) {
          getListAndInitEventSource();
        }
      } else {
        // nếu bệnh viện ko bắt buộc thanh toán trước khám => reload lại danh sách khám bệnh
        if (eventData.type === Constants.EVENT_TIEP_DON_LUOT_DIEU_TRI) {
          getListAndInitEventSource();
        }
      }
      if (eventData.type === Constants.XOA_LUOT_DIEU_TRI) {
        layDsBenhNhanChoKham();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  useEffect(() => {
    getListAndInitEventSource();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getListAndInitEventSource = () => {
    if (!!room_id) {
      layDsBenhNhanChoKham();
      initEventSource();
    }
  };

  const layDsBenhNhanChoKham = async () => {
    if (!room_id || room_id === "") return HLog("Chưa có phòng khám");

    let dataRequest = {
      partner_code: partner_code,
      BENH_VIEN_ID: hospital_id,
      NGAY: moment().format("YYYYMMDD"),
      PHONG_ID: room_id,
      LOAI: TYPE_CHOKHAM,
      page: 1,
    };

    let res = await _layDSBenhNhanChoKham(dataRequest);

    if (res && res.status === "OK") {
      setDataSource(res.result);
    } else {
      setDataSource([]);
    }
  };

  //event source liên quan đến reload lượt điều trị
  const initEventSource = () => {
    let urlEvent =
      apis.event_reload_kham_benh +
      "?BENH_VIEN_ID=" +
      hospital_id +
      "&&partner_code=" +
      partner_code +
      "&&PHONG_ID=" +
      room_id;

    HLog("MediTv1 initEventSource urlEvent: " + urlEvent);

    _eventSource = new EventSource(urlEvent);
    _eventSource.onmessage = (e) => {
      HLog("MediTv1 initEventSource onmessage: ", JSON.parse(e.data));
    };

    _eventSource.addEventListener("responseReloadCIS", (event) => {
      HLog(
        "MediTv1 initEventSource addEventListener update:",
        JSON.parse(event.data)
      );

      if (event.data && event.data !== "") {
        let dataEvent = JSON.parse(event.data);
        let { type } = dataEvent;

        if (
          type === Constants.EVENT_THANH_TOAN_LUOT_DIEU_TRI ||
          type === Constants.EVENT_LUOT_DIEU_TRI_VAO_KHAM ||
          type === Constants.EVENT_LUOT_DIEU_TRI_KHAM_XONG ||
          type === Constants.EVENT_XAC_NHAN_LUOT_DIEU_TRI ||
          type === Constants.XOA_LUOT_DIEU_TRI ||
          type === Constants.THEM_LUOT_DIEU_TRI
        ) {
          HLog("XOA_LUOT DIEU TRI")
          layDsBenhNhanChoKham();
        }
      }
    });
  };

  return (
    <div className={style["container"]}>
      <div className={style["waiting-data"]}>
        <h1>Khách hàng tiếp theo</h1>

        {
          <Row>
            <Col span={12}>
              {dataSource.slice(0, 7).map((item) => (
                <div className={style["item"]} key={rid()}>
                  <span className={style["number"]}>{item.STT_VAO_KHAM}</span> -{" "}
                  {item.TEN}
                </div>
              ))}
            </Col>

            <Col span={12}>
              {dataSource.slice(7, 14).map((item) => (
                <div className={style["item"]} key={rid()}>
                  <span className={style["number"]}>{item.STT_VAO_KHAM}</span> -{" "}
                  {item.TEN}
                </div>
              ))}
            </Col>
          </Row>
        }
      </div>
    </div>
  );
};
