import { Button, Collapse, Drawer, Input, Space, Row, Form, Col, InputNumber, Spin, Tooltip } from "antd";
import { CaretRightOutlined } from "@ant-design/icons";
import { useEffect, useImperativeHandle, useState } from "react";
import { forwardRef } from "react";
import style from "../qltu.module.less";
import i18n, { languageKeys } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { QlyTamUngData, createPhHoanTien } from "ducks/slices/QlyVienPhi/QlyTamUng.slice";
import { doFetchBook, doFetchPaymentMethod, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { formLoadingState } from "ducks/slices/loadingSlice";
import { ModalPDF, Select } from "components";
import { useRef } from "react";
import { DateInput } from "components_v2";
import moment from "moment";
import { danhSachGioiTinh } from "constants/data";
import _ from "lodash";
import { useEventListener } from "hooks";
import { NewBackIcon } from "assets/svg";
import { loaiSo, paymentMethodProto } from "constants/keys";

const FormHoanTien = forwardRef(({ checkPermissions }, ref) => {

    const [form] = Form.useForm()
    const [open, setOpen] = useState(false)
    const [isNew, setIsNew] = useState(false)
    const [doPrint, setDoPrint] = useState(false)

    const dateRef = useRef()
    const pdfRef = useRef()

    const dispatch = useDispatch()
    const userProfile = useSelector((state) => state.auth.user);
    const { result: DsPhTamUng } = useSelector(QlyTamUngData)
    const { paymentMethod, advanceBook } = useSelector(getSharedData)
    const loading = useSelector(formLoadingState)

    useImperativeHandle(ref, () => ({
        open: (data, isNew) => {
            !advanceBook.length && dispatch(doFetchBook({ LOAI_SO: loaiSo.SO_TAM_UNG }))
            !paymentMethod.length && dispatch(doFetchPaymentMethod())
            setOpen(true)
            form.setFields(Object.keys(isNew ? _.omit(data, ['SO_HOADON_ID', 'LY_DO', 'SO_TIEN', 'HINH_THUC_THANH_TOAN']) : data)
                .map(item => {
                    if (item === "NGAY_SINH") {
                        let date = moment(data.NGAY_SINH).isValid()
                            ? moment(data.NGAY_SINH).format("DD/MM/YYYY")
                            : moment(data.NGAY_SINH, "DDMMYYYY").format("DD/MM/YYYY")
                        if (data.NGAY_SINH.length === 4) {
                            dateRef.current?.setDate(["", "", data.NGAY_SINH]);
                        } else if (data.NGAY_SINH.length === 8) {
                            dateRef.current?.setDate(date.split("/"));
                        }
                        return {
                            name: "NGAY_SINH",
                            value: moment(date).format(data.NGAY_SINH.length === 4 ? "YYYY" : "DDMMYYYY")
                        }
                    } else if (item === "SO_HOADON_ID" && _.isNull(data.SO_HOADON_ID)) {
                        return {
                            name: "SO_HOADON_ID",
                            value: ""
                        }
                    } else if (item === "LY_DO" && data.LY_DO === "TIEN THUA") {
                        return {
                            name: "LY_DO",
                            value: i18n.t(languageKeys.vien_phi_tien_thua)
                        }
                    } else {
                        return {
                            name: item,
                            value: data[item]
                        }
                    }
                }))
            setIsNew(isNew)
        },
        isClose: !open,
    }))

    useEffect(() => {
        handleClose()
    }, [DsPhTamUng])

    const handleClose = () => {
        setOpen(false)
        form.resetFields()
        setDoPrint(false)
    }

    const onFinish = () => {
        dispatch(createPhHoanTien({
            Payload: {
                ID: form.getFieldValue('ID'),
                SO_HOADON_ID: form.getFieldValue('SO_HOADON_ID'),
                LY_DO: form.getFieldValue('LY_DO'),
                SO_TIEN: form.getFieldValue('SO_TIEN'),
                SO_TIEN_THUC: form.getFieldValue('SO_TIEN_THUC'),
                BENH_NHAN_ID: form.getFieldValue('BENH_NHAN_ID'),
                NHANSU_ID: userProfile.NHANSU_ID,
                HINH_THUC_THANH_TOAN: form.getFieldValue('HINH_THUC_THANH_TOAN'),
            },
            doPrint: doPrint
                ? (MA_PHIEU) => pdfRef.current?.openModalWithData({ ...form.getFieldValue(undefined), MA_PHIEU }, "phieuHoanUng")
                : () => {}
        }))
    }

    const handleSubmit = (doPrint) => {
        setDoPrint(doPrint)
        form.submit()
    }

    const keydownEvent = (event) => {
        if (event.ctrlKey && event.key === "l" && isNew) {
            event.stopPropagation();
            event.preventDefault();
            handleSubmit(true)
        }
        if (event.ctrlKey && event.key === "s" && isNew) {
            event.stopPropagation();
            event.preventDefault();
            handleSubmit(false)
        }
        if (event.ctrlKey && event.key === "p" && !isNew && checkPermissions?.HOAN_TIEN) {
            event.stopPropagation();
            event.preventDefault();
            pdfRef.current?.openModalWithData(form.getFieldValue(undefined), "phieuHoanUng")
        }
    };
    useEventListener("keydown", keydownEvent, window.document, open);

    // Title
    const FormTitle = (
        <Space className={style["spaceDiv"]}>
            <div className={style["title"]}>
                <NewBackIcon onClick={handleClose} style={{ cursor: "pointer" }} />
                <h2>{i18n.t(languageKeys.phieu_hoan_tam_ung)}</h2>
            </div>
        </Space>
    );

    // Footer
    const FormFooter = (
        <Space style={{ float: 'right' }}>
            <Button type="primary" ghost onClick={handleClose}>
                {i18n.t(languageKeys.common_Huy)} (ESC)
            </Button>
            {isNew ? (
                <>
                    <Button type="primary" onClick={() => handleSubmit(false)}>
                        {i18n.t(languageKeys.common_Luu)} (Ctrl+S)
                    </Button>
                    <Button type="primary" onClick={() => handleSubmit(true)}>
                        {i18n.t(languageKeys.common_Luu_va_In)} (Ctrl+L)
                    </Button>
                </>
            ) : (
                <Tooltip title={checkPermissions?.HOAN_TIEN ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                    <Button
                        type="primary"
                        onClick={() => pdfRef.current?.openModalWithData(form.getFieldValue(undefined), "phieuHoanUng")}
                        disabled={!checkPermissions?.HOAN_TIEN}
                    >
                        {i18n.t(languageKeys.field_In_phieu)} (Ctrl+P)
                    </Button>
                </Tooltip>
            )}
        </Space>
    )

    return (
        <Drawer
            width={780}
            open={open}
            onClose={handleClose}
            title={FormTitle}
            footer={FormFooter}
            className={style['drawer']}
            maskClosable={false}
            forceRender
        >
            <Spin spinning={loading}>
                <Form form={form} layout="vertical" onFinish={onFinish}>
                    <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        defaultActiveKey={["TTKH", "TTHTTU"]}
                    >
                        <Collapse.Panel header={i18n.t(languageKeys.thong_tin_khach_hang)} key="TTKH">
                            <div className={style['wrapper']}>
                                <Row gutter={[10, 3]}>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.ma_khach_hang)} name={'MA_BENH_NHAN'}>
                                            <Input readOnly />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.ho_va_ten)} name={'TEN'}>
                                            <Input readOnly />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.field_Gioi_tinh)} name={'GIOI_TINH'}>
                                            <Select
                                                dataSource={danhSachGioiTinh}
                                                valueKey='key'
                                                titleKey='name'
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.field_Ngay_sinh)} name={'NGAY_SINH'}>
                                            <DateInput ref={dateRef} readOnly tabIndex={[4, 4, 3]} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.sdt)} name={'SO_DIEN_THOAI'}>
                                            <Input readOnly />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.field_CMND_CCCD)} name={'CCCD'}>
                                            <Input readOnly />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.field_Tinh_thanh)} name={'MA_TINH_THANH'}>
                                            <Select
                                                dataSource={[{
                                                    MA_TINH_THANH: form.getFieldValue('MA_TINH_THANH'),
                                                    TEN_TINH_THANH: form.getFieldValue('TEN_TINH_THANH')
                                                }]}
                                                valueKey='MA_TINH_THANH'
                                                titleKey='TEN_TINH_THANH'
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.field_Quan_huyen)} name={'MA_QUAN_HUYEN'}>
                                            <Select
                                                dataSource={[{
                                                    MA_QUAN_HUYEN: form.getFieldValue('MA_QUAN_HUYEN'),
                                                    TEN_QUAN_HUYEN: form.getFieldValue('TEN_QUAN_HUYEN')
                                                }]}
                                                valueKey='MA_QUAN_HUYEN'
                                                titleKey='TEN_QUAN_HUYEN'
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.field_Xa_phuong)} name={'MA_PHUONG_XA'}>
                                            <Select
                                                dataSource={[{
                                                    MA_PHUONG_XA: form.getFieldValue('MA_PHUONG_XA'),
                                                    TEN_PHUONG_XA: form.getFieldValue('TEN_PHUONG_XA')
                                                }]}
                                                valueKey='MA_PHUONG_XA'
                                                titleKey='TEN_PHUONG_XA'
                                                disabled
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label={i18n.t(languageKeys.field_Dia_chi_chi_tiet)} name={'DIA_CHI_CHI_TIET'}>
                                            <Input readOnly />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse.Panel>
                        <Collapse.Panel header={i18n.t(languageKeys.thong_tin_hoan_ung)} key="TTHTTU">
                            <div className={style['wrapper']}>
                                <Row gutter={[10, 3]}>
                                    <Col span={8}>
                                        <Form.Item label={i18n.t(languageKeys.tong_tien_tam_ung)} name={'SO_TIEN_THUC'}>
                                            <InputNumber formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')} readOnly />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label={i18n.t(languageKeys.so_tien_hoan_ung)}
                                            name={'SO_TIEN'}
                                            rules={[
                                                {
                                                    required: true,
                                                    message: i18n.t(languageKeys.vui_long_nhap),
                                                },
                                                {
                                                    validator: async (_, SO_TIEN) => {
                                                        let SO_TIEN_THUC = form.getFieldValue("SO_TIEN_THUC");
                                                        if (SO_TIEN_THUC != null)
                                                            if (SO_TIEN_THUC < SO_TIEN) {
                                                                return Promise.reject(
                                                                    i18n.t(languageKeys.tien_hoan_ung_ko_the_lon_hon_tien_tam_ung)
                                                                );
                                                            }
                                                    },
                                                },
                                            ]}
                                        >
                                            <InputNumber
                                                placeholder={i18n.t(languageKeys.common_Nhap)}
                                                controls={false}
                                                formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                                readOnly={!isNew}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label={i18n.t(languageKeys.hinh_thuc_chi)}
                                            name={isNew ? 'HINH_THUC_THANH_TOAN' : 'TEN_HINH_THUC_THANH_TOAN'}
                                            rules={[{
                                                required: true,
                                                message: i18n.t(languageKeys.please_chon),
                                            }]}
                                        >
                                            <Select
                                                dataSource={paymentMethod.filter(i => ![paymentMethodProto.TRA_GOP,paymentMethodProto.CONG_NO,paymentMethodProto.TIEN_DU,paymentMethodProto.TAM_UNG]
                                                    .some(txt => i?.PHAN_LOAI_HINH_THUC?.includes?.(txt)))}
                                                valueKey='ID'
                                                titleKey='TEN_HINH_THUC_THANH_TOAN'
                                                disabled={!isNew}
                                                onSelect={(val) => form.setFieldValue("TEN_HINH_THUC_THANH_TOAN",
                                                    paymentMethod.find(i => i.ID === val)?.TEN_HINH_THUC_THANH_TOAN)}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item
                                            label={i18n.t(languageKeys.so_hoan_tien)}
                                            name={'SO_HOADON_ID'}
                                            rules={[{
                                                required: true,
                                                message: i18n.t(languageKeys.please_chon),
                                            }]}
                                        >
                                            <Select
                                                dataSource={advanceBook}
                                                valueKey='ID'
                                                titleKey='TEN_SO'
                                                disabled={!isNew}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={16}>
                                        <Form.Item
                                            label={i18n.t(languageKeys.ly_do_hoan_ung)}
                                            name={'LY_DO'}
                                            rules={[{
                                                required: true,
                                                message: i18n.t(languageKeys.vui_long_nhap),
                                            }]}
                                        >
                                            <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isNew} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>
                        </Collapse.Panel>
                    </Collapse>
                </Form>
            </Spin>

            <ModalPDF ref={pdfRef} />

        </Drawer>
    )
})

export default FormHoanTien