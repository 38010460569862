import { notification } from "antd";
import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import i18n, { languageKeys } from "../../../../i18n";

export async function layDsDichVuChuaThanhToan(dataRequest) {
  try {
    const response = await common_post(
      apis.vienphi_lay_ds_dich_vu_cua_benh_nhan,
      dataRequest
    );
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay dich vụ error", error);
    return null;
  }
}

export async function layGoiDichVuChuaThanhToan(dataRequest) {
  try {
    const response = await common_post(
      apis.vienphi_lay_goi_dv_cua_benh_nhan,
      dataRequest
    );
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay dich vụ error", error);
    return null;
  }
}

export async function luuHoaDonThanhToan(dataRequest) {
  try {
    const response = await common_post(
      apis.vienphi_luu_hoa_don_thanh_toan,
      dataRequest
    );
    if (response && response.status === "OK") {
      return response;
    } else if (response && response.error_code === "003") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay dich vụ error", error);
    return null;
  }
}

export async function luuHoaDonThanhToan_v2(dataRequest) {
  try {
    const response = await common_post(
      apis.vienphi_luu_hoa_don_thanh_toan_v2,
      dataRequest
    );
    if (response) {
      return response;
    } else {
      notification.error({ message: i18n.t(languageKeys.common_co_loi_xay_ra) })
    }
    return null;
  } catch (error) {
    HLog("lay dich vụ error", error);
    return null;
  }
}

export async function luuHoaDonThanhToanGoiDv(dataRequest) {
  try {
    const response = await common_post(
      apis.vienphi_luu_hoa_don_thanh_toan_goidv,
      dataRequest
    );
    if (response) {
      return response;
    } else {
      notification.error({ message: i18n.t(languageKeys.common_co_loi_xay_ra) })
    }
    return null;
  } catch (error) {
    HLog("lay dich vụ error", error);
    return null;
  }
}

export async function hoanGoiDv(dataRequest) {
  try {
    const response = await common_post(
      apis.vienphi_hoan_goidv,
      dataRequest
    );
    if (response && response.status === "OK") {
      return response;
    } else if (response.status === "KO" && response.error_code === "003") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay dich vụ error", error);
    return null;
  }
}

export async function huyDichVu(dataRequest) {
  try {
    const response = await common_post(apis.huy_dich_vu_da_dat, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay dich vụ error", error);
    return null;
  }
}

export async function layTamUng(dataRequest) {
  try {
    const response = await common_post(apis.lay_tam_ung, dataRequest);
    if (response && response.status === "OK") {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay tam ung error", error);
    return null;
  }
}

export async function layChaCon(dataRequest) {
  try {
    const response = await common_post(apis.xu_ly_hien_thi_hoan_goi, dataRequest);
    if (response && response.status === "OK") {
      return response.result;
    }
    return null;
  } catch (error) {
    HLog("lay danh sach error", error);
    return null;
  }
}

export async function layTatCaDvGoiTrangThai(dataRequest) {
  try {
    const response = await common_post(
      apis.lay_tat_ca_Dv_Goi_TrangThai,
      dataRequest
    );
    if (response) {
      return response;
    }
    return null;
  } catch (error) {
    HLog("lay dich vụ error", error);
    return null;
  }
}

export async function huyHoaDonTT(dataRequest) {
  try {
    const res = await common_post(apis.cancel_paid, dataRequest);
    if (res && res.status === "OK") {
      return res;
    }
    return null;
  } catch (error) {
    HLog("chinh sua error", error);
    return null;
  }
}