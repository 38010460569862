import { useSelector } from "react-redux";
import { selectLoadingState } from "ducks/slices/loadingSlice";
import { getSharedData } from "ducks/slices/ListData/SharedData.slice";

export const useThongTinKhamBenh = () => {

  const { personnel } = useSelector(getSharedData);
  const loadingNhanSu = useSelector(selectLoadingState);

  const dsNhanSu = personnel.map((item) => ({
    key: item.NHANSU_ID,
    id: item.NHANSU_ID,
    name: item.HO + " " + item.TEN,
    QUYEN_MODULE: item?.QUYEN_MODULE
  }))

  return {
    dsNhanSu,
    loadingNhanSu,
  };
};
