import { Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { DocSupport, FileExclamationAlt, Profile, QuestionMark } from "../../../assets/svg";
import i18n, { languageKeys } from "../../../i18n";
import { defineKeys } from "../keys";

import style from "./style.module.less";

const SideBar = (props) => {
  const [realKey, setRealKey] = useState("");

  const history = useHistory();
  let query = useQuery();

  const pageKey = query.get("page") || defineKeys.HDSDBangDieuKhien;

  function getItem(label, key, icon, children, type) {
    return {
      key,
      icon,
      children,
      label,
      type,
    };
  }
  const items = [
    getItem(i18n.t(languageKeys.file_hdsd), "sub1", <Profile style={{ marginLeft: "2px", marginRight: "2px" }} />, [
      getItem(i18n.t(languageKeys.menu_Bang_dieu_khien) + " (" + i18n.t(languageKeys.data_Quan_tri) + ")", defineKeys.HDSDBangDieuKhien),
      getItem(i18n.t(languageKeys.menu_Bao_cao) + " (" + i18n.t(languageKeys.field_tat_ca) + ")", defineKeys.HDSDBaocao),
      getItem(i18n.t(languageKeys.menu_Quan_ly_tiep_don) + " (" + i18n.t(languageKeys.data_Tiep_tan) + ")", defineKeys.HDSDTiepDon),
      getItem(i18n.t(languageKeys.menu_Quan_ly_vien_phi) + " (" + i18n.t(languageKeys.data_Thu_ngan) + ")", defineKeys.HDSDVienPhi),
      getItem(i18n.t(languageKeys.menu_Quan_ly_kham_benh) + " (" + i18n.t(languageKeys.field_Bac_si) + ")", defineKeys.HDSDKhamBenh),
      getItem(i18n.t(languageKeys.menu_Quan_ly_duoc_ngoai_tru) + " (" + i18n.t(languageKeys.data_Thu_kho) + ")", defineKeys.HDSDDuoc),
      getItem(i18n.t(languageKeys.ris_nhap_tay) + " (" + i18n.t(languageKeys.data_Ky_thuat_vien) + ")", defineKeys.HDSDRisNhapTay),
      getItem(i18n.t(languageKeys.menu_Quan_ly_he_thong) + " (" + i18n.t(languageKeys.data_Quan_tri) + ")", defineKeys.HDSDHeThong),
    ]),
    getItem(i18n.t(languageKeys.hoi_dap), defineKeys.hoiDap, <QuestionMark style={{ marginLeft: "3px", marginRight: "4px" }} />),
    getItem(i18n.t(languageKeys.cong_cu_ho_tro), defineKeys.congCuHoTro, <DocSupport />),
    getItem(i18n.t(languageKeys.mau_danh_muc), defineKeys.mauDanhMuc, <FileExclamationAlt />),
  ]; // submenu keys of first level

  const [openKeys, setOpenKeys] = useState(["sub1"]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);

    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const rootSubmenuKeys = ["sub1", "sub2", "sub4"];

  useEffect(() => {
    if (
      pageKey !== defineKeys.HDSDBangDieuKhien &&
      pageKey !== defineKeys.HDSDBaocao &&
      pageKey !== defineKeys.HDSDTiepDon &&
      pageKey !== defineKeys.HDSDVienPhi &&
      pageKey !== defineKeys.HDSDKhamBenh &&
      pageKey !== defineKeys.HDSDDuoc &&
      pageKey !== defineKeys.HDSDRisNhapTay &&
      pageKey !== defineKeys.HDSDHeThong &&
      pageKey !== defineKeys.hoiDap &&
      pageKey !== defineKeys.congCuHoTro &&
      pageKey !== defineKeys.mauDanhMuc
    ) {
      setRealKey(defineKeys.HDSDBangDieuKhien);
    } else setRealKey(pageKey);
  }, [pageKey]);

  return (
    <div className={style["container"]}>
      <Menu
        mode="inline"
        openKeys={openKeys}
        onOpenChange={onOpenChange}
        items={items}
        // selectedKeys={pageKey ? pageKey : defineKeys.HDSDBangDieuKhien}

        selectedKeys={realKey}
        onSelect={(data) =>
          history.push({
            pathname: "/cis/ho-tro-khach-hang",
            search: `page=${data.key}`,
          })
        }
        style={{
          color: "#2C3782",
        }}
      />
    </div>
  );
};

export default SideBar;
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
