import { Button, Checkbox, Col, Form, Modal, Row, Spin } from "antd";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import style from "./style.module.less";
import i18n, { languageKeys } from "../../../../../i18n";
import DateInput from "components/DateInput_v2/DateInput_v2";
import { Calendar } from "assets/svg";
import moment from "moment";
import { useSelector } from "react-redux";
import { formLoadingState } from "ducks/slices/loadingSlice";

const ModalActiveBundle = ({ onOk }, ref) => {

    const [visible, setVisible] = useState(false);
    const inputRef = useRef();

    const [form] = Form.useForm()
    const DATA = Form.useWatch("DATA", form)

    const loading = useSelector(formLoadingState)

    useImperativeHandle(ref, () => ({
        open: (data) => {
            setVisible(true);
            if (!!data) {
                form.setFieldValue("DATA", data.map(item => ({
                    ...item,
                    IS_ACTIVE: true,
                    NGAY_KICH_HOAT: moment().format("DD/MM/YYYY"),
                    NGAY_KET_THUC: moment().add(item?.NGAY_SU_DUNG, "days").format("DD/MM/YYYY"),
                })))
            }
        },
        close: onDismiss,
    }));
    const getValue = (index, field) => form.getFieldValue(["DATA", index, field])

    const onClickOk = () => onOk(DATA.map(item => ({
        ...item,
        GOI_DV_ID: item.ID,
        IS_ACTIVE: item.IS_ACTIVE ? 1 : 0,
        NGAY_KICH_HOAT: moment(item.NGAY_KICH_HOAT, 'DD/MM/YYYY').format('YYYYMMDD'),
        NGAY_KET_THUC: moment(item.NGAY_KET_THUC, 'DD/MM/YYYY').format('YYYYMMDD'),
    })))

    const onDismiss = () => {
        setVisible(false)
        inputRef?.current.reset();
        form.resetFields()
    }

    return (
        <Modal visible={visible} onOk={onClickOk} footer={false} onCancel={onDismiss} className={style["active-modal"]}>
            <Spin spinning={loading}>
                <h3 className={style["title"]}>{i18n.t(languageKeys.kich_hoat_goi_dv)}</h3>

                <Form form={form} layout="vertical">
                    <Form.List name="DATA" initialValue={[{}]}>
                        {(fields) => (
                            <div className={style["content"]}>
                                {fields.map(({ key, name, ...restField }) => (
                                    <Row key={key} gutter={[0, 10]} className={style["content__row"]}>

                                        <Col span={24}>
                                            <Form.Item name={[name, "IS_ACTIVE"]} valuePropName="checked" style={{ margin: 0 }}>
                                                <Checkbox>{getValue(name, "TEN_GOI_DV")}</Checkbox>
                                            </Form.Item>
                                        </Col>

                                        <Col className={style['text-fade']} span={8}>{i18n.t(languageKeys.ngay_kich_hoat)}:</Col>
                                        <Col span={16}>
                                            <DateInput
                                                className={style["date-input"]}
                                                form={form}
                                                name={[name, "NGAY_KICH_HOAT"]}
                                                field={["DATA", name, "NGAY_KICH_HOAT"]}
                                                placeholder="DD/MM/YYYY"
                                                readOnly={false}
                                                suffix={<Calendar className={style["calendar"]} />}
                                                validateOldDay={true}
                                                onChangeInput={(value) => {
                                                    form.validateFields([["DATA", name, "NGAY_KICH_HOAT"]]).then(() => {
                                                        form.setFieldValue(["DATA", name, "NGAY_KET_THUC"], moment(value, "DD/MM/YYYY").add(getValue(name, "NGAY_SU_DUNG"), "days").format("DD/MM/YYYY"))
                                                    }, () => {
                                                        form.setFieldValue(["DATA", name, "NGAY_KET_THUC"], undefined);
                                                    }).catch(() => { })
                                                }}
                                                additionalRules={
                                                    [
                                                        {
                                                            validator: async (_, value) => {
                                                                if (value?.length < 10)
                                                                    return Promise.reject(
                                                                        new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le))
                                                                    );
                                                            },
                                                        },
                                                    ]
                                                }
                                                ref={inputRef}
                                            />
                                        </Col>

                                        <Col className={style['text-fade']} span={8}>{i18n.t(languageKeys.so_ngay_sd)}:</Col>
                                        <Col span={16}>{getValue(name, "NGAY_SU_DUNG")}</Col>

                                        <Col className={style['text-fade']} span={8}>{i18n.t(languageKeys.field_Ngay_ket_thuc)}:</Col>
                                        <Col span={16}>{getValue(name, "NGAY_KET_THUC")}</Col>

                                    </Row>
                                ))}
                            </div>
                        )}
                    </Form.List>
                </Form>

                <Row justify='center' gutter={10} className={style['footer']}>
                    <Col span={12}>
                        <Button ghost type="primary" onClick={onDismiss}>
                            {i18n.t(languageKeys.common_Huy)} (ESC)
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button type="primary" onClick={onClickOk}>
                            {i18n.t(languageKeys.title_Xac_nhan)}
                        </Button>
                    </Col>
                </Row>

            </Spin>
        </Modal>
    )
}

export default forwardRef(ModalActiveBundle);