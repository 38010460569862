import { CaretDownFilled, CaretRightOutlined, PlusCircleFilled, PrinterOutlined } from "@ant-design/icons";
import { Button, Checkbox, Col, Divider, Form, Input, Layout, notification, Row, Tooltip } from "antd";
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ModalHoanTien from "./ModalHoanTien";
import style from "../../style.module.less";
import { hoanGoiDv, luuHoaDonThanhToan_v2 } from "../../apisDvThanhToan/apisDvThanhToan";
import { Check_box, EditAlt, Trash, Uncheckbox } from "../../../../../assets/svg";
import { useEventListener } from "../../../../../hooks";
import i18n, { languageKeys } from "../../../../../i18n";
import { apis, keys } from "../../../../../constants";
import { featureKeys, keyLoaiNhanVien, paymentMethodProto } from "../../../../../constants/keys";
import { calcTotal, common_post, convertDateToValue, formatCurrency, handleCheckPermissions, rid } from "../../../../../helpers";
import { ConfirmModal, Select, Table } from "../../../../../components";
import { snapshotActions } from "ducks/slices/snapshotSlice";
import { useMemo } from "react";
import moment from "moment";
import { doFetchBook, doFetchPaymentMethod, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import ModalSuaPhieu from "./ModalSuaPhieu";

const DichvuDaThanhToan = forwardRef(
  (
    {
      patientInfo = {}, // thông tin bệnh nhân được chọn
      onThemSo, // thêm sổ hóa đơn
      currentPatient,
      tabView = "",
      onPrint = () => {},
      onManyPrints = () => {},
      isAddRefundBookAllowed = true,
      isCancelAllowed = false,
      onAfterCancel = () => {},
      setTabView = () => {},
      setIsRefundDisable = () => {},
      isPayAllowed = false,
    },
    ref
  ) => {

    const dispatch = useDispatch();
    const userProfile = useSelector((state) => state.auth.user);
    const { paymentMethod, refundBook } = useSelector(getSharedData);

    const [form] = Form.useForm();

    const refundRef = useRef();
    const confirmRef = useRef()
    const editRef = useRef()

    const [dataSource, setDataSource] = useState([]); // danh sách dịch vụ cần thanh toán
    const [tongTien, setTongTien] = useState(0); // tổng tiền hoàn
    const [loading, setLoading] = useState(false);
    const [infoGoiDv, setInfoGoiDv] = useState()

    // const isAdmin = userProfile.NHOM_NHAN_SU === keyLoaiNhanVien.quan_tri;

    const cancelCondition = (record) => {
      return (
        !isCancelAllowed ||
        !moment().isSame(moment(record?.NGAY).format('YYYYMMDD'), 'day') 
        // || (isAdmin ? false : userProfile?.NHANSU_ID !== record?.NHANSU_ID)
      )
    }

    const check_da_thuc_hien = (value) => {
      const stt = ["HOAN_THANH", "DA_THUC_HIEN", "KHAM_XONG"].includes(value)
      // console.log(`stt`,stt );
      return stt
    }

    const checkRoleArr = useMemo(() => {
      return handleCheckPermissions(userProfile, featureKeys.quan_ly_dich_vu_can_thanh_toan)
    }, [userProfile])

    useImperativeHandle(ref, () => ({
      reloadSo: () => {
        dispatch(doFetchBook({ LOAI_SO: 'SO_HOAN_TIEN' }));
      },
      print: () => {
        if (!dataSource.length) {
          notification.error({
            message: i18n.t(languageKeys.vienphi_no_service),
            placement: "bottomLeft",
          });
          return;
        }
        onPressPrint();
      },
      printAll: () => {
        if (!dataSource.length) {
          notification.error({
            message: i18n.t(languageKeys.vienphi_no_service),
            placement: "bottomLeft",
          });
          return;
        }
        onPressPrint(true);
      },
      onOpenRefundModal: () => handleOpenRefundModal(),
      reloadList: () => {
        let body = {
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
          LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
          ID: patientInfo.ID,
        };
        layDsDichDaThanhToan(body);
      },
      checkDisable,
    }));

    const handleKeyPress = (e) => {
      if (e.ctrlKey && e.key === "i") {
        e.stopPropagation();
        e.preventDefault();
        onPressPrint();
      }
    };

    useEventListener("keydown", handleKeyPress, window.document, tabView === "DA_THANH_TOAN");

    useEffect(() => {
      if (!currentPatient) setDataSource([]);
      form.resetFields()
    }, [currentPatient]);

    useEffect(() => {
      if (dataSource.length === 0) {
        form.setFields([
          {
            name: "LY_DO_HOAN_TIEN",
            errors: [],
          },
        ]);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSource]);

    const layDsDichDaThanhToan = async (body) => {
      setLoading(true);
      const res = await common_post(body.LICH_KHAM_ID ? apis.lay_chi_tiet_don_theo_benh_nhan : apis.lay_ds_goi_da_tt, body);
      if (res && res.status === "OK") {
        setLoading(false);
        const { result } = res;

        if (body.LICH_KHAM_ID) {
          setDataSource(() => {
            let newDataSource = [];
            newDataSource = result.map((phieu_dv, item) => {
              const so_luong = phieu_dv.CHI_TIET_HOA_DON.reduce((acc, curr) => {
                return acc + curr.SO_LUONG;
              }, 0);
              let newChild = phieu_dv.CHI_TIET_HOA_DON.filter((item) => item.THANH_TOAN === 1);
              let newPhieu = {};
              let tong_sl = newChild.reduce((acc, curr) => acc + curr.SOLUONG, 0);
              newPhieu = {
                ...phieu_dv,
                children: newChild,
                TEN_DICHVU: `${i18n.t(languageKeys.phieu_dich_vu)} ${phieu_dv.SO_PHIEU ? `- ${phieu_dv.SO_PHIEU}` : ""}`,
                SO_LUONG: so_luong,
                tong_sl,
                key: rid(),
              };
              delete newPhieu?.CHI_TIET_HOA_DON;
              return {
                ...newPhieu,
              };
            });
            let newData = newDataSource.filter((item) => item.children.length);
            return newData;
          });
          setIsRefundDisable(result.findIndex(service => service?.TIEN_THANH_TOAN < service?.TONG_THANH_TOAN) !== -1);
          let tong_tien = result.reduce((gia_tri, phieu_dv) => {
            return gia_tri + phieu_dv.TONG_THANH_TOAN;
          }, 0);
          setTongTien(tong_tien);
          setInfoGoiDv()
        } else {
          const formatData = result.map((item, key) => ({
            ...item,
            key,
            TEN_DICHVU: `${i18n.t(languageKeys.phieu_dich_vu)} ${item.SO_PHIEU ? `- ${item.SO_PHIEU}` : ""}`,
            children: item.DS_DV.map((i, index) => ({
              ...i,
              key: rid(),
              GIA_DICH_VU: i.DON_GIA,
              TEN_DICHVU: i.TEN_DV
            }))
          }))
          setDataSource(formatData)

          let tong_tien = result.reduce((gia_tri, phieu_dv) => {
            return gia_tri + phieu_dv.TONG_THANH_TOAN;
          }, 0);
          setTongTien(tong_tien);
          setInfoGoiDv({
            TEN_GOI_DV: result[0]?.TEN_GOI_DV,
            TEN_LOAI_GOI: result[0]?.TEN_LOAI_GOI,
            GOI_DV_ID: result[0]?.GOI_DV_ID,
          })
        }

      }
    };

    const checkDisable = () => {
      let newArray = dataSource.map((item) => {
        return {
          ...item.children,
        };
      });
      let check = newArray.filter((item) => {
        return {
          ...(item.TRANG_THAI === ("CHO_XAC_NHAN" || "XAC_NHAN" || "CHO_KHAM")),
        };
      });
      if (check.length) {
        return false;
      } else {
        return true;
      }
    };

    useEffect(() => {
      !paymentMethod.length && dispatch(doFetchPaymentMethod())
      !refundBook.length && dispatch(doFetchBook({ LOAI_SO: 'SO_HOAN_TIEN' }));
      return () => {
        dispatch(snapshotActions.setDisabled(false));
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      form.setFields([
        {
          name: "SO_HOADON_ID",
          value: refundBook?.[0]?.ID,
          errors: [],
        },
      ]);
    }, [refundBook])

    // Khởi tạo dữ liệu với từng bệnh nhân
    useEffect(() => {
      const getDataServiceHadComplete = async () => {
        if (patientInfo.BENH_NHAN_ID) {
          let body = {
            partner_code: userProfile.partner_code,
            BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
            BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
            LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
            ID: patientInfo.ID,
          };
          await layDsDichDaThanhToan(body);
        }
      };

      getDataServiceHadComplete();
      return () => {
        setTongTien(0);
        setLoading(false);
      };
    }, [patientInfo]);

    useEffect(() => {
      if (!dataSource?.length) {
        dispatch(snapshotActions.setDisabled(true));
      } else {
        dispatch(snapshotActions.setDisabled(false))
      }
    }, [dataSource])

    const columnsDaThanhToan = [
      {
        title: i18n.t(languageKeys.field_Ten_dich_vu),
        dataIndex: "TEN_DICHVU",
        key: "TEN_DICHVU",
        width: 220,
        render: (ten_dv, record) => {
          if (record.BUNDLE_INFO) return (
            <Checkbox value={record} onChange={(e) => setInfoGoiDv(e.target.value)} checked={record.GOI_DV_ID === infoGoiDv?.GOI_DV_ID}>
              {record.TEN_GOI_DV?.toUpperCase()}
            </Checkbox>
          )
          if (record.children) {
            return <b className={style["txt-highlight"]}>{ten_dv}</b>;
          } else {
            return (
              <Tooltip title={ten_dv}>
                <div style={{ maxWidth: 270 }} className={style["text-overflow"]}>
                  {ten_dv}
                </div>
              </Tooltip>
            );
          }
        },
        onCell: (record) => ({ colSpan: record.BUNDLE_INFO ? 8 : 1 }),
      },

      {
        title: i18n.t(languageKeys.field_don_gia),
        dataIndex: "GIA_DICH_VU",
        key: "GIA_DICH_VU",
        render: (money, record) => {
          return (
            <span>
              {!record.children ? (
                <>{formatCurrency(money, "đ", true)}</>
              ) : (
                <b className={style["title-money"]}>
                  {record.GIO} - {convertDateToValue(record.NGAY)}
                </b>
              )}{" "}
            </span>
          );
        },
        width: 200,
        onCell: (record) => ({ colSpan: record.BUNDLE_INFO ? 0 : 1 }),
      },
      {
        title: i18n.t(languageKeys.field_SL),
        dataIndex: "SO_LUONG",
        key: "SO_LUONG",
        width: 90,
        render: (SO_LUONG, record) => record.DS_DV ? calcTotal(record.DS_DV || [], "SO_LUONG") : SO_LUONG,
        onCell: (record) => ({ colSpan: record.BUNDLE_INFO ? 0 : 1 }),
      },
      {
        title: i18n.t(languageKeys.tong_tien),
        dataIndex: "TONG_CHI_PHI",
        key: "TONG_CHI_PHI",
        width: 120,
        render: (TONG_CHI_PHI, record) => formatCurrency(TONG_CHI_PHI ? TONG_CHI_PHI : (record.GIA_DICH_VU * record.SO_LUONG), "đ", true),
        onCell: (record) => ({ colSpan: record.BUNDLE_INFO ? 0 : 1 }),
      },
      {
        title: i18n.t(languageKeys.field_Mien_giam),
        dataIndex: "TONG_TIEN_GIAM",
        key: "TONG_TIEN_GIAM",
        width: 120,
        render: (tong_giam, record) => {
          if (record.children) {
            return <>{formatCurrency(record.MIEN_GIAM, "đ", true)}</>;
          } else {
            return <>{formatCurrency(record.TONG_TIEN_GIAM, "đ", true)}</>;
          }
        },
        onCell: (record) => ({ colSpan: record.BUNDLE_INFO ? 0 : 1 }),
      },
      {
        title: i18n.t(languageKeys.vien_phi_thanh_tien),
        dataIndex: "TONG_THANH_TOAN",
        key: "TONG_THANH_TOAN",
        render: (money, record) => {
          return record.children ? (
            <span className="green-txt">{money ? formatCurrency(money, "đ", true) : (money === 0 ? '0 đ' : "")} </span>
          ) : (
            <span className="navy-txt">{formatCurrency(record.GIA_DICH_VU * record.SO_LUONG - record.TONG_TIEN_GIAM, "đ", true)}</span>
          );
        },
        width: 150,
        onCell: (record) => ({ colSpan: record.BUNDLE_INFO ? 0 : 1 }),
      },
      {
        title: <span className={style["table-size"]}>{i18n.t(languageKeys.field_Da_TH)}</span>,
        dataIndex: "TRANG_THAI",
        key: "TRANG_THAI",
        width: 80,
        render: (status, record) => {
          if (record.TRANG_THAI_THUC_HIEN === "DA_THUC_HIEN") {
            return (
              <div style={{ marginLeft: 6 }}>
                <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
                  <Check_box />
                </Tooltip>
              </div>
            );
          }
          if (status) {
            if (currentPatient?.LOAI === "THANH_TOAN_MUA_GOI"
              ? (status === null || status === "CHUA_KHAM")
              : !(check_da_thuc_hien(status) || check_da_thuc_hien(record?.TRANG_THAI_THUC_HIEN))
            ) {
              return (
                <div style={{ marginLeft: 6 }}>
                  <Tooltip title={i18n.t(languageKeys.chua_thuc_hien)}>
                    <Uncheckbox />
                  </Tooltip>
                </div>
              );
            } else {
              return (
                <Tooltip title={i18n.t(languageKeys.da_thuc_hien)}>
                  <Check_box style={{ marginLeft: 6 }} />
                </Tooltip>
              );
            }
          } else {
            return <></>;
          }
        },
        onCell: (record) => ({ colSpan: record.BUNDLE_INFO ? 0 : 1 }),
      },
      {
        title: null,
        dataIndex: "action_btn",
        key: "action_btn",
        width: "80px",
        fixed: 'right',
        render: (_, record) => record.children ? (
          <div onClick={(e) => e.stopPropagation()} className={style['action']}>
            <Tooltip>
              <Button
                icon={<EditAlt className={style.editIcon} />}
                type="link"
                onClick={() => editRef.current?.open(record)}
                disabled={cancelCondition(record) || !isPayAllowed}
              />
            </Tooltip>
            <Tooltip title={!checkRoleArr.IN_PDF ? i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay) : i18n.t(languageKeys.common_In) + ` ${record.TEN_DICHVU}`}>
              <Button
                icon={<PrinterOutlined className={style['print']} />}
                type="link"
                disabled={!checkRoleArr.IN_PDF}
                onClick={(e) => onPressPrint(false, [record.HOA_DON_ID])}
              />
            </Tooltip>
            <Tooltip title={isCancelAllowed ? (i18n.t(languageKeys.huy_phieu) + ` ${record.SO_PHIEU}`) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
              <Button
                icon={<Trash className={style['delete']} />}
                type="link"
                onClick={() => confirmRef.current?.open(record)}
                disabled={cancelCondition(record)}
              />
            </Tooltip>
          </div>
        ) : (
          <></>
        ),
        onCell: (record) => ({ colSpan: record.BUNDLE_INFO ? 0 : 1 }),
      },
    ];

    const handleOpenRefundModal = () => {
      let ly_do_hoan = form.getFieldValue("LY_DO_HOAN_TIEN");
      if (!ly_do_hoan) {
        form.submit();
        notification.error({
          message: i18n.t(languageKeys.noti_vien_phi_ly_do_hoan_tien),
          placement: "bottomLeft",
        });
        return;
      }
      refundRef.current.open();
    };

    async function onHoanTien(value, print = false) {
      let SO_HOADON_ID = form.getFieldValue("SO_HOADON_ID");
      let LY_DO_HOAN_TIEN = form.getFieldValue("LY_DO_HOAN_TIEN");
      let HINH_THUC_THANH_TOAN = form.getFieldValue('HINH_THUC_THANH_TOAN');
      if (!LY_DO_HOAN_TIEN) {
        notification.error({
          message: i18n.t(languageKeys.noti_vien_phi_ly_do_hoan_tien),
          placement: "bottomLeft",
        });
        return;
      }
      if (!SO_HOADON_ID) {
        notification.error({
          message: i18n.t(languageKeys.noti_so_hoa_don),
          placement: "bottomLeft",
        });
        return;
      }
      let dataRequest = {
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        list_dv: value.list_dv,
        SO_HOADON_ID,
        BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
        TONG_CHI_PHI: value.TONG_CHI_PHI,
        MIEN_GIAM: value.MIEN_GIAM,
        TONG_THANH_TOAN: value.TONG_THANH_TOAN,
        NHANSU_ID: userProfile.NHANSU_ID,
        LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
        NHOM: "HOAN_TIEN",
        LY_DO_HOAN_TIEN: LY_DO_HOAN_TIEN,
        GOI_DV_ID: infoGoiDv?.GOI_DV_ID,
        list_dv_thanh_toan: [{
          HINH_THUC_THANH_TOAN,
          TEN_HINH_THUC_THANH_TOAN: paymentMethod.find(item => item.ID === HINH_THUC_THANH_TOAN)?.TEN_HINH_THUC_THANH_TOAN
        }]
      };
      let response
      if (currentPatient.LOAI === "THANH_TOAN_MUA_GOI") {
        response = await hoanGoiDv(dataRequest);
      } else {
        response = await luuHoaDonThanhToan_v2(dataRequest);
      }
      // const response = await luuHoaDonThanhToan_v2(dataRequest);
      if (response && response.status === "OK") {
        if (print) {
          onPrint(response.HOA_DON_ID, currentPatient.LOAI === "THANH_TOAN_MUA_GOI");
        }

        notification.success({
          message: i18n.t(languageKeys.thao_tac_thanh_cong),
        });
        form.resetFields(["LY_DO_HOAN_TIEN"]);

        // refundList = dataSource.map((phieu_dv, item) => {
        //   return phieu_dv.children;
        // });
        // refundList = flattenDeep(refundList);
        setDataSource([]);

        await layDsDichDaThanhToan({
          partner_code: userProfile.partner_code,
          BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
          BENH_NHAN_ID: patientInfo.BENH_NHAN_ID,
          LICH_KHAM_ID: patientInfo.LICH_KHAM_ID,
          ID: patientInfo.ID,
        });

        refundRef.current?.close();
      } else if (response && response.status === "KO" && response.error_code === "003") {
        notification.error({
          message: i18n.t(languageKeys.so_hoa_don_da_day),
        });
        dispatch(doFetchBook({ LOAI_SO: 'SO_HOAN_TIEN' }));
      }
    }

    // const handleHoanTien = useCallback(
    //   (values = {}, print) => {
    //     onHoanTien(values, print);
    //   },
    //   [patientInfo]
    // );

    const onPressPrint = (printAll = false, dataPrints = dataSource.map((item) => item.HOA_DON_ID)) => {
      if (dataPrints.length) {
        onManyPrints(dataPrints, printAll);
      }
    };

    // const onReloadList = () => {
    //   layDsDichDaThanhToan()
    // }

    const handleCancelPaid = (info) => {
      form.validateFields(['LY_DO_HUY'])
        .then(async () => {
          confirmRef.current?.loading(true)
          try {
            const res = await common_post(apis.cancel_paid, {
              HOA_DON_ID: info.HOA_DON_ID,
              NGUOI_HUY: userProfile?.NHANSU_ID,
              LY_DO_HUY: form.getFieldValue('LY_DO_HUY')
            })
            if (!!res && res.status === 'OK') {
              confirmRef.current?.close()
              onAfterCancel("CHO_THANH_TOAN")
              setTabView("CHO_THANH_TOAN")
              notification.success({ message: i18n.t(languageKeys.thao_tac_thanh_cong), placement: 'bottomLeft' })
            } else {
              notification.error({ message: i18n.t(languageKeys.thao_tac_that_bai), placement: 'bottomLeft' })
            }
          } catch (err) {
            notification.error({ message: i18n.t(languageKeys.common_co_loi_xay_ra), placement: 'bottomLeft' })
          } finally {
            confirmRef.current?.loading(false)
          }
        }
        )
    }

    const formatDataSource = () => {
      let newData = []
      dataSource.forEach(item => {
        newData.push({
          GOI_DV_ID: item.GOI_DV_ID,
          TEN_GOI_DV: item.TEN_GOI_DV,
          TEN_LOAI_GOI: item.TEN_LOAI_GOI,
          BUNDLE_INFO: true,
        }, item)
      })
      return newData
    }

    return (
      <>
        <Layout.Content style={{ padding: "0 12px" }}>
          <Layout.Header style={{ backgroundColor: "transparent" }}>
            <Row>
              {infoGoiDv
                ? (
                  <Col flex={"auto"}>
                    <Row className={style["title"]} gutter={30} align='middle' style={{ height: 50, lineHeight: 'normal' }}>
                      <Col span={12}><span>{i18n.t(languageKeys.goi_dv)}: {infoGoiDv.TEN_GOI_DV}</span></Col>
                      <Col span={12}><span>{i18n.t(languageKeys.loai_goi_dv)}: {infoGoiDv.TEN_LOAI_GOI}</span></Col>
                    </Row>
                  </Col>
                )
                : (
                  <Col flex={"auto"}>
                    <div className={style["title"]} style={{ fontSize: 20, fontWeight: 600 }}>
                      {i18n.t(languageKeys.vienphi_dv_da_tt)}
                    </div>
                  </Col>
                )
              }
              {/* <Col style={{ marginLeft: "15px" }}>
              <Button icon={<ReloadOutlined />} type="primary"
                onClick={() => onReloadList()}
              >
                {i18n.t(languageKeys.common_tai_lai)}
              </Button>
            </Col> */}
            </Row>
          </Layout.Header>
          <Table
            columns={columnsDaThanhToan}
            className={style["table-custom"]}
            loading={loading}
            defaultExpandAllRows
            dataSource={currentPatient?.LOAI === "THANH_TOAN_MUA_GOI" ? formatDataSource() : dataSource}
            expandable={{
              expandRowByClick: true,
              defaultExpandAllRows: true,
            }}
            expandIcon={({ expanded, onExpand, record }) =>
              record.children ? (
                expanded ? (
                  <CaretDownFilled onClick={(e) => onExpand(record, e)} />
                ) : (
                  <CaretRightOutlined onClick={(e) => record.children && onExpand(record, e)} />
                )
              ) : (
                <></>
              )
            }
            classRow
            classnameRow={(record) => {
              if (record.children) return style["row-table-split"];
              if (record.BUNDLE_INFO) return style.bundleInfo;
            }}
            scroll={!!dataSource.length && { y: "calc(100vh - 280px)", x: 'max-content' }}

          />
        </Layout.Content>

        <Layout.Sider theme="light" className={style["payment-sider"]} width={210}>
          <Form form={form} onFinish={() => {}} layout="vertical">
            <h2 style={{ fontSize: 16, padding: "6px 12px" }}>{i18n.t(languageKeys.vien_phi_Hoan_tien)}</h2>
            <Divider style={{ margin: 0 }} />
            <Row justify="space-between" style={{ margin: "6px 0" }} className={style["sider-item"]}>
              <Col className={style["title-money"]} style={{ fontSize: 12 }}>
                {i18n.t(languageKeys.field_Tong_tien)} :{" "}
              </Col>
              <Col className={style["title-money"]}>{formatCurrency(tongTien, "đ", true)}</Col>
            </Row>
            <Divider style={{ margin: 0 }} dashed />
            <Row className={style["sider-item"]} style={{ marginTop: 12 }}>
              <Col flex="auto">
                <div className={style["title-money"]} style={{ marginBottom: 12, fontSize: 12 }}>
                  {i18n.t(languageKeys.field_Ly_do_hoan_tien)} <span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item
                  style={{ margin: 0 }}
                  name="LY_DO_HOAN_TIEN"
                  rules={[
                    {
                      required: true,
                      message: " ",
                    },
                  ]}
                >
                  <Input.TextArea className={style["select-custom-refund"]} placeholder={i18n.t(languageKeys.common_Nhap)} size="medium" />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ margin: "8px 0" }} dashed />
            <Row style={{ paddingBottom: 12 }} className={style["sider-item"]}>
              <Col span={24}>
                <div className={style["title-money"]} style={{ marginBottom: 12, fontSize: 12 }}>
                  {i18n.t(languageKeys.field_hinh_thuc_thanh_toan)} <span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item
                  name="HINH_THUC_THANH_TOAN"
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.vui_long_nhap),
                    },
                  ]}
                >
                  <Select
                    className={style["select-custom"]}
                    valueKey="ID"
                    titleKey="TEN_HINH_THUC_THANH_TOAN"
                    dataSource={paymentMethod.filter(i => ![paymentMethodProto.TRA_GOP, paymentMethodProto.CONG_NO, paymentMethodProto.TIEN_DU, paymentMethodProto.TAM_UNG]
                      .some(txt => i?.PHAN_LOAI_HINH_THUC?.includes?.(txt)))}
                    onSelect={(value) => form.setFieldValue('HINH_THUC_THANH_TOAN', value)}
                  />
                </Form.Item>
              </Col>
            </Row>
            <Divider style={{ margin: "8px 0" }} dashed />
            <Row style={{ paddingBottom: 12 }} className={style["sider-item"]}>
              <Col span={24}>
                <div className={style["title-money"]} style={{ marginBottom: 12, fontSize: 12 }}>
                  {i18n.t(languageKeys.so_hoan_tien)} <span style={{ color: "red" }}>*</span>
                </div>
                <Form.Item
                  style={{ margin: 0 }}
                  name="SO_HOADON_ID"
                  rules={[
                    {
                      required: true,
                      message: i18n.t(languageKeys.vienphi_chon_so_hoan_tien),
                    },
                  ]}
                >
                  <Select
                    className={style["select-custom"]}
                    dataSource={refundBook}
                    titleKey="TEN_SO"
                    valueKey="ID"
                    onAdd={onThemSo && isAddRefundBookAllowed}
                    onClickAdd={() => onThemSo && onThemSo("SO_HOAN_TIEN")}
                    textAdd={i18n.t(languageKeys.common_Them_moi)}
                    iconAdd={onThemSo && <PlusCircleFilled />}
                    onSelect={(id) => localStorage.setItem(keys.id_sht, id)}
                    showSearch
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Layout.Sider>

        <ModalHoanTien
          ref={refundRef}
          serviceList={dataSource}
          patientInfo={patientInfo}
          form={form}
          handleHoanTien={onHoanTien}
          onPrint={onPrint}
          infoGoiDv={infoGoiDv}
        />

        <ConfirmModal
          ref={confirmRef}
          content={""}
          danger
          onOk={handleCancelPaid}
          onCancel={() => form.resetFields(['LY_DO_HUY'])}
          onPressCancelSuccess={() => form.resetFields(['LY_DO_HUY'])}
        >
          <div className={style.cancelRes}>
            <p>{i18n.t(languageKeys.confirm_cancel_ticket)}</p>
            <Form form={form} layout="vertical">
              <Form.Item
                label={i18n.t(languageKeys.ly_do_huy_phieu)}
                name="LY_DO_HUY"
                rules={[{ required: true, whitespace: true, message: "" }]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Form>
          </div>
        </ConfirmModal>

        <ModalSuaPhieu
          ref={editRef}
          patientInfo={patientInfo}
          onPrint={onPrint}
          layDsDichDaThanhToan={layDsDichDaThanhToan}
        />

      </>
    );
  }
);
export default DichvuDaThanhToan;
