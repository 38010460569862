import { PlusCircleOutlined } from "@ant-design/icons";
import { Col, Divider, Row, Spin, TreeSelect, Button } from "antd";
import select from "./selectTree.module.less";
import { Triangle } from "../../../../../assets/svg";
import { rid } from "../../../../../helpers";
import i18n, { languageKeys } from "../../../../../i18n";

/* Nếu muốn hiển thị thanh tìm kiếm thì hàm onSearch() phải được truyền giá trị vào, tương tự với thanh thêm mới cùng hàm onAdd() */

const SelectTree = ({
  dataSource = [], // mảng danh sách các lựa chọn (dữ liệu trong mảng chưa được JSON.stringify)
  titleKey, // prop của option (là 1 object) dùng để hiển thị trên giao diện màn hình
  valueKey, // prop của option (là 1 object) dùng để lưu thành value của Select.Option
  childrenKey, // prop của option (là 1 object) dùng để render nhóm con
  onSearch, // hàm xử lý search data
  onAdd, // hàm xử lý add data mới
  loading = false, // spin quay vòng khi load data vừa search
  className,
  showSearch = false,
  disabled = false,
  onSelect,
  onChange,
  customTitle,
  selectedList = [],
  setSelectedList = () => {},
  showTable = false,
  columnsTable = [],
  isOnlyValue = false,
  placeholder = i18n.t(languageKeys.common_Chon),
  filterOption = false,
  iconAdd,
  textAdd,
  ...props
}) => {
  /* =====Custom component Dropdown khi bấm vào Select hoặc TreeSelect===== */
  const dropdownRender = (menu) => {
    return (
      <>
        {showTable && (
          <Row className={select["table-head"]}>
            {columnsTable.map((item) => (
              <Col key={rid()} span={8}>
                {item.title}
              </Col>
            ))}
          </Row>
        )}

        {/* =====Danh sách các options===== */}
        {/* Note: Với mỗi loại Select hoặc TreeSelect, menu sẽ được hiển thị ra khác nhau */}
        <Spin spinning={loading}>{menu}</Spin>

        {/* =====Nếu có hàm onAdd() thì mới hiển thị nút thêm mới===== */}
        {!!onAdd && (
          <>
            <Divider style={{ margin: "4px 0 0 0" }} />

            {/* =====Nút thêm mới===== */}
            <div className={select["add"]} onClick={() => onAdd()}>
              {iconAdd ? (
                iconAdd
              ) : (
                <PlusCircleOutlined className={select["icon"]} />
              )}
              {textAdd ? textAdd : i18n.t(languageKeys.common_Them_moi)}
            </div>
          </>
        )}
      </>
    );
  };

  // Render Tree cha và con
  const renderTreeNodes = (data) => {
    return data.map((item) => {
      if ( item[childrenKey] && item[childrenKey].length > 0) {
        return (
          <TreeSelect.TreeNode
            key={
              !!valueKey
                ? item[valueKey]
                : typeof item === "object"
                ? JSON.stringify(item)
                : item
            }
            value={
              !!valueKey
                ? item[valueKey]
                : typeof item === "object"
                ? JSON.stringify(item)
                : item
            }
            title={
              !!customTitle
                ? customTitle(item)
                : !!titleKey
                ? item[titleKey]
                : item
            }
          >
            {renderTreeNodes(item[childrenKey])}
          </TreeSelect.TreeNode>
        );
      }
      return (
        <TreeSelect.TreeNode
          key={
            !!valueKey
              ? item[valueKey]
              : typeof item === "object"
              ? JSON.stringify(item)
              : item
          }
          value={
            !!valueKey
              ? item[valueKey]
              : typeof item === "object"
              ? JSON.stringify(item)
              : item
          }
          title={
            !!customTitle
              ? customTitle(item)
              : !!titleKey
              ? item[titleKey]
              : item
          }
        />
      );
    });
  };

  return (
    <TreeSelect
      {...props}
      className={select["container-mul"]}
      dropdownClassName={select["dropdown"]}
      allowClear
      showArrow
      dropdownRender={dropdownRender}
      disabled={disabled}
      showSearch={showSearch}
      onSearch={onSearch}
      placeholder={placeholder}
      switcherIcon={<Triangle />}
      loading={loading}
      onSelect={onSelect}
      onChange = {onChange}
      dropdownStyle={{ position: "fixed" }}
    >
      {renderTreeNodes(dataSource)}
    </TreeSelect>
  );
};

export default SelectTree;
