import { AppStore, GoogleStore, PksQR } from "assets/img";
import style from "./app.module.less";

const AppQR = () => {
  return (
    <main className={style.main}>
      <section className={style.section}>
        <div className={style.link}>
          <img src={PksQR} alt="QR" className={style.qr} />
          <div className={style.download}>
            <img
              src={AppStore}
              alt="apple"
              className={style.store}
              onClick={() => window.location.href = `https://apps.apple.com/vn/app/ph%C3%B2ng-kh%C3%A1m-s%E1%BB%91/id6473767122?l=vi`}
            />
            <img
              src={GoogleStore}
              alt="google"
              className={style.store}
              onClick={() => window.location.href = `https://play.google.com/store/apps/details?id=com.h247.mobicare&hl=vi-VN`}
            />
          </div>
        </div>
        <div className={style.text}>
          <h1>Tải ứng dụng Phòng Khám Số để chăm sóc sức khỏe của bạn và những người thân yêu mọi nơi, mọi lúc!</h1>
          <p>Quét mã QR ngay để:</p>
          <p>- Quản lý Hồ sơ sức khỏe</p>
          <p>- Hỏi đáp chuyên gia y tế</p>
          <p>- Đặt hẹn lịch khám</p>
          <p>- Nhắc nhở đơn thuốc</p>
          <p>- Gợi ý các hoạt động chăm sóc sức khỏe</p>
          <p>- Bác sĩ gia đình</p>
        </div>
      </section>
    </main>
  )
}

export default AppQR