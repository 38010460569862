import moment from "moment";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { apis } from "../../../constants";
import Constants from "../../../constants/Constants";
import { common_post, HLog, rid } from "../../../helpers";
import { _layDSBenhNhanChoKham } from "../../QuanLyKhamBenh/apisKhamBenh/apisSiderBar";
import style from "./tmedical.module.less";

const TYPE_CHOKHAM = "CHO_KHAM"; //Type ds bệnh nhân chờ khám

var _eventSource = null;

export const Tmedical = ({
  room_id_1,
  room_id_2,
  room_id_3,
  room_id_4,
  hospital_id,
  partner_code,
  payment_required = false,
}) => {
  const [room1, setRoom1] = useState("");
  const [room2, setRoom2] = useState("");
  const [room3, setRoom3] = useState("");
  const [room4, setRoom4] = useState("");
  const [dataSource1, setDataSource1] = useState([]);
  const [dataSource2, setDataSource2] = useState([]);
  const [dataSource3, setDataSource3] = useState([]);
  const [dataSource4, setDataSource4] = useState([]);
  const eventData = useSelector((state) => state.eventSlice.event);

  useEffect(() => {
    if (!!eventData.type) { //event source liên quan đến reload lượt điều trị
      HLog("Tmedical useEffect eventData: ", eventData);
      // nếu yêu cầu bắt buộc phải thanh toán trước khi được vào khám
      if (!!payment_required) {
        // nếu có lượt điều trị được thanh toán thì reload
        if (eventData.type === Constants.EVENT_THANH_TOAN_LUOT_DIEU_TRI) {
          getListAndInitEventSource();
        }
      } else {
        // nếu bệnh viện ko bắt buộc thanh toán trước khám => reload lại danh sách khám bệnh
        if (eventData.type === Constants.EVENT_TIEP_DON_LUOT_DIEU_TRI) {
          getListAndInitEventSource();
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventData]);

  const getListAndInitEventSource = () => {
    if (!!room_id_1) {
      layDsBenhNhanChoKham(room_id_1, setDataSource1);
      initEventSource(room_id_1, setDataSource1);
    }

    if (!!room_id_2) {
      layDsBenhNhanChoKham(room_id_2, setDataSource2, setRoom2);
      initEventSource(room_id_2, setDataSource2);
    }

    if (!!room_id_3) {
      layDsBenhNhanChoKham(room_id_3, setDataSource3, setRoom3);
      initEventSource(room_id_3, setDataSource3);
    }

    if (!!room_id_4) {
      layDsBenhNhanChoKham(room_id_4, setDataSource4, setRoom4);
      initEventSource(room_id_4, setDataSource4);
    }
  };

  useEffect(() => {
    getListAndInitEventSource();

    if (!!room_id_1) layThongTinPhong(room_id_1, setRoom1);
    if (!!room_id_2) layThongTinPhong(room_id_2, setRoom2);
    if (!!room_id_3) layThongTinPhong(room_id_3, setRoom3);
    if (!!room_id_4) layThongTinPhong(room_id_4, setRoom4);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layDsBenhNhanChoKham = async (id, callback = () => {}) => {
    if (!id || id === "") return;

    let dataRequest = {
      partner_code: partner_code,
      BENH_VIEN_ID: hospital_id,
      NGAY: moment().format("YYYYMMDD"),
      PHONG_ID: id,
      LOAI: TYPE_CHOKHAM,
      page: 1,
    };

    let res = await _layDSBenhNhanChoKham(dataRequest);

    if (res && res.status === "OK") {
      callback(res.result);
    } else {
      callback([]);
    }
  };

  //event source liên quan đến reload lượt điều trị
  const initEventSource = (id, callback = () => {}) => {
    let urlEvent =
      apis.event_reload_kham_benh +
      "?BENH_VIEN_ID=" +
      hospital_id +
      "&&partner_code=" +
      partner_code +
      "&&PHONG_ID=" +
      id;

    HLog("Tmedical initEventSource urlEvent: " + urlEvent);

    _eventSource = new EventSource(urlEvent);
    _eventSource.onmessage = (e) => {
      HLog("Tmedical initEventSource onmessage: ", JSON.parse(e.data));
    };

    _eventSource.addEventListener("responseReloadCIS", (event) => {
      HLog(
        "Tmedical initEventSource addEventListener update:",
        JSON.parse(event.data)
      );

      if (event.data && event.data !== "") {
        let dataEvent = JSON.parse(event.data);
        let { type } = dataEvent;

        if (
          type === Constants.EVENT_THANH_TOAN_LUOT_DIEU_TRI ||
          type === Constants.EVENT_LUOT_DIEU_TRI_VAO_KHAM ||
          type === Constants.EVENT_LUOT_DIEU_TRI_KHAM_XONG
        ) {
          layDsBenhNhanChoKham(id, callback);
        }
      }
    });
  };

  const layThongTinPhong = async (ID, callback = () => {}) => {
    try {
      const res = await common_post(apis.lay_chi_tiet_phong_PUBLIC_API, {
        partner_code,
        ID,
      });
      if (res.status === "OK") {
        callback(res.result.TEN_KHOA_PHONG);
      }
    } catch (error) {}
  };

  return (
    <div className={style["container"]}>
      <div className={style["room"]}>
        <div className={style["title"]}>
          <h1>{room1}</h1>
        </div>

        <div className={style["content"]}>
          {dataSource1.slice(0, 3).map((item) => (
            <p key={rid()}>
              <span>{item.STT_VAO_KHAM}</span> - {item.TEN}
            </p>
          ))}
        </div>
      </div>

      <div className={style["room"]}>
        <div className={style["title"]}>
          <h1>{room2}</h1>
        </div>

        <div className={style["content"]}>
          {dataSource2.slice(0, 3).map((item) => (
            <p key={rid()}>
              <span>{item.STT_VAO_KHAM}</span> - {item.TEN}
            </p>
          ))}
        </div>
      </div>

      <div className={style["room"]}>
        <div className={style["title"]}>
          <h1>{room3}</h1>
        </div>

        <div className={style["content"]}>
          {dataSource3.slice(0, 3).map((item) => (
            <p key={rid()}>
              <span>{item.STT_VAO_KHAM}</span> - {item.TEN}
            </p>
          ))}
        </div>
      </div>

      <div className={style["room"]}>
        <div className={style["title"]}>
          <h1>{room4}</h1>
        </div>

        <div className={style["content"]}>
          {dataSource4.slice(0, 3).map((item) => (
            <p key={rid()}>
              <span>{item.STT_VAO_KHAM}</span> - {item.TEN}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
};
