import i18n, { languageKeys } from "../../i18n";

export const fieldsCaiDatTiepDon = {
  // thông tin hành chính
  ten_benh_nhan: "TEN",
  ho_benh_nhan: "HO",
  so_dien_thoai: "SO_DIEN_THOAI",
  email: "EMAIL",
  gioi_tinh: "GIOI_TINH",
  ngay_sinh: "NGAY_SINH",
  t_h_x: "MA_KHU_VUC",
  dia_chi_chi_tiet: "DIA_CHI_CHI_TIET",
  tinh_thanh: "TEN_TINH_THANH",
  quan_huyen: "TEN_QUAN_HUYEN",
  xa_phuong: "TEN_PHUONG_XA",
  ma_tinh_thanh: "MA_TINH_THANH",
  ma_quan_huyen: "MA_QUAN_HUYEN",
  ma_xa_phuong: "MA_PHUONG_XA",
  nghe_nghiep: "NGHE_NGHIEP",
  dan_toc: "DAN_TOC",
  quoc_gia: "QUOC_TICH",
  ma_nghe_nghiep: "MA_NGHE_NGHIEP",
  ma_dan_toc: "MA_DAN_TOC",
  ma_quoc_gia: "MA_QUOC_TICH",
  cccd: "CCCD",

  // thông tin đăng ký khám
  lich_kham_id: "LICH_KHAM_ID",
  doi_tuong_benh_nhan: "DOI_TUONG_BENH_NHAN",
  uu_tien: "UU_TIEN",
  ma_benh_nhan: "BENH_NHAN_ID",
  thong_tin_kham: "list_luot_dieu_tri",
  yeu_cau_kham: "DICH_VU_ID",
  phong_kham: "PHONG_ID",
  khoa_id: "KHOA_ID",
  gia_dich_vu: "GIA_DICH_VU",

  // backend chưa code phần này
  te_co_the_bhyt: "te_co_the_bhyt",
  the_bhyt: "the_bhyt",
  ma_cskcb: "ma_cskcb",
  ten_cskcb: "ten_cskcb",
  tuyen: "tuyen",
  han_the_tu: "han_the_tu",
  han_the_den: "han_the_den",
  ngay_mien_cung_chi_tra: "ngay_mien_cung_chi_tra",
  ngay_dong_han_du_5_nam: "ngay_dong_han_du_5_nam",
  noi_song: "noi_song",
  dia_chi_the: "dia_chi_the",
};

export const fieldTiepDon = {
  // thông tin hành chính
  ten_benh_nhan: "TEN",
  ho_benh_nhan: "HO",
  so_dien_thoai: "SO_DIEN_THOAI",
  email: "EMAIL",
  gioi_tinh: "GIOI_TINH",
  ngay_sinh: "NGAY_SINH",
  t_h_x: "MA_KHU_VUC",
  dia_chi_chi_tiet: "DIA_CHI_CHI_TIET",
  tinh_thanh: "TEN_TINH_THANH",
  quan_huyen: "TEN_QUAN_HUYEN",
  xa_phuong: "TEN_PHUONG_XA",
  ma_tinh_thanh: "MA_TINH_THANH",
  ma_quan_huyen: "MA_QUAN_HUYEN",
  ma_xa_phuong: "MA_PHUONG_XA",
  nghe_nghiep: "NGHE_NGHIEP",
  dan_toc: "DAN_TOC",
  quoc_gia: "QUOC_TICH",
  ma_nghe_nghiep: "MA_NGHE_NGHIEP",
  ma_dan_toc: "MA_DAN_TOC",
  ma_quoc_gia: "MA_QUOC_TICH",
  cccd: "CCCD",

  // thông tin đăng ký khám
  lich_kham_id: "LICH_KHAM_ID",
  doi_tuong_benh_nhan: "DOI_TUONG_BENH_NHAN",
  uu_tien: "UU_TIEN",
  ma_benh_nhan: "MA_BENH_NHAN",
  benh_nhan_id: "BENH_NHAN_ID",
  thong_tin_kham: "list_luot_dieu_tri",
  yeu_cau_kham: "DICH_VU_ID",
  phong_kham: "PHONG_ID",
  khoa_id: "KHOA_ID",
  gia_dich_vu: "GIA_DICH_VU",
  bang_lai_xe: "BANG_LAI_XE",
  ly_do_kham: "MO_TA",

  // backend chưa code phần này
  te_co_the_bhyt: "te_co_the_bhyt",
  the_bhyt: "the_bhyt",
  ma_cskcb: "ma_cskcb",
  ten_cskcb: "ten_cskcb",
  tuyen: "tuyen",
  han_the_tu: "han_the_tu",
  han_the_den: "han_the_den",
  ngay_mien_cung_chi_tra: "ngay_mien_cung_chi_tra",
  ngay_dong_han_du_5_nam: "ngay_dong_han_du_5_nam",
  noi_song: "noi_song",
  dia_chi_the: "dia_chi_the",

  nguon_khach:"NGUON_KHACH",
  TEN_NGUOI_THAN:"TEN_NGUOI_THAN",
  SDT_NGUOI_THAN:"SDT_NGUOI_THAN",
  QUAN_HE_NGUOI_THAN:"QUAN_HE_NGUOI_THAN",
  DIA_CHI_NGUOI_THAN:"DIA_CHI_NGUOI_THAN",
  DM_DOI_TUONG_ID:"DM_DOI_TUONG_ID",
  DM_NGUON_KHACH_ID:"DM_NGUON_KHACH_ID",
  NGUON_KHACH_ID:"NGUON_KHACH_ID",
  TIEP_TAN_ID:"TIEP_TAN_ID",
  NGUOI_HIEN_THI: "NGUOI_HIEN_THI",
  NHAN_VIEN_TU_VAN_ID: "NHAN_VIEN_TU_VAN_ID",
  TEN_NHAN_VIEN_TU_VAN: "TEN_NHAN_VIEN_TU_VAN",
};

export const fieldsDsTiepDon = {
  stt: "STT_DAT_KHAM",
  benh_nhan: "TEN_BENH_NHAN",
  dich_vu_su_dung: "TEN_DICH_VU",
  phong_thuc_hien: "TEN_PHONG",
  thanh_toan: "THANH_TOAN",
  trang_thai: "TRANG_THAI",
};

export const fieldsChonPhongKham = {
  ten_phong: "TEN_KHOA_PHONG",
  so_phong: "MA_PHONG",
  benh_nhan_cho_kham: "SO_CHO_KHAM",
};

export const fieldsTimKiemBenhNhan = {
  ma_benh_nhan: "MA_BENH_NHAN",
  ten_benh_nhan: "TEN",
  ngay_sinh: "NGAY_SINH",
  gioi_tinh: "GIOI_TINH",
  so_dien_thoai: "SO_DIEN_THOAI",
  ma_cccd: "CCCD",
  ngay_kham: "NGAY_TIEP_DON",
  gio_kham: "GIO_TIEP_DON",
};

export const lsDoiTuong = [
  {
    name: i18n.t(languageKeys.option_Tat_ca),
    ma_dt: "",
  },
  {
    name: i18n.t(languageKeys.field_doi_tuong_vien_phi),
    ma_dt: "VIEN_PHI",
  },
  {
    name: i18n.t(languageKeys.field_doi_tuong_nuoc_ngoai),
    ma_dt: "NUOC_NGOAI",
  },
  {
    name: i18n.t(languageKeys.field_doi_tuong_nuoc_ngoai),
    ma_dt: "NUOC_NGOAI",
  },
];

export const fieldTiepDonReset = {
    // thông tin hành chính
    ten_benh_nhan: "TEN",
    ho_benh_nhan: "HO",
    so_dien_thoai: "SO_DIEN_THOAI",
    email: "EMAIL",
    gioi_tinh: "GIOI_TINH",
    ngay_sinh: "NGAY_SINH",
    t_h_x: "MA_KHU_VUC",
    dia_chi_chi_tiet: "DIA_CHI_CHI_TIET",
    tinh_thanh: "TEN_TINH_THANH",
    quan_huyen: "TEN_QUAN_HUYEN",
    xa_phuong: "TEN_PHUONG_XA",
    ma_tinh_thanh: "MA_TINH_THANH",
    ma_quan_huyen: "MA_QUAN_HUYEN",
    ma_xa_phuong: "MA_PHUONG_XA",
    nghe_nghiep: "NGHE_NGHIEP",
    dan_toc: "DAN_TOC",
    quoc_gia: "QUOC_TICH",
    ma_nghe_nghiep: "MA_NGHE_NGHIEP",
    ma_dan_toc: "MA_DAN_TOC",
    ma_quoc_gia: "MA_QUOC_TICH",
    cccd: "CCCD",
  
    // thông tin đăng ký khám
    ma_benh_nhan: "MA_BENH_NHAN",
    nguon_khach:"NGUON_KHACH",
    TEN_NGUOI_THAN:"TEN_NGUOI_THAN",
    SDT_NGUOI_THAN:"SDT_NGUOI_THAN",
    QUAN_HE_NGUOI_THAN:"QUAN_HE_NGUOI_THAN",
    DIA_CHI_NGUOI_THAN:"DIA_CHI_NGUOI_THAN",
    DM_DOI_TUONG_ID:"DM_DOI_TUONG_ID",
    DM_NGUON_KHACH_ID:"DM_NGUON_KHACH_ID",
  
    ly_do_kham: "MO_TA",
}