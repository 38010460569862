import { apis, keys } from "../../../../constants";
import { HLog, common_post } from "../../../../helpers";

export const apiLayDmCachThucThuThuat = async (payload = {}) => {
  try {
    const requestData = {
      ...payload,
      limit: keys.limit,
    };
    const response = await common_post(apis.dm_thuthuat_lay_ds_cttt, requestData);

    return response;
  } catch (error) {
    HLog("Api lay ds dm_thuthuat_them_cttt error", error);
  }
};

export const apiThemDMCTTT = async (payload = {}) => {
  try {
    const requestData = {
      ...payload,
      data: [payload.data],
    };
    const response = await common_post(apis.dm_thuthuat_them_cttt, requestData);
    return response;
  } catch (error) {
    HLog("Api lay ds dm_thuthuat_them_cttt error", error);
  }
};

export const apiSuaDmCachThucThuThuat = async ({ data, ...params }) => {
  const { BENH_VIEN_ID, partner_code, ...rest } = data;
  try {
    const requestData = {
      BENH_VIEN_ID,
      partner_code,
      data: [
        {
          ...rest,
          ID_CACHTHUC_THUTHUAT: rest.ID,
        },
      ],
    };
    return await common_post(apis.dm_thuthuat_sua_mot_cttt, requestData);
  } catch (error) {
    HLog("Api cap nhat dm_thuthuat_them_cttt error", error);
  }
};

export const apiXoaHoacSuaNhieuDmCachThucThuThuat = async ({ BENH_VIEN_ID, partner_code, lock: KHOA, data, ...params }) => {
  try {
    const listId = Array.isArray(data) ? data.map((item) => item.ID).filter((item) => item) : [data.ID];
    const requestData = {
      BENH_VIEN_ID,
      partner_code,
      data: [{ listId }],
    };
    if (KHOA !== undefined) {
      requestData.KHOA = KHOA ? 1 : 0;
    }
    return await common_post(apis.dm_thuthuat_xoa_cttt, requestData);
  } catch (error) {
    HLog("Api cap nhat dm_thuthuat_them_cttt error", error);
  }
};

// ========================

export const apiLayDmPPTT = async (payload = {}) => {
  try {
    const requestData = {
      ...payload,
      limit: keys.limit,
    };
    const response = await common_post(apis.dm_thuthuat_lay_ds_pptt, requestData);
    return response;
  } catch (error) {
    HLog("Api lay ds dm_thuthuat_lay_ds_pptt error", error);
  }
};

export const apiThemDMPPTT = async (payload = {}) => {
  try {
    const requestData = {
      ...payload,
      data: [payload.data],
    };
    const response = await common_post(apis.dm_thuthuat_them_pptt, requestData);
    return response;
  } catch (error) {
    HLog("Api lay ds dm_thuthuat_lay_ds_pptt error", error);
  }
};

export const apiSuaDmPPTT = async ({ data, ...params }) => {
  try {
    const { BENH_VIEN_ID, partner_code, ...rest } = data;
    const requestData = {
      BENH_VIEN_ID,
      partner_code,
      data: [
        {
          ...rest,
          ID_PHUONGPHAP_THUTHUAT: rest.ID,
        },
      ],
    };
    return await common_post(apis.dm_thuthuat_sua_mot_pptt, requestData);
  } catch (error) {
    HLog("Api cap nhat dm_thuthuat_lay_ds_pptt error", error);
  }
};

export const apiXoaHoacSuaNhieuDmPPTT = async ({ BENH_VIEN_ID, partner_code, lock: KHOA, data, ...params }) => {
  try {
    const listId = Array.isArray(data) ? data.map((item) => item.ID).filter((item) => item) : [data.ID];
    const requestData = {
      BENH_VIEN_ID,
      partner_code,
      data: [{ listId }],
    };
    if (KHOA !== undefined) {
      requestData.KHOA = KHOA ? 1 : 0;
    }
    return await common_post(apis.dm_thuthuat_xoa_pptt, requestData);
  } catch (error) {
    HLog("Api cap nhat dm_thuthuat_lay_ds_pptt error", error);
  }
};
