import React, { useState } from "react";
import { Button, Col, Row, Typography, Select as SelectAntd, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FormDrawer } from "../../components";
import { onVisibleAccManagment } from "../../ducks/slices/titlePageSlice";
import { memo } from "react";
import { widthDrawerKeys } from "../../constants/keys";
import i18n, { languageKeys } from "../../i18n";
import style from "./style.module.less";
import { Form } from "antd";
import { AvatarUploader } from "../QuanLyHeThong/QuanLyNguoiDung/CaiDatNguoiDung/components/AvatarUploader";
import { Input, Select } from "../../components_v2";
import Icon from "@ant-design/icons/lib/components/Icon";
import { IconAddImage } from "../../assets/svg";
import { chuc_danh, hoc_ham } from "../../constants/data";
import { useEffect } from "react";
import { HLog, rid, uploadImageBase64 } from "../../helpers";
import ForgotPass from "./ForgotPass";
import { useRef } from "react";
import { updateUser } from "../QuanLyHeThong/QuanLyNguoiDung/apisUser";
import { updateUser as updateUserProfile } from "../../ducks/slices/authSlice";
import moment from "moment";

const fieldUserKey = {
  USERNAME: "USERNAME",
  HO: "HO",
  TEN: "TEN",
  EMAIL: "EMAIL",
  SDT: "SDT",
  CHUC_DANH: "CHUC_DANH",
  HOC_HAM: "HOC_HAM",
  MA_CCHN: "MA_CCHN",
};

const ruleRequired = { required:true,message:i18n.t(languageKeys.vui_long_nhap) }

const CisAccountManagment = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const forgotRef = useRef();
  
  const visibleAccount = useSelector((state) => state.titlePage.accountManagmentVisible);
  const userProfile = useSelector((state) => state.auth.user);

  const [dataAvatar, setDataAvatar] = useState();
  const [urlPreviewAvatar, setUrlPreviewAvatar] = useState();
  const [loading, setLoading] = useState(false);

  const handleCancel = () => {
    dispatch(onVisibleAccManagment(false));
    setDataAvatar();
    setUrlPreviewAvatar();
    form.resetFields();
    setLoading(false);
  };
HLog(userProfile)
  const onFinish = async (values) => {
    setLoading(true);
    try {
      let urlAvatar = userProfile.ANH_DAI_DIEN;
      if (dataAvatar) {
        let ID = !!userProfile ? userProfile.NHANSU_ID : rid(8);
        let response = await uploadImageBase64(dataAvatar, ID, userProfile.partner_code);
        if (response) {
          urlAvatar = response;
        }
      }

      let newValue = {
        ...values,
        ANH_DAI_DIEN: urlAvatar,
        partner_code: userProfile.partner_code,
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      };

      const thongtinNguoiSua = {
        USERNAME_NGUOISUA:userProfile.USERNAME,
        TEN_NGUOI_SUA:`${userProfile.HO} ${userProfile.TEN}`,
        GIO_SUA:moment().format("HH:mm"),
        NGAY_SUA:moment().format("YYYYMMDD"),
      }
      
      let body = { ...newValue, NHANSU_ID: userProfile.NHANSU_ID, BENH_VIEN_ID: userProfile.BENH_VIEN_ID, NGUOI_SUA: userProfile.NHANSU_ID, ...thongtinNguoiSua }
      HLog(body)
      let res = await updateUser(body);
      if(res && res.status === "OK") {
        notification.success({placement:"bottomLeft",message:i18n.t(languageKeys.thao_tac_thanh_cong)});
        dispatch(updateUserProfile({...userProfile,...body,CHUC_DANH:body.CHUC_DANH ? JSON.stringify(body.CHUC_DANH) : "",HOC_HAM:body.HOC_HAM ? JSON.stringify(body.HOC_HAM): ""}));
        handleCancel();
      } else {
        notification.error({placement:"bottomLeft",message:i18n.t(languageKeys.thao_tac_that_bai)})
      }
    } catch (error) {
      HLog(error)
    }
    setLoading(false);
  };

  const setDataJson = (data) => {
    let result = undefined;
    try {
      if (data && typeof data === "string") {
        if (data.length) {
          return JSON.parse(data);
        }
      }
      return result;
    } catch (error) {}
  };

  useEffect(() => {
    try {
      if (visibleAccount) {

          if(userProfile.ANH_DAI_DIEN) {
            setUrlPreviewAvatar(userProfile.ANH_DAI_DIEN)
          }

        let fieldData = [];
        Object.keys(fieldUserKey).forEach((item) => {
          if ([fieldUserKey.HOC_HAM, fieldUserKey.CHUC_DANH].includes(item)) {
            fieldData.push({
              name: item,
              value: setDataJson(userProfile[item],item),
            });
          } else {
            fieldData.push({
              name: item,
              value: userProfile[item],
            });
          }
        });
        form.setFields(fieldData);
      }
    } catch (error) {
      HLog(error);
    }
  }, [visibleAccount]);

  const handleOpenModalPass = () => {
    forgotRef.current.open();
  };

  return (
    <FormDrawer
      visible={visibleAccount}
      onCancel={handleCancel}
      width={widthDrawerKeys.small}
      hiddenTopAction={true}
      // onOk={()=> form.submit()}
      loading={loading}
      title={i18n.t(languageKeys.quan_ly_tai_khoan)}
      footer={
        <Row justify="end" gutter={10}>
          {/*=========RIGHT=============*/}
          <Col>
            <Button type="primary" onClick={handleCancel} ghost>
              {i18n.t(languageKeys.common_Thoat) + " (ESC)"}
            </Button>
          </Col>
          <Col>
            <Button type="primary" onClick={() =>  form.submit()}>
              {i18n.t(languageKeys.luu) + " (Ctrl + S)"}
            </Button>
          </Col>
        </Row>
      }
      zIndex={10000}
    >
      <Form form={form} layout="vertical" className={style["form-container"]} onFinish={onFinish}>
        <Row gutter={[10, 5]}>
          <Col span={24}>
            <div className={style["avatar-upload"]}>
              <AvatarUploader showAddIcon={false} setData={setDataAvatar} urlPreview={urlPreviewAvatar} setUrlPreview={setUrlPreviewAvatar} />
              <Icon component={IconAddImage} className={style["icon-add-img"]} />
            </div>
          </Col>

            <React.Fragment>
              <Col span={24}>
                <Typography.Title level={3}>{i18n.t(languageKeys.thong_tin_tai_khoan)}</Typography.Title>
              </Col>
              <Col span={10}>
                <Form.Item label={i18n.t(languageKeys.field_Ten_dang_nhap)} name={fieldUserKey.USERNAME}>
                  <Input disabled={true} />
                </Form.Item>
              </Col>

              <Col span={6}>
                <Form.Item label={" "}>
                  <Button type="primary" block onClick={handleOpenModalPass}>
                    {i18n.t(languageKeys.doi_mat_khau)}
                  </Button>
                </Form.Item>
              </Col>
              <Col span={8}></Col>
            </React.Fragment>


          <Col span={4}>
            <Form.Item label={i18n.t(languageKeys.field_ho)} name={fieldUserKey.HO} rules={[ruleRequired]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item label={i18n.t(languageKeys.field_ten_ten_dem)} name={fieldUserKey.TEN}  rules={[ruleRequired]}>
              <Input />
            </Form.Item>
          </Col>
          <Col span={14}>
            <Form.Item label={i18n.t(languageKeys.field_Email)} name={fieldUserKey.EMAIL}
              rules={[
                ruleRequired,
                {
                  pattern: /\S+@\S+\.\S+/,
                  message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={i18n.t(languageKeys.field_So_dien_thoai)} name={fieldUserKey.SDT}
            rules={[
              ruleRequired,
              {
                pattern: /^[0-9]*$/,
                message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
              },
            ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={i18n.t(languageKeys.field_chuc_danh)} name={fieldUserKey.CHUC_DANH}>
              <Select dataSource={chuc_danh} labelKey="name" valueKey="key"/>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={i18n.t(languageKeys.field_hoc_ham)} name={fieldUserKey.HOC_HAM}>

              <SelectAntd
                placeholder={i18n.t(languageKeys.common_Chon)}
                mode="multiple"
                maxTagCount={1}
                popupClassName={style["dropdown-mul-select"]}
                className={style["mul-select"]}
                allowClear
                showSearch={false}
              >
                {hoc_ham.map((item) => (
                  <SelectAntd.Option key={item.key} value={item.key}>
                    {item.name}
                  </SelectAntd.Option>
                ))}
              </SelectAntd>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item label={i18n.t(languageKeys.field_ma_cc_hanh_nghe)} name={fieldUserKey.MA_CCHN}>
              <Input />
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <ForgotPass ref={forgotRef} />
    </FormDrawer>
  );
};

export default memo(CisAccountManagment);

// className={style["button"]}
