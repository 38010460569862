import { apis, keys } from "../../../../constants";
import {
  common_post,
  HLog
} from "../../../../helpers";

export const apiLayDsXuatKhac = async ({
  BENH_VIEN_ID,
  search_string,
  page,
  partner_code,
  limit = keys.limit,
}) => {
  let dataRequest = { BENH_VIEN_ID, search_string, page, partner_code, limit };
  try {
    let response = await common_post(apis.dm_lay_danh_sach_ly_do_xuat_huy_phieu_thuoc, dataRequest);
    if (response && response.status === "OK") {
      HLog("Api lay danh sach xuat khac", response);
      return response;
    }
    return null;
  } catch (error) {
    HLog("Api lay danh sach xuat khac error", error);
  }
};

export const apiThemXuatKhac = async (dataReq) => {
  let dataRequest = {
    partner_code: dataReq.partner_code,
    BENH_VIEN_ID: dataReq.BENH_VIEN_ID,
    ...dataReq.data,
  };
  try {
    return await common_post(apis.dm_them_ly_do_xuat_huy_phieu_thuoc, dataRequest);
  } catch (error) {
    HLog("Api them xuat khac error", error);
  }
};

export const apiSuaXuatKhac = async (dataReq) => {
  try {
    let dataRequest = { 
      partner_code: dataReq.partner_code,
      BENH_VIEN_ID: dataReq.BENH_VIEN_ID,
      TRANG_THAI: 1,
      ...dataReq.data,
    };
    const response = await common_post(apis.dm_sua_ly_do_xuat_huy_phieu_thuoc, dataRequest);

    return response;
  } catch (error) {
    HLog("Api sua xuat khac error", error);
  }
};

export const apiXoaXuatKhac = async (dataReq) => {
  try {
    let dataRequest = { 
      partner_code: dataReq.partner_code,
      BENH_VIEN_ID: dataReq.BENH_VIEN_ID,
      TRANG_THAI: 0,
      arr_ID: [dataReq.data.ID]
    };
    const response = await common_post(apis.dm_xoa_ly_do_xuat_huy_phieu_thuoc, dataRequest);

    return response;
  } catch (error) {
    HLog("Api xoa xuat khac error", error);
  }
};