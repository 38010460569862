import { ConfirmModal, FormDrawer } from "components";
import i18n, { languageKeys } from "../../../../../i18n";
import React, { forwardRef, memo, useImperativeHandle, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Layout, Row } from "antd";
import { HLog, handleCheckPermissions } from "helpers";
import DichVuChuaThanhToan from "pages/QuanLyVienPhi/DichVuCanThanhToan/layouts/DichVuChuaThanhToan";
import { keyViews } from "pages/QuanLyVienPhi/DichVuCanThanhToan/DichVuCanThanhToan";
import style from "./modalThanhToan.module.less";
import { useEventListener, useFakeSyncronousState } from "hooks";
import ModalDebtList from "pages/QuanLyVienPhi/DichVuCanThanhToan/layouts/ModalDebtList";
import { featureKeys } from "constants/keys";
import ModalSoHoaDon from "pages/QuanLyVienPhi/SoHoaDon/ModalSoHoaDon/ModalSoHoaDon";
import FastSwitchButtons from "components_v2/FastSwitchButtons";
import { useHistory } from "react-router-dom";
import { paths } from "../../../../../constants";

const tabViews = {
  GOI: "THANH_TOAN_KHAM_GOI",
  THUONG: "THANH_TOAN_KHAM_BENH",
};
const ModalThanhToan = forwardRef(({ onAfterSubmit = () => { }, onPrint = () => { }, onClose = () => { }, showExtra, disabledExam, disabledSubclinical, isUseKeyboard=true }, ref) => {

  const userProfile = useSelector((state) => state.auth.user);
  const loading = useSelector((state) => state.snapShot.loading);

  const thanhToanRef = useRef();
  const modalDebtList = useRef()
  const createRef = useRef();

  // const forceRender = useState();
  // const [form] = Form.useForm();
  // const confirmRef = useRef();
  const {
    getState: afterSubmit,
    setState: setAfterSubmit,
  } = useFakeSyncronousState();
  const [open, setOpen] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState();
  const [tab, setTab] = useState(tabViews.THUONG);
  const [soCongNoId, setSoCongNoId] = useState()
  const [visibleDrawerSoHoaDon, setVisibleDrawerSoHoaDon] = useState(false);
  const [disabledCongNo, setDisabledCongNo] = useState(false)
  const [forceFinish, setForceFinish] = useState(false);
  // const [afterSubmit, setAfterSubmit] = useState()

  const checkRoleArr = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_dich_vu_can_thanh_toan)
  }, [userProfile])

  const checkRoleSoTH = useMemo(() => {
    return handleCheckPermissions(userProfile, featureKeys.quan_ly_so_hoa_don);
  }, [userProfile])

  const history = useHistory();

  useImperativeHandle(ref, () => {
    return {
      onOpen: handleOpen,
      onClose: handleClose,
    };
  });

  const handleOpen = (patient) => {
    setSelectedPatient(patient);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedPatient();
    setTab(tabViews.THUONG)
    setForceFinish(false);

    // if (afterSubmit() === 'clear') {
    //   onClose(afterSubmit() === 'clear' ? undefined : selectedPatient)
    // }
    if (afterSubmit() === 'goToExam') {
      history.push(`${paths.quan_ly_kham_benh}?ma_benh_nhan=${selectedPatient.BENH_NHAN_ID}&phong=${selectedPatient.PHONG_ID}&trang_thai=${languageKeys.status_Tat_ca}`)
    }
    else if (afterSubmit() === 'goToSubclinical') {
      history.push(`${paths.quan_ly_dv_chi_dinh}?ma_benh_nhan=${selectedPatient.BENH_NHAN_ID}&trang_thai=${languageKeys.status_Tat_ca}`)
    }
    else {
      onClose(afterSubmit() === 'clear' ? undefined : selectedPatient)
    }

    afterSubmit() && setAfterSubmit()
  };

  /**
   * 
   * @param {boolean} print in sau khi thanh toán
   * @param {boolean} forceFinish thanh toán và thực hiện hành động thêm sau đó ngay lập tức ( không đợi thanh toán hết dịch vụ )
   * @returns 
   */
  const handleSubmit = (print = false, forceFinish) => {
    try {
      setForceFinish(forceFinish)
      return thanhToanRef.current.onSubmit(print, forceFinish);
    } catch (error) {
      HLog(error.message);
    }
  };

  const reloadSoHoaDon = () => {
    setTimeout(() => {
      setVisibleDrawerSoHoaDon(false);
    }, 500);
    thanhToanRef.current?.reloadSo();
  };

  const onThemSo = (type = "SO_THU_TIEN") => {
    if (createRef.current) {
      createRef.current.open(null, type);
      setVisibleDrawerSoHoaDon(true);
    }
  };

  const keyDownEvent = (event) => {
    if (!isUseKeyboard) return
    const stopDefaultEvent = (e) => {
      e.stopPropagation();
      e.preventDefault();
    };

    if (event.ctrlKey && event.key === "l") {
      stopDefaultEvent(event);
      !loading && handleSubmit(true);
    } else if (event.ctrlKey && event.key === "s") {
      stopDefaultEvent(event);
      !loading && handleSubmit();
    } else if (event.ctrlKey && event.key === "b") {
      stopDefaultEvent(event);
      handleOpenModalDebtList()
    }
  };

  useEventListener("keydown", keyDownEvent, window.document, open);

  const patient = useMemo(() => {

    return selectedPatient
      ? {
        ...selectedPatient,
        LOAI: tab === tabViews.GOI ? "THANH_TOAN_MUA_GOI" : selectedPatient.GOI_DV_ID ? "THANH_TOAN_KHAM_GOI" : "THANH_TOAN_KHAM_BENH",
      }
      : {}
  }, [selectedPatient, tab]);

  const handleOpenModalDebtList = () => {
    thanhToanRef?.current?.resetFieldPaymentMethod()
    modalDebtList?.current?.open()
  }

  const handleOnOk = () => {
    thanhToanRef?.current?.handleSaveDebt()
    if (soCongNoId) {
      modalDebtList?.current?.close()
    }
  }

  const handleFastButton = (type) => {
    setAfterSubmit(type)
    handleSubmit(false, true)
  }

  return (
    <>
      <FormDrawer
        visible={open}
        onCancel={handleClose}
        title={i18n.t(languageKeys.vien_phi_dich_vu_can_thanh_toan)}
        hiddenTopAction
        width={"90vw"}
        className={style["drawer-title"]}
        footer={
          <Row gutter={10} justify={"end"}>
            <Col>
              <Button type="primary" ghost onClick={handleClose}>
                {i18n.t(languageKeys.common_Thoat)} (ESC)
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleOpenModalDebtList()} disabled={disabledCongNo}>
                {i18n.t(languageKeys.luu_cong_no)}{isUseKeyboard ?'(CTRL + B)':"" } 
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleSubmit(true)} disabled={loading}>
                {i18n.t(languageKeys.vien_phi_thanh_toan_va_in)} {isUseKeyboard ?'(CTRL + L)':"" } 
              </Button>
            </Col>
            <Col>
              <Button type="primary" onClick={() => handleSubmit()} disabled={loading}>
                {i18n.t(languageKeys.thanh_toan)} {isUseKeyboard ?'(CTRL + S)':"" } 
              </Button>
            </Col>
          </Row>
        }
        extra={showExtra && (
          <FastSwitchButtons
            loading={loading}
            handleRecep={() => handleFastButton('clear')}
            showRecep
            showExam
            handleGoToExam={() => handleFastButton('goToExam')}
            handleGoToSubclinical={() => handleFastButton('goToSubclinical')}
            disabledExam={disabledExam}
            disabledSubclinical={disabledSubclinical}
          />
        )}
      >
        <Row gutter={10} style={{ margin: 10 }}>
          <Col>
            <Button
              type={tab === tabViews.THUONG ? "primary" : ""}
              className={style["btn-tab-tt"]}
              size="small"
              onClick={(e) => setTab(tabViews.THUONG)}
            >
              {i18n.t(languageKeys.dich_vu)?.toUpperCase()}
            </Button>
          </Col>
          <Col>
            <Button
              type={tab === tabViews.GOI ? "primary" : ""}
              disabled={!selectedPatient}
              className={style["btn-tab-tt"]}
              size="small"
              onClick={(e) => setTab(tabViews.GOI)}
            >
              {i18n.t(languageKeys.goi_dich_vu)?.toUpperCase()}
            </Button>
          </Col>
        </Row>
        {open ? (
          <Layout key={"fgjfdk4"} style={{ height: `calc(100vh - 170px)`, maxWidth: "calc(90vw - 10px)" }}>
            <DichVuChuaThanhToan
              tabView={keyViews.CHUA_THANH_TOAN}
              currentPatient={patient}
              ref={thanhToanRef}
              onAfterSubmit={(data) => {
                handleClose();
                onAfterSubmit(data);
                setTimeout(() => {
                  setForceFinish(false);
                })
              }}
              onPrint={(ID, COND) => onPrint(ID, COND, patient)}
              hiddenHeader={tab === tabViews.THUONG}
              styleMode={"TIEP_DON"}
              soCongNoId={soCongNoId}
              isDeleteAllowed={checkRoleArr?.XOA_DICH_VU}
              isAddRevenueBookAllowed={checkRoleSoTH?.THEM_MOI}
              visibleDrawerSoHoaDon={visibleDrawerSoHoaDon}
              onThemSo={onThemSo}
              setDisabledCongNo={setDisabledCongNo}
              scrollY={260}
              forceFinish={forceFinish}
            />
          </Layout>
        ) : (
          <></>
        )}
      </FormDrawer>
      <ConfirmModal
        isCloseWhenOk={false}
        ref={modalDebtList}
        content=""
        title={i18n.t(languageKeys.so_cong_no)}
        className={style["modal-content"]}
        danger
        onOk={handleOnOk}
      >
        <ModalDebtList visible={modalDebtList?.current?.visible} setSoCongNoId={setSoCongNoId} soCongNoId={soCongNoId} />
      </ConfirmModal>
      <ModalSoHoaDon ref={createRef} doAfterSubmit={reloadSoHoaDon} setVisibleDrawerSoHoaDon={setVisibleDrawerSoHoaDon} />
    </>
  );
});

export default memo(ModalThanhToan);
