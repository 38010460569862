import React from "react";
import RedirectLogin from "./Login/RedirectLogin";
import NotFound from "./NotFound/NotFound";
import { lazyLienThongDonThuoc } from './QuanLyDuocNgoaiTru/LienThongDonThuoc';
import Support from "./Support/Support";
const InternalError = require("./InternalError");
// import InternalError from "./InternalError";
const lazyRetry = function (importFunc) {
  return new Promise((resolve, reject) => {
    importFunc()
      .then((component) => resolve(component)) 
      .catch((error) => {
        console.error("Error during lazy loading:", error);
        resolve(InternalError);
      });
  });
};


const QuanLyTiepDon = React.lazy(() => import("./QuanLyTiepDon/TiepDon/TiepDon"));
const QuanLyBenhNhan = React.lazy(() => import("./QuanLyBenhNhan/QuanLyBenhNhan"));
const CuocHenSapToi = React.lazy(() => import("./QuanLyTiepDon/CuocHenSapToi/CuocHenSapToi"));

const TonKho = React.lazy(() => import("./QuanLyDuocNgoaiTru/KhoThuoc/TonKho"));
const QuanlyCongNo = React.lazy(() => import("./QuanLyVienPhi/QuanLyCongNo"));
const QuanLyTamUng = React.lazy(() => import("./QuanLyVienPhi/QuanLyTamUng"));
const QuanLySuDungGoiDichVu = React.lazy(() => import("./QuanLyGoiDichVu/QuanLySuDungGoiDichVu"));
const PhauThuatThuThuat = React.lazy(() => import("./PhauThuat_ThuThuat"));
const PhieuNhapXuat = React.lazy(() => import("./QuanLyDuocNgoaiTru/PhieuNhapXuat/PhieuNhapXuat"));
// const QuanLyKhamBenh = React.lazy(() => import("./QuanLyKhamBenh"));
const QuanLyDvChiDinh = React.lazy(() => import("./QuanLyKhamBenh/QuanLyDvChiDinh"));
const QuanLyKhamBenh = React.lazy(() => import("./QuanLyKhamBenh/QuanLyKhamBenh"));
const QuanLyNoiTru = React.lazy(() => import("./QuanLyNoiTru/QuanLyNoiTru"));
const QuanLySoHoaDon = React.lazy(() => import("./QuanLyVienPhi/SoHoaDon/QuanLySoHoaDon/QuanLySoHoaDon"));
const SoHoaDon = React.lazy(() => import("./QuanLyVienPhi/SoHoaDon/SoHoaDon"));
const PhieuThuDaTao = React.lazy(() => import("./QuanLyVienPhi/DichVuCanThanhToan/PhieuThuDaTao/PhieuThuDaTao"));
const BangDieuKhien = React.lazy(() => lazyRetry(() => import("./BangDieuKhien/BangDieuKhien")));
const QLDangKyGoiDichVu = React.lazy(() => import("./QuanLyGoiDichVu/QLDangKyGoiDichVu/QLDangKyGoiDichVu"));
const DanhSachGoiDichVu = React.lazy(() => import("./QuanLyGoiDichVu/DanhSachGoiDichVu/DanhSachGoiDichVu"));
const DichVuCanThanhToan = React.lazy(() => import("./QuanLyVienPhi/DichVuCanThanhToan/DichVuCanThanhToan"));
const NhapXuat = React.lazy(() => import("./QuanLyDuocNgoaiTru/NhapXuat/NhapXuat"));
const BanThuoc = React.lazy(() => import("./QuanLyDuocNgoaiTru/BanThuoc/BanThuoc"));
const NhaCungCap = React.lazy(() => import("./QuanLyDuocNgoaiTru/NhaCungCap/NhaCungCap"));
const MauMoTaCdha = React.lazy(() => import("./RisNhapTay/MauMoTaCdha/MauMoTaCdha"));
const TraKetQuaCdha = React.lazy(() => import("./RisNhapTay/TraKetQuaCdha/TraKetQuaCdha"));

export {
  QuanLyTiepDon,
  QuanLySoHoaDon,
  // Login,
  RedirectLogin,
  NotFound,
  PhieuThuDaTao,
  QuanLyKhamBenh,
  QuanLyDvChiDinh,
  SoHoaDon,
  QuanLyNoiTru,
  TonKho,
  QuanLyBenhNhan,
  BangDieuKhien,
  CuocHenSapToi,
  PhieuNhapXuat,
  Support,
  PhauThuatThuThuat,
  QuanLyTamUng,
  QuanlyCongNo,
  QuanLySuDungGoiDichVu,
  QLDangKyGoiDichVu,
  DanhSachGoiDichVu,
  DichVuCanThanhToan,
  NhapXuat,
  BanThuoc,
  NhaCungCap,
  TraKetQuaCdha,
  MauMoTaCdha,
  lazyLienThongDonThuoc
};

export * from "./QuanLyVienPhi/SoHoaDon/SoHoaDon";
export * from "./QuanLyVienPhi/QuanLyTamUng";
export * from "./TV/TvMonitor";
export * from "./Support/Support";
export * from "./HomeLogin";
export * from "./SoftwareRoute";
