import { Divider, Form, Input, Layout, Select, Space } from "antd";
import style from "../../qlcn.module.less";
import i18n, { languageKeys } from "i18n";
import { useDispatch, useSelector } from "react-redux";
import { debtManagementState } from "ducks/slices/QlyVienPhi/QlyCongNo.slice";
import { formatCurrency2 } from "helpers";
import { forwardRef, useEffect } from "react";
import { doFetchBook, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { paymentMethodProto } from "constants/keys";

const Sider = forwardRef(({form}, ref) => {

    const dispatch = useDispatch()
    const { paidDebtList, patientInfo } = useSelector(debtManagementState)
    const userProfile = useSelector((state) => state.auth.user);
    const { refundBook, paymentMethod } = useSelector(getSharedData)

    const unpaidDebtList = paidDebtList?.[0]
    const tongDaThanhToan = unpaidDebtList?.TONG_CHI_PHI - unpaidDebtList?.MIEN_GIAM

    useEffect(() => {
        !refundBook.length && dispatch(doFetchBook({ LOAI_SO: 'SO_HOAN_TIEN' }));
    }, [userProfile])

    useEffect(() => {
        // dispatch(getUnpaidDebt(patientInfo))
        form.resetFields()
    }, [patientInfo])

    const formatOptionRefundBook = (refundBookList = []) => {
        return refundBookList?.map(i => ({
            label: i.TEN_SO,
            value: i.ID
        }))
    }

    const formatOptionPaymentMethod = (paymentMethodList = []) => {
        return paymentMethodList?.filter(i => ![paymentMethodProto.TRA_GOP,paymentMethodProto.CONG_NO,paymentMethodProto.TIEN_DU,paymentMethodProto.TAM_UNG]
        .some(txt => i?.PHAN_LOAI_HINH_THUC?.includes?.(txt)))?.map(i => ({
            label: i.TEN_HINH_THUC_THANH_TOAN,
            value: JSON.stringify({name: i.TEN_HINH_THUC_THANH_TOAN, value: i.ID})
        }))
    }

    const handleChangeMethodPayment = (val) => {
        form.setFieldValue("TEN_HINH_THUC_THANH_TOAN", val)
    }
    
    return (
        <Layout.Sider
            width={220}
            theme="light"
            className={style['right_sider']}
        >
            <h4>{i18n.t(languageKeys.vien_phi_Hoan_tien)}</h4>
            <Divider />
            <Space direction="vertical" className={style['money_container']}>
                <Space>
                    <span>{i18n.t(languageKeys.vien_phi_tong_chi_phi)}</span>
                    <b>{formatCurrency2(unpaidDebtList?.TONG_CHI_PHI, 'đ', true)}</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.vienphi_tong_mien_giam)}</span>
                    <b>{formatCurrency2(unpaidDebtList?.MIEN_GIAM, 'đ', true)}</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.field_Tong_thanh_toan)}</span>
                    <b>{formatCurrency2(tongDaThanhToan, 'đ', true)}</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.field_Da_TT)}</span>
                    <b>{formatCurrency2(+unpaidDebtList?.TONG_DA_THANH_TOAN > tongDaThanhToan ?  tongDaThanhToan : (unpaidDebtList?.TONG_DA_THANH_TOAN || 0), 'đ', true)}</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.so_du_cong_no)}:</span>
                    <b>{ unpaidDebtList?.TONG_CHI_PHI - unpaidDebtList?.MIEN_GIAM - (unpaidDebtList?.TONG_DA_THANH_TOAN || 0) > 0 ? formatCurrency2(unpaidDebtList?.TONG_CHI_PHI - unpaidDebtList?.MIEN_GIAM - (unpaidDebtList?.TONG_DA_THANH_TOAN || 0), 'đ', true) : '0 đ' }</b>
                </Space>
                <Space>
                    <span>{i18n.t(languageKeys.field_Da_TH)}: </span>
                    <b>{formatCurrency2(unpaidDebtList?.TONG_DA_THUC_HIEN || 0, 'đ', true)}</b>
                </Space>
            </Space>
            <Divider dashed />
            <Form layout="vertical" form={form}>
                <Form.Item label={i18n.t(languageKeys.field_Ly_do_hoan_tien)} name='LY_DO_HOAN_TIEN' rules={[{required: true, message:''}]}>
                    <Input.TextArea
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                    />
                </Form.Item>
                <Form.Item label={i18n.t(languageKeys.field_hinh_thuc_thanh_toan)} name='HINH_THUC_THANH_TOAN' rules={[{required: true, message:''}]}>
                    <Select
                        placeholder={i18n.t(languageKeys.common_Chon)}
                        options={formatOptionPaymentMethod(paymentMethod)}
                        onChange={handleChangeMethodPayment}
                    />
                </Form.Item>
                <Form.Item label={i18n.t(languageKeys.so_hoan_tien)} name='SO_HOADON_ID' rules={[{required: true, message:''}]}>
                    <Select
                        placeholder={i18n.t(languageKeys.common_Chon)}
                        options={formatOptionRefundBook(refundBook)}
                    />
                </Form.Item>
            </Form>
        </Layout.Sider>
    )
})

export default Sider