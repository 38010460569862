import { BangDieuKhien, BaoCao, Duoc, HeThong, KhamBenh, VienPhi, RIS, TiepDon } from "../../assets/HDSD";
import i18n from "../../i18n";
import languageKeys from "../../i18n/languageKeys";

export const defineKeys = {
  HDSDBangDieuKhien: "hdsd-bang-dieu-khien",
  HDSDBaocao: "hdsd-bao-cao",
  HDSDTiepDon: "hdsd-tiep-don",
  HDSDVienPhi: "hdsd-vien-phi",
  HDSDKhamBenh: "hdsd-kham-benh",
  HDSDDuoc: "hdsd-duoc",
  HDSDRisNhapTay: "hdsd-ris-nhap-tay",
  HDSDHeThong: "hdsd-he-thong",
  hoiDap: "hoi-dap",
  congCuHoTro: "cong-cu-ho-tro",
  mauDanhMuc: "mau-danh-muc",
};

export const HDSD = [
  {
    name: i18n.t(languageKeys.menu_Bang_dieu_khien),
    youtube: [
      {
        src: "https://player.vimeo.com/video/749468154?h=b33aa80929",
      },
    ],
    pdf: [
      {
        name: i18n.t(languageKeys.menu_Bang_dieu_khien),
        src: BangDieuKhien,
      },
    ],
  },
  {
    name: i18n.t(languageKeys.menu_Bao_cao),
    youtube: [
      {
        src: "https://player.vimeo.com/video/749472659?h=e4379a901a",
      },
    ],
    pdf: [
      {
        name: i18n.t(languageKeys.menu_Bao_cao),
        src: BaoCao,
      },
    ],
  },
  {
    name: i18n.t(languageKeys.menu_Quan_ly_tiep_don),
    youtube: [
      {
        src: "https://player.vimeo.com/video/749481096?h=79b9ceab7c",
      },
      {
        src: "https://player.vimeo.com/video/749481357?h=fa90fc4dcc",
      },
    ],
    pdf: [
      {
        name: i18n.t(languageKeys.menu_Quan_ly_tiep_don),
        src: TiepDon,
      },
    ],
  },
  {
    name: i18n.t(languageKeys.menu_Quan_ly_vien_phi),
    youtube: [
      {
        src: "https://player.vimeo.com/video/749481828?h=14c181ae5f",
      },
      {
        src: "https://player.vimeo.com/video/749482968?h=9642034487",
      },
    ],
    pdf: [
      {
        name: i18n.t(languageKeys.menu_Quan_ly_vien_phi),
        src: VienPhi,
      },
    ],
  },
  {
    name: i18n.t(languageKeys.menu_Quan_ly_kham_benh),
    youtube: [
      {
        src: "https://player.vimeo.com/video/749481631?h=aed0d26c8f",
      },
    ],
    pdf: [
      {
        name: i18n.t(languageKeys.menu_Quan_ly_kham_benh),
        src: KhamBenh,
      },
    ],
  },
  {
    name: i18n.t(languageKeys.menu_Quan_ly_duoc_ngoai_tru),
    youtube: [
      {
        src: "https://player.vimeo.com/video/749487121?h=8e3a853880",
      },
      {
        src: "https://player.vimeo.com/video/749486951?h=0361248b24",
      },
      {
        src: "https://player.vimeo.com/video/749486644?h=661cef67b6",
      },
      {
        src: "https://player.vimeo.com/video/749486211?h=64ee9654e4",
      },
    ],
    pdf: [
      {
        name: i18n.t(languageKeys.menu_Quan_ly_duoc_ngoai_tru),
        src: Duoc,
      },
    ],
  },
  {
    name: i18n.t(languageKeys.menu_Quan_ly_chan_doan_hinh_anh_ris),
    youtube: [
      {
        src: "https://player.vimeo.com/video/749483191?h=74ada81a4e",
      },
      {
        src: "https://player.vimeo.com/video/749483426?h=4590b2501c",
      },
    ],
    pdf: [
      {
        name: i18n.t(languageKeys.menu_Quan_ly_chan_doan_hinh_anh_ris),
        src: RIS,
      },
    ],
  },
  {
    name: i18n.t(languageKeys.menu_Quan_ly_he_thong),
    youtube: [
      {
        src: "https://player.vimeo.com/video/749488055?h=ab95467c52",
      },
      {
        src: "https://player.vimeo.com/video/749488940?h=10c5fc5738",
      },
      {
        src: "https://player.vimeo.com/video/749489112?h=e769f50ad4",
      },
      {
        src: "https://player.vimeo.com/video/749487777?h=57dcb30513",
      },
    ],
    pdf: [
      {
        name: i18n.t(languageKeys.menu_Quan_ly_he_thong),
        src: HeThong,
      },
    ],
  },
];
