import { PrinterOutlined } from "@ant-design/icons";
import { Button, Form, Input, Layout, Space, Tooltip, notification } from "antd";
import { ConfirmModal, ModalPDF, Table } from "components";
import { apis } from "../../../../../constants";
import { cancelPaidDebt, debtManagementState, getPaidDebt } from "ducks/slices/QlyVienPhi/QlyCongNo.slice";
import { tableLoadingState } from "ducks/slices/loadingSlice";
import { formatCurrency2, handleCheckPermissions } from "helpers";
import i18n, { languageKeys } from "../../../../../i18n";
import moment from "moment";
import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { featureKeys } from "constants/keys";
import { Trash } from "assets/svg";
import style from "../../qlcn.module.less"

const Content = () => {

  const [form] = Form.useForm();

  const pdfRef = useRef()
  const confirmRef = useRef()

  const dispatch = useDispatch()
  const { patientInfo, paidDebtList } = useSelector(debtManagementState)
  const isLoadingTable = useSelector(tableLoadingState)
  const userProfile = useSelector((state) => state.auth.user);
  const permission = handleCheckPermissions(userProfile, featureKeys.quan_ly_cong_no)

  useEffect(() => {
    dispatch(getPaidDebt(patientInfo))
  }, [patientInfo])

  const columnsDichVu = [
    {
      title: i18n.t(languageKeys.field_Ma_phieu),
      key: "MA_PHIEU",
      width: "30%",
      dataIndex: 'MA_PHIEU'
    },
    {
      title: i18n.t(languageKeys.field_thoi_gian),
      key: "THOI_GIAN",
      width: "40%",
      render: (_, record) => {
        return <span style={{ fontSize: 14 }}>{`${record.GIO} - ${moment(record.NGAY).format('DD/MM/YYYY')}`}</span>
      }
    },
    {
      title: i18n.t(languageKeys.tong_tien),
      key: "TONG_TIEN",
      width: "50%",
      render: (_, record) => <span style={{ color: record?.NHOM === 'THANH_TOAN' ? '#2CB2A5' : '#f27e7c' }}>{formatCurrency2(record.TONG_TIEN, 'đ', true)}</span>
    },
    {
      title: "",
      key: "action_btn",
      width: 40,
      render: (_, record) => (
        <Space>
          <Tooltip title={permission.IN_PDF ? i18n.t(languageKeys.field_In_phieu) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
            <Button
              icon={<PrinterOutlined />}
              type="link"
              size="small"
              onClick={() => handlePrintEachBill({ id: record.HOA_DON_ID, nhom: record.NHOM })}
              disabled={!permission.IN_PDF}
            />
          </Tooltip>
          {moment().isSame(moment(record.NGAY), 'day')
            ? (
              <Tooltip title={permission.HUY ? i18n.t(languageKeys.huy_phieu) : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
                <Button
                  className={style["btn-cancel"]}
                  icon={<Trash />}
                  type="link"
                  size="small"
                  onClick={() => confirmRef.current?.open(record)}
                  disabled={!permission.HUY}
                />
              </Tooltip>
            ) : <></>
          }
        </Space >
      )
    },
  ];

  const formatDataTable = (data = []) => {
    return data?.filter(item => item.NHOM !== 'HOAN_TIEN')?.map(i => ({
      MA_PHIEU: i.SO_PHIEU,
      GIO: i.GIO,
      NGAY: i.NGAY,
      TONG_TIEN: i.BENH_NHAN_DUA,
      HOA_DON_ID: i.HOA_DON_ID,
      NHOM: i.NHOM
    }))
  }

  const handlePrintEachBill = ({ id, nhom }) => {
    if (id) {
      const dataRequest = {
        keyPhieu: nhom === 'THANH_TOAN' ? 'phieuCongNo' : 'phieuHoanTien',
        arr_HOA_DON_ID: [id]
      }
      pdfRef.current.openModal(dataRequest, patientInfo.LICH_KHAM_ID ? apis.in_cong_no_dv : apis.pdf_quan_ly_cong_no)
    } else {
      notification.error({
        message: i18n.t(languageKeys.common_co_loi_xay_ra)
      })
    }
  }

  const handleCancel = (data) => {
    form.validateFields().then(
      () => dispatch(cancelPaidDebt({
        payLoad: {
          HOA_DON_ID: data.HOA_DON_ID,
          NGUOI_HUY: userProfile?.NHANSU_ID,
          LY_DO_HUY: form.getFieldValue('LY_DO_HUY')
        },
        callbackLoading: (status) => confirmRef.current?.loading(status),
        callbackClose: () => confirmRef.current?.close(),
        isModal: false,
      }))
    )
  }

  return (
    <>
      <Layout.Content style={{ padding: 15 }}>
        <h2 style={{ marginBottom: 15 }}>{i18n.t(languageKeys.phieu_thu_cong_no)}</h2>
        <Table
          loading={isLoadingTable}
          columns={columnsDichVu}
          dataSource={formatDataTable(paidDebtList)}
        />
      </Layout.Content>

      <ModalPDF ref={pdfRef} />

      <ConfirmModal
        ref={confirmRef}
        content={""}
        danger
        onOk={handleCancel}
        onCancel={() => form.resetFields()}
        onPressCancelSuccess={() => form.resetFields()}
      >
        <div className={style.cancelRes}>
          <p>{i18n.t(languageKeys.confirm_cancel_ticket)}</p>
          <Form form={form} layout="vertical">
            <Form.Item
              label={i18n.t(languageKeys.ly_do_huy_phieu)}
              name="LY_DO_HUY"
              rules={[{ required: true, whitespace: true, message: "" }]}
            >
              <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
            </Form.Item>
          </Form>
        </div>
      </ConfirmModal>

    </>
  )
}

export default Content