import style from "./style.module.less";
import { useState, useEffect } from "react";
import i18n, { languageKeys } from "../../../i18n";
import { Row, Col, Collapse } from "antd";
import { Clock, Triangle, Mail } from "../../../assets/svg";
import cn from "classnames";
import HoiDapDrawer from "./hoiDapDrawer/hoiDapDrawer";
import { apiLayDsCauHoiCuaToi, apiLayDsCauHoiThuongGap } from "../apisHoTro";
import { useSelector } from "react-redux";
import moment from "moment";
import HoiDapModal from "./hoiDapModal/hoiDapModal";

const HoiDap = () => {
  const { Panel } = Collapse;
  const userProfile = useSelector((state) => state.auth.user);
  const [visible, setVisible] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [dsMyQuestion, setDsMyQuestion] = useState([]);
  const [dsFaq, setDsFaq] = useState([]);

  useEffect(() => {
    layDsCauHoiCuaToi();
    layDsCauHoiThuongGap();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const layDsCauHoiCuaToi = async () => {
    let request = {
      NHANSU_ID: userProfile.NHANSU_ID,
      BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
      partner_code: userProfile.partner_code,
    };
    let response = await apiLayDsCauHoiCuaToi(request);
    if (response) {
      setDsMyQuestion(response.result);
    }
  };

  const layDsCauHoiThuongGap = async () => {
    let response = await apiLayDsCauHoiThuongGap();
    if (response) {
      setDsFaq(response.result);
    }
  };

  // console.log("dsMyQuestion", dsMyQuestion);
  // console.log("dsFaq", dsFaq);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  const handleOk = () => {
    setModalVisible(false);
  };

  const handleAddSuccess = () => {
    layDsCauHoiCuaToi();
    setVisible(false);
    setModalVisible(true);
  };

  return (
    <div className={style["container"]}>
      <Row className={style["wrapper"]}>
        <Col span={8}>
          <div className={style["my-question"]}>
            <div className={style["submit-question"]} onClick={showDrawer}>
              <div className={style["mail-logo"]}>
                <Mail />
              </div>
              <div>
                <p>{i18n.t(languageKeys.gui_cau_hoi)}</p>
                <span>{i18n.t(languageKeys.nhap_noi_dung)}</span>
              </div>
            </div>
            <div className={style["recent-question"]}>
              <p className={cn(style["faq-title"], style["line"])}>{i18n.t(languageKeys.cau_hoi_cua_toi)}</p>
              {dsMyQuestion.map((question, i) => (
                <div key={i} className={style["block"]}>
                  <div>
                    <div className={style["time"]}>
                      <Clock />
                      <span>{question.GIO_TAO + " " + moment(question.NGAY_TAO, "YYYYMMDD").format("DD/MM/YYYY")}</span>
                    </div>
                    <p>{question.CAU_HOI}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
        <Col span={16}>
          <div className={style["common-question"]}>
            <p className={style["faq-title"]}>{i18n.t(languageKeys.danh_sach_cau_hoi_thuong_gap)}</p>
            <Collapse
              accordion
              expandIcon={({ isActive }) => (isActive ? <Triangle /> : <Triangle style={{ transform: "rotate(270deg)" }} />)}
              bordered={false}
              defaultActiveKey={["1"]}
            >
              {dsFaq.map((p, i) => (
                <Panel header={p.CAU_HOI} key={`${i + 1}`} className={style["collapse"]}>
                  <p>{p.TRA_LOI}</p>
                </Panel>
              ))}
            </Collapse>
          </div>
        </Col>
      </Row>
      <HoiDapDrawer visible={visible} onClose={onClose} handleAddSuccess={handleAddSuccess} />
      <HoiDapModal visible={modalVisible} onOk={handleOk} />
    </div>
  );
};

// const questions = [
//   {
//     THOI_GIAN: "12:43 22/12/2022",
//     CAU_HOI: `Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC?`,
//   },
//   {
//     THOI_GIAN: "12:43 22/12/2022",
//     CAU_HOI: `Where does it come from?`,
//   },
//   {
//     THOI_GIAN: "12:43 22/12/2022",
//     CAU_HOI: `1914 translation by H. Rackham`,
//   },
// ];

// const QnA = [
//   {
//     QUESTION: `Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC?`,
//     ANSWER:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit",
//   },
//   {
//     QUESTION: `Where does it come from?`,
//     ANSWER:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit",
//   },
//   {
//     QUESTION: `1914 translation by H. Rackham`,
//     ANSWER:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit",
//   },
//   {
//     QUESTION: `Section 1.10.32 of "de Finibus Bonorum et Malorum", written by Cicero in 45 BC?`,
//     ANSWER:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit",
//   },
// ];

export default HoiDap;
