import { memo, useCallback, useEffect, useState } from "react";
import { HLog } from "../../../../helpers";
import { debounce } from "lodash";
import formDanhMuc from "./formDanhMuc.module.less";
import i18n, { languageKeys } from "../../../../i18n";
import { Col, Form, Input, Row, Switch } from "antd";
import { fieldKhoa, fieldPhongNoiTru } from "../fieldsDanhMuc";
import { Select } from "../../../../components";
import { useSelector } from "react-redux";
import { keys } from "../../../../constants";
import {
  apiLayChiTietPhongNoiTru,
  apiLayDsBuongDieuTri,
} from "../apisDanhMuc/apisDmPhongNoiTru";

const FormPhongNoiTru = ({
  initData = {},
  visible = false,
  is_create_new = false,
  form,
}) => {
  const userProfile = useSelector((state) => state.auth.user);
  const [danhSachKhoa, setDanhSachKhoa] = useState([]); // Danh sách khoa (Select)
  const [danhSachBuongDieuTri, setDanhSachBuongDieuTri] = useState([]); // Danh sách khoa (Select)
  const [dsPhongChiDinh, setDsPhongChiDinh] = useState([]); // Danh sách phòng chỉ định (Select)
  const [disabledSelectPcd, setDisabledSelectPcd] = useState(true);
  const [loadingKhoa, setLoadingKhoa] = useState(false);
  const [loadingPCD, setLoadingPCD] = useState(false);
  const [selectedPCD, setSelectedPCD] = useState([]); //chọn buồng điều trị

  useEffect(() => {
    if (visible) {
      if (!is_create_new) {
        layChiTietPhong();
        // chonLoaiPhong(initData[fieldPhongNoiTru.loai_phong]);

        form.setFields(
          Object.values(fieldPhongNoiTru).map((name) => {
            // console.log(name);
            if (name === fieldPhongNoiTru.buong_dieu_tri) {
              return { name, value: initData[fieldPhongNoiTru.ten_phong] };
            }
            return { name, value: initData[name] };
          })
        );
      }
    } else {
      setDanhSachKhoa([]);
      setDanhSachBuongDieuTri([]);
      setDsPhongChiDinh([]);
      setDisabledSelectPcd(true);
      setLoadingKhoa(false);
      setLoadingPCD(false);
      setSelectedPCD([]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  // Hàm lấy thông tin chi tiết phòng
  const layChiTietPhong = async () => {
    try {
      const res = await apiLayChiTietPhongNoiTru({
        ID: initData[fieldPhongNoiTru.id],
        partner_code: userProfile.partner_code,
      });

      if (res.status === "OK") {
        const key_buong_dieu_tri = fieldPhongNoiTru.buong_dieu_tri;

        const pcdList = res.result[key_buong_dieu_tri];
        let buongDieuTri = "";
        pcdList.map((item) => (buongDieuTri = item.TEN_KHOA_PHONG));
        // console.log(pcdList);
        const pcdListStringified =
          pcdList.length > 0
            ? pcdList.map((item) =>
                JSON.stringify({
                  [fieldPhongNoiTru.id]: item[fieldPhongNoiTru.id],
                  [fieldPhongNoiTru.ten_phong]:
                    item[fieldPhongNoiTru.ten_phong],
                })
              )
            : [];
        // console.log(res.result);
        setSelectedPCD(pcdListStringified);

        form.setFields([
          {
            name: fieldPhongNoiTru.buong_dieu_tri_id,
            value: buongDieuTri,
          },
          {
            name: fieldPhongNoiTru.ma_phong,
            value: res.result.MA_PHONG,
          },
          {
            name: fieldPhongNoiTru.ten_phong,
            value: res.result.TEN_KHOA_PHONG,
          },
          // {
          //   name: "MA_KHOA",
          //   value: res.result.TEN_KHOA,
          // },
          {
            name: fieldPhongNoiTru.ghi_chu,
            value: res.result.GHI_CHU,
          },
        ]);
      }
    } catch (error) {
      HLog("FormPhong lay thong tin chi tiet error", error);
    }
  };

  // Hàm lấy danh sách buồng điều trị (tìm kiếm luôn)
  const layDanhSachKhoa = async (
    search_string = "",
    onSuccess = async () => {}
  ) => {
    try {
      setLoadingKhoa(true);

      const res = await apiLayDsBuongDieuTri({
        BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
        search_string,
        limit: keys.limit,
        partner_code: userProfile.partner_code,
      });

      if (res.status === "OK") {
        setDanhSachKhoa(
          res.result.map((item) => ({
            [fieldKhoa.id]: item[fieldKhoa.id],
            [fieldKhoa.ten_khoa]: item[fieldKhoa.ten_khoa],
            [fieldKhoa.ma_khoa]: item[fieldKhoa.ma_khoa],
          }))
        );

        onSuccess(res.result);
      }
    } catch (error) {
      HLog("FormPhong lay ds khoa error", error);
    }

    setLoadingKhoa(false);
  };
  // console.log(danhSachKhoa);

  // Hàm lấy danh sách phòng chỉ định (tìm kiếm luôn)
  // const layDsPhongChiDinh = async (search_string = "", CHI_DINH = []) => {
  //   try {
  //     setLoadingPCD(true);

  //     const res = await apiLayDsPhongNoiTru({
  //       BENH_VIEN_ID: userProfile.BENH_VIEN_ID,
  //       search_string,
  //       limit: keys.limit,
  //       CHI_DINH,
  //       LOAI_PHONG: "KHAM_BENH",
  //       partner_code: userProfile.partner_code,
  //     });

  //     if (res.status === "OK" && !!res.result && res.result.length > 0) {
  //       setDsPhongChiDinh(
  //         res.result.map((item) => ({
  //           [fieldPhongNoiTru.id]: item[fieldPhongNoiTru.id],
  //           [fieldPhongNoiTru.ten_phong]: item[fieldPhongNoiTru.ten_phong],
  //         }))
  //       );
  //     }

  //     setLoadingPCD(false);
  //   } catch (error) {
  //     HLog("FormPhong lay ds phong chi dinh error", error);
  //     setLoadingPCD(false);
  //   }
  // };

  // Hàm tìm kiếm Khoa
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchKhoa = useCallback(
    debounce((value) => layDanhSachKhoa(value), 1000),
    []
  );

  // Hàm tìm kiếm phòng chỉ định
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // const searchPCD = useCallback(
  //   debounce(
  //     (value) =>
  //       layDsPhongChiDinh(
  //         value,
  //         is_create_new ? [] : [initData[fieldPhongNoiTru.ma_phong]]
  //       ),
  //     1000
  //   ),
  //   []
  // );

  // const chonLoaiPhong = (val) => {
  //   if (val === "CDHA" || val === "XET_NGHIEM") {
  //     setDisabledSelectPcd(false);
  //   } else {
  //     form.setFields([{ name: fieldPhongNoiTru.phong_chi_dinh, value: [] }]);
  //     setDisabledSelectPcd(true);
  //   }
  // };

  return (
    <>
      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Thong_tin_co_so)}</h4>

        <div className={formDanhMuc["inner"]}>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name={fieldPhongNoiTru.ma_phong}
                label={i18n.t(languageKeys.field_Ma_phong)}
                rules={
                  [
                    // {
                    //   required: true,
                    //   message: i18n.t(languageKeys.vui_long_nhap),
                    // },
                  ]
                }
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldPhongNoiTru.ten_phong}
                label={i18n.t(languageKeys.field_Ten_phong)}
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Input placeholder={i18n.t(languageKeys.common_Nhap)} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={10}>
            <Col span={12}>
              <Form.Item
                name="MA_KHOA"
                label="Mã khóa"
                // rules={[
                //   {
                //     required: true,
                //     message: i18n.t(languageKeys.vui_long_nhap),
                //   },
                // ]}
              >
                <Input
                  // placeholder={i18n.t(languageKeys.common_Nhap)}
                  readOnly
                />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name={fieldPhongNoiTru.buong_dieu_tri_id}
                label="Buồng điều trị"
                rules={[
                  {
                    required: true,
                    message: i18n.t(languageKeys.vui_long_nhap),
                  },
                ]}
              >
                <Select
                  placeholder={i18n.t(languageKeys.common_Chon)}
                  dataSource={danhSachKhoa}
                  titleKey={fieldKhoa.ten_khoa}
                  selectedList={selectedPCD}
                  showSearch
                  onChange={(item) =>
                    form.setFields([
                      {
                        name: "MA_KHOA",
                        value: JSON.parse(item).MA_KHOA,
                      },
                    ])
                  }
                  onSearch={searchKhoa}
                  loading={loadingKhoa}
                  onDropdownVisibleChange={(bool) => bool && layDanhSachKhoa()}
                />
              </Form.Item>
            </Col>

            {/* {disabledSelectPcd && (
              <Col span={24}>
                <Form.Item
                  name={fieldPhongNoiTru.buong_dieu_tri_id}
                  label={i18n.t(languageKeys.field_Phong_chi_dinh)}
                >
                  <Select
                    placeholder={i18n.t(languageKeys.common_Chon)}
                    onSearch={searchPCD}
                    dataSource={dsPhongChiDinh}
                    titleKey={fieldPhongNoiTru.ten_phong}
                    multiple
                    disabled={disabledSelectPcd}
                    showSearch
                    onDropdownVisibleChange={(bool) =>
                      bool &&
                      (is_create_new
                        ? layDsPhongChiDinh()
                        : layDsPhongChiDinh("", [
                            initData[fieldPhongNoiTru.ma_phong],
                          ]))
                    }
                    loading={loadingPCD}
                    onChange={setSelectedPCD}
                    selectedList={selectedPCD}
                    setSelectedList={(newArr) => {
                      const key_pcd = fieldPhongNoiTru.buong_dieu_tri_id;

                      setSelectedPCD(newArr);

                      form.setFields([
                        {
                          name: key_pcd,
                          value: newArr,
                        },
                      ]);
                    }}
                  />
                </Form.Item>
              </Col>
            )} */}
          </Row>
        </div>
      </div>

      <div className={formDanhMuc["wrapper"]}>
        <h4>{i18n.t(languageKeys.field_Ghi_chu)}</h4>

        <Form.Item name={fieldPhongNoiTru.ghi_chu}>
          <Input.TextArea
            rows={3}
            placeholder={i18n.t(languageKeys.common_Nhap)}
          />
        </Form.Item>

        <h4>{i18n.t(languageKeys.field_Hien_thi)}</h4>

        <Form.Item
          name={fieldPhongNoiTru.hien_thi}
          valuePropName="checked"
          initialValue={false}
        >
          <Switch />
        </Form.Item>
      </div>
    </>
  );
};

export default memo(FormPhongNoiTru);
