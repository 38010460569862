import Item from "antd/lib/list/Item";
import { apis, keys } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldLoaiDvKiThuat } from "../fieldsDanhMuc";
import store from "../../../../ducks/configStore";

const key_nhom_dv_id = fieldLoaiDvKiThuat.nhom_dv_ki_thuat;

export const apiLayDsLoaiDvkt = async ({ BENH_VIEN_ID = "", search_string = "", partner_code = "", page = 1, limit = keys.limit }) => {
  try {
    let requestData = {
      BENH_VIEN_ID,
      search_string,
      partner_code,
      page,
      limit,
    };

    const response = await common_post(apis.dm_loai_dvkt_lay_ds, requestData);

    return response;
  } catch (error) {
    HLog("Api lay ds loai dvkt error", error);
  }
};

/**
 * API thêm loại dvkt
 */
export const apiThemLoaiDvkt = async ({ BENH_VIEN_ID = "", data = {}, partner_code = "" }) => {
  try {
    const _store = store.getState()
    let { auth } = _store
    if (!!data[key_nhom_dv_id]) {
      data[key_nhom_dv_id] = JSON.parse(data[key_nhom_dv_id])[key_nhom_dv_id];
    }

    let requestData = {
      data: [data],
      BENH_VIEN_ID,
      partner_code,
      NGUOI_TAO : auth.user ? auth.user.NHANSU_ID : null
    };

    return await common_post(apis.dm_loai_dvkt_them_moi, requestData);
  } catch (error) {
    HLog("Api them moi loai dvkt error", error);
  }
};

export const apiNhapDsLoaiDvkt = async ({ BENH_VIEN_ID = "", data = [], partner_code = "" }) => {
  try {
    data = data.map((item) => {
      Object.keys(item).forEach((key) => {
        if (key === "STT_LDV" && !item[key]) {
          item[key] = null;
        } else if (key === "STT_LDV") {
          item[key] = Number(item[key]);
        } else item[key] = item[key].toString();
      });
      return item;
    });
    let requestData = {
      data,
      BENH_VIEN_ID,
      partner_code,
    };

    return await common_post(apis.dm_loai_dvkt_them_moi, requestData);
  } catch (error) {
    HLog("Api nhap ds loai dvkt error", error);
  }
};

/**
 * API sửa loại dvkt
 */
export const apiSuaLoaiDvkt = async ({ data = {}, currentData = {}, NHANSU_ID = ""}) => {
  try {
    // if (isNaN(Number(data[key_nhom_dv_id]))) {

    //   data[key_nhom_dv_id] = JSON.parse(data[key_nhom_dv_id])[key_nhom_dv_id];
    // }

    const key_nhom_dvkt = fieldLoaiDvKiThuat.nhom_dv_ki_thuat;
    const key_nguoi_sua = fieldLoaiDvKiThuat.nguoi_sua;
    data[key_nguoi_sua] = NHANSU_ID;

    // nếu là json string (chọn mới nhóm dvkt)
    // eslint-disable-next-line no-useless-escape
    if (typeof data[key_nhom_dvkt] === "string" && data[key_nhom_dvkt].includes(`{\"`)) {
      data[key_nhom_dvkt] = JSON.parse(data[key_nhom_dvkt])[key_nhom_dvkt];
    }
    // nếu là string tên nhóm dvkt (không chọn mới nhóm dvkt)
    else {
      data[key_nhom_dvkt] = currentData[key_nhom_dvkt];
    }

    let requestData = { ...data };

    return await common_post(apis.dm_loai_dvkt_cap_nhat, requestData);
  } catch (error) {
    HLog("Api cap nhat loai dvkt error", error);
  }
};

/**
 * API xóa loại dvkt
 */
export const apiXoaLoaiDvkt = async ({ partner_code = "", data = {} }) => {
  try {
    const listId = [data[fieldLoaiDvKiThuat.id]];

    let requestData = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_loai_dvkt_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa loai dvkt error", error);
  }
};

/**
 * API xóa ds loại dvkt
 */
export const apiXoaDsLoaiDvkt = async ({ partner_code = "", data = [] }) => {
  try {
    const listId = data.map((item) => item[fieldLoaiDvKiThuat.id]);

    let requestData = {
      listId,
      partner_code,
    };

    const response = await common_post(apis.dm_loai_dvkt_xoa, requestData);

    return response;
  } catch (error) {
    HLog("Api xoa ds loai dvkt error", error);
  }
};

/**
 * API khoá danh sách loai dvkt
 */
export const apiKhoaDsLoaiDvkt = async ({ partner_code = "", data = [], lock = false }) => {
  try {
    const listId = data.map((item) => item[fieldLoaiDvKiThuat.id]);
    const req = { partner_code, listId };
    const api = lock ? apis.dm_loai_dvkt_lock_ds : apis.dm_loai_dvkt_unlock_ds;
    const response = await common_post(api, req);
    return response;
  } catch (error) {
    HLog("Api khoa danh sach loai dvkt error", error);
  }
};

export const apiExportLoaiDvkt = async ({ BENH_VIEN_ID = "", partner_code = "", template = 0 }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      template,
    };
    const res = await common_post(apis.dm_loai_dvkt_export, req);
    if (res.status === "OK") return res;
  } catch (error) {
    HLog(!!template ? "Tai template loai dvkt error" : "Export ds loai dvkt error");
  }
};
