import { notification } from "antd";
import { apis } from "../../../../constants";
import { common_post, HLog } from "../../../../helpers";
import { fieldDanhMucNguonKhach } from "../fieldsDanhMuc";

export const apiThemDmNguonKhach = async ({ data, BENH_VIEN_ID, partner_code }) => {
  if(data?.hasOwnProperty(fieldDanhMucNguonKhach.nhan_su_id)) {
    data[fieldDanhMucNguonKhach.nhan_su_id] = JSON.parse(data[fieldDanhMucNguonKhach.nhan_su_id]).value
  } else if(data?.hasOwnProperty(fieldDanhMucNguonKhach.ve_tinh_id)) {
    data[fieldDanhMucNguonKhach.ve_tinh_id] = JSON.parse(data[fieldDanhMucNguonKhach.ve_tinh_id]).value
  }
  let dataRequest = { ...data, BENH_VIEN_ID, partner_code };
  HLog("data request::::", dataRequest);
  try {
    let response = await common_post(apis.dm_them_nguon_khach, dataRequest, false);
    return {...response,...data};
  } catch (error) {
    HLog("Api them nguon khach error", error);
  }
};
export const apiSuaDmNguonKhach = async ({ data, BENH_VIEN_ID, partner_code, NHANSU_ID }) => {
  let dataRequest = { ...data, NHANSU_ID: NHANSU_ID };
  delete dataRequest["key"];
  // delete dataRequest['_THU_TU'];
  // delete dataRequest['DUNG_CHO_CIS'];
  HLog("sua doi tuong", dataRequest);
  try {
    let response = await common_post(apis.dm_sua_nguon_khach, dataRequest, false);
    return response;
  } catch (error) {
    HLog("Api sua doi tuong error", error);
  }
};
export const apiXoaDmNguonKhach = async ({ data, BENH_VIEN_ID, partner_code, NHANSU_ID }) => {
  try {
    if (!Array.isArray(data)) data = [data];
    let body = {
      BENH_VIEN_ID,
      partner_code,
      NHANSU_ID,
      arr_ID: data.map((item) => item.ID),
    };

    HLog("xoa doi tuong::", body);
    let res = await common_post(apis.dm_xoa_nguon_khach, body, false);

    return res;
  } catch (error) {
    HLog("======::", error);
  }
};
export const apiKhoaDmNguonKhach = async ({ lock, data, BENH_VIEN_ID, partner_code, NHANSU_ID }) => {
  try {
    if (!Array.isArray(data)) data = [data];

    let body = {
      partner_code,
      BENH_VIEN_ID,
      arr_ID: data.map((item) => !!item.ID && item.ID),
      KHOA: Number(lock),
      NHANSU_ID: NHANSU_ID,
    };
    let res = await common_post(apis.dm_khoa_nguon_khach, body, false);
    return res;
  } catch (error) {
    HLog("=======================", error);
  }
};
export const apiLayDmNguonKhach = async ({ search_string, page, BENH_VIEN_ID, partner_code }) => {
  try {
    let req = {
      BENH_VIEN_ID,
      partner_code,
      search_string,
      page,
    };
    HLog("data request lay doi tuong::::", req);

    const res = await common_post(apis.dm_lay_ds_nguon_khach, req);
    return res;
  } catch (error) {
    HLog(error);
  }
};
export const apiExportDmNguonKhach = async ({ BENH_VIEN_ID, partner_code }) => {
  try {
    let res = await common_post(apis.dm_export_nguon_khach, { BENH_VIEN_ID, partner_code }, false);
    if (res && res.status === "OK") {
      let { result } = res;
      res.result = result.map((item) => {
        let { ID, MA_NGUON_KHACH, TEN_NGUON_KHACH, GHI_CHU, KHOA } = item;
        return {
          ID,
          MA_NGUON_KHACH,
          TEN_NGUON_KHACH,
          GHI_CHU,
          KHOA,
        };
      });
    }
    return res;
  } catch (error) {
    HLog("errorEXPortDoiTUong::", error);
  }
};
export const apiImportDmNguonKhach = async ({ data, BENH_VIEN_ID, partner_code }) => {
  try {
    let body = {
      BENH_VIEN_ID,
      partner_code,
      data: data.map((item) => ({ ...item, BENH_VIEN_ID, KHOA: Number(!!item.KHOA)})),
    };
    let res = await common_post(apis.dm_import_nguon_khach, body, false);
    return res;
  } catch (error) {
    HLog("errorIMPortDoiTUong::", error);
  }
};
