import { apis } from "../../../constants";
import { common_post, HLog } from "../../../helpers";

//api lấy ds chờ khám, cls, hoàn thành
export async function _layDSBenhNhanChoKham(dataRequest) {
  try {
    const res = await common_post(
      apis.khambenh_ds_benh_nhan_kham,
      dataRequest,
      true
    );
    // HLog("Apis DsBenhNhan layDSBenhNhanChoKham response: ", res);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis DsBenhNhan layDSBenhNhanChoKham lỗi: ", error);
    return;
  }
}

export async function _layThongTinSLTungLoaiDVCD(dataRequest) {
  try {
    const res = await common_post(
      apis.demLichKhamBenh,
      dataRequest,
      true
    );
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis DsBenhNhan layDSBenhNhanChoKham lỗi: ", error);
    return;
  }
}

export async function _layThongTinSLTungLoaiKhamBenh(dataRequest) {
  try {
    const res = await common_post(
      apis.demLichQlKhamBenh,
      dataRequest,
      true
    );
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis DsBenhNhan layDSBenhNhanChoKham lỗi: ", error);
    return;
  }
}

//api lấy ds phòng của bác sĩ đang làm việc
export async function layDSPhongBs(dataRequest) {
  try {
    const res = await common_post(
      apis.khambenh_lay_dm_phong,
      dataRequest,
      true
    );
    HLog("Apis DsBenhNhan layDSPhongBs response: ", res);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis DsBenhNhan layDSPhongBs lỗi: ", error);
    return;
  }
}

//api lấy ds phòng của bác sĩ đang làm việc
export async function layDsPhongTheoBs(dataRequest) {
  try {
    const res = await common_post(
      apis.lay_phong_theo_nhan_su,
      dataRequest,
      true
    );
    HLog("Apis layDsPhongTheoBs response: ", res);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis layDsPhongTheoBs lỗi: ", error);
    return;
  }
}

export async function layHangChoChiDinhDv(dataRequest) {
  try {
    const res = await common_post(
      apis.layHangChoChiDinhDv,
      dataRequest,
      true
    );


    HLog("Apis DsBenhNhan layDSBenhNhanChoKham response: ", res);
    if (!!res && res.status === "OK") {
      return res;
    }
    return;
  } catch (error) {
    HLog("Apis DsBenhNhan layDSBenhNhanChoKham lỗi: ", error);
    return;
  }
}