import { Button, Checkbox, Collapse, Drawer, Input, Space, Row, Form, Col, notification, InputNumber, Spin, Tooltip } from "antd";
import { CaretRightOutlined, QrcodeOutlined } from "@ant-design/icons";
import { useCallback, useImperativeHandle, useState, forwardRef, useRef, useEffect } from "react";
import style from "../qltu.module.less";
import i18n, { languageKeys } from "i18n";
import { convertMillisecondToAge, formatCurrency, locdau } from "helpers";
import { DateInput } from "components_v2";
import moment from "moment";
import InputSearchPatient from "pages/QuanLyTiepDon/ModalTiepDon/components/InputSearchPatient";
import { useDispatch, useSelector } from "react-redux";
import { LOAI_PHIEU_TamUng, danhSachGioiTinh } from "constants/data";
import { ModalPDF, Select, Table } from "components";
import { clearDistrictWard, getCityDistrictWard, getDistrictSuccess, getWardSuccess } from "ducks/slices/ListData/CityDistrictWard.slice";
import { doFetchBook, doFetchPaymentMethod, getSharedData } from "ducks/slices/ListData/SharedData.slice";
import { QRLoadingState, formLoadingState, selectLoadingState } from "ducks/slices/loadingSlice";
import FormHoanTien from "./FormHoanTien";
import { QlyTamUngData, addPhTamUng, editPhTamUng, getDsPhHoanUng } from "ducks/slices/QlyVienPhi/QlyTamUng.slice";
import { useEventListener } from "hooks";
import QRInput from "components_v2/QRInput";
import { loaiSo, paymentMethodProto, widthDrawerKeys } from "constants/keys";
import ModalTTBN from "components_v2/ModalTTBN";
import { NewBackIcon } from "assets/svg";
import { snapshotActions } from "ducks/slices/snapshotSlice";

const FormTamUng = forwardRef(({ checkPermissions, isFromThongTinTTBenhNhan = false, onAfterSubmit }, ref) => {

  const dateRef = useRef()
  const formRef = useRef()
  const pdfRef = useRef()
  const duplicateInfoRef = useRef();

  const [open, setOpen] = useState(false);
  const [batBuoc, setBatBuoc] = useState(false);
  const [isCreateNew, setIsCreateNew] = useState(true)
  const [continuousInput, setContinuousInput] = useState(false)
  const [readOnly, setReadOnly] = useState(false)
  const [doPrint, setDoPrint] = useState(false)

  const [form] = Form.useForm();

  const dispatch = useDispatch()
  const userProfile = useSelector((state) => state.auth.user);
  const { result: DsPhTamUng, dsPhHoanUng } = useSelector(QlyTamUngData)
  const { district, ward } = useSelector(getCityDistrictWard)
  const data = useSelector((state) => state.data)
  const { personnel, paymentMethod, advanceBook } = useSelector(getSharedData)
  const loadingForm = useSelector(formLoadingState)
  const loading = useSelector(selectLoadingState)
  const loadingQR = useSelector(QRLoadingState)
  const { modeQR } = useSelector((state) => state.snapShot)

  // Close form after submit
  useEffect(() => {
    // doPrint && pdfRef.current?.openModalWithData(form.getFieldValue(undefined), "phieuTamUng")
    if (isCreateNew) {
      if (continuousInput) {
        form.resetFields()
        dispatch(clearDistrictWard())
      } else {
        handleClose()
      }
    } else {
      handleClose()
    }
  }, [DsPhTamUng])

  useImperativeHandle(ref, () => ({
    open: initOpen,
    fillForm: (data) => {
      setIsCreateNew(false)
      initOpen()
      handleFillForm(data)
      setReadOnly(data.LOAI_PHIEU === "HUY" || data.PHAN_LOAI === 'TAT_TOAN_GOI')
      dispatch(getDsPhHoanUng({ ID: data.ID }))
    },
    close: handleClose,
    // print: (data) => pdfRef.current?.openModalWithData(data, "phieuTamUng")
  }));

  const initOpen = (data) => {
    if (data && data?.info) {
      handleFillForm(data?.info)
    }
    setOpen(true)
    !advanceBook.length && dispatch(doFetchBook({ LOAI_SO: loaiSo.SO_TAM_UNG }))
    !paymentMethod.length && dispatch(doFetchPaymentMethod())
  }

  const handleClose = () => {
    setOpen(false)
    setIsCreateNew(true)
    form.resetFields()
    dispatch(clearDistrictWard())
    setReadOnly(false)
    setDoPrint(false)
  }

  // Handle Birthday
  const handleSetRequire = useCallback((bool) => {
    setBatBuoc(bool);
  }, []);
  const ruleBirth = () => {
    let rule_arr = [
      {
        required: true,
        message: "",
      },
      {
        validator: async (_, value) => {
          if (value?.length > 3) {
            batBuoc && handleSetRequire(false);

            if ([4, 8].includes(value.length)) {
              if (value.length === 8) {
                if (+value.slice(4, 8) < 1900) {
                  return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                }

                let age = convertMillisecondToAge(moment(value, "DDMMYYYY").format("YYYYMMDD"));
                if (age <= 15) {
                  handleSetRequire(true);
                }

                const formatVal = moment(value, "DDMMYYYY");
                const isFutureDay = formatVal.isAfter(moment(), "days");
                if (isFutureDay) {
                  return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                }

                if (Number(value.slice(2, 4)) == 2) {
                  if (Number(value.slice(0, 2)) > 29) {
                    return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                  }
                }
              }

              if (value.length === 4) {
                if (+value > new Date().getFullYear() || +value < 1900) {
                  return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
                }

                let nam = value + "0101";
                let age = convertMillisecondToAge(nam);
                if (age <= 15) {
                  handleSetRequire(true);
                }
                if (age <= 6) {
                  notification.error({ message: i18n.t(languageKeys.noti_vui_long_nhap_du_ngay_thang_nam), placement: "bottomLeft" });
                  return Promise.reject(new Error(i18n.t(languageKeys.noti_vui_long_nhap_du_ngay_thang_nam)));
                }
              }

              return Promise.resolve();
            } else {
              return Promise.reject(new Error(i18n.t(languageKeys.error_Thoi_gian_khong_hop_le)));
            }
          } else {
            return Promise.reject(new Error(i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang)));
          }
        },
      },
    ];

    return rule_arr;
  };
  const handleChangeDate = async (strDate) => {
    form.setFieldValue("NGAY_SINH", strDate);
  };

  // Fill form function
  const handleFillForm = (info) => {
    // Temporary list
    info.MA_QUAN_HUYEN && dispatch(getDistrictSuccess({
      result: data.huyen[info.MA_TINH_THANH]?.map((item) => ({
        MA_QUAN_HUYEN: item.MA_QUAN_HUYEN,
        TEN_QUAN_HUYEN: item.TEN_QUAN_HUYEN,
      }))
    }))
    info.MA_PHUONG_XA && dispatch(getWardSuccess({
      result: data.xa[info.MA_QUAN_HUYEN]?.map((item) => ({
        MA_PHUONG_XA: item.MA_PHUONG_XA,
        TEN_PHUONG_XA: item.TEN_PHUONG_XA,
        TEN_VIET_TAT: item.TEN_VIET_TAT,
      }))
    }))
    // Fill form
    form.setFields(Object.keys(info)?.map(item => {
      if (item === "NGAY_SINH") {
        if (info.NGAY_SINH.length === 4) {
          dateRef.current?.setDate(["", "", info.NGAY_SINH]);
        } else if (info.NGAY_SINH.length === 8) {
          let date = moment(info.NGAY_SINH, "YYYYMMDD").format("DD/MM/YYYY");
          dateRef.current?.setDate(date.split("/"));
        }
        return {
          name: "NGAY_SINH",
          value: moment(info.NGAY_SINH).format(info.NGAY_SINH.length === 4 ? "YYYY" : "DDMMYYYY")
        }
      } else if (item === "NGAY_TAO") {
        return {
          name: "NGAY_TAO",
          value: moment(info.THOI_GIAN_TAO).format("HH:mm - DD/MM/YYYY")
        }
      } else if (item === "NHANSU_ID") {
        return {
          name: "NHANSU_ID",
          value: info.NGUOI_TAO_ID
        }
      } else {
        return {
          name: item,
          value: info[item]
        }
      }
    }))
    // // Fetch district + ward
    // data.MA_TINH_THANH && dispatch(getDistrict({ MA_TINH_THANH: data.MA_TINH_THANH }));
    // data.MA_QUAN_HUYEN && dispatch(getWard({ MA_QUAN_HUYEN: data.MA_QUAN_HUYEN }));
  }

  const handleChooseOldPatient = (patient) => {
    handleFillForm(patient);
    duplicateInfoRef?.current.setVisible(false);
  }

  // Submit
  const onFinish = () => {
    isCreateNew
      ? dispatch(addPhTamUng({
        info: { ...form.getFieldValue(undefined), PHAN_LOAI: "TAM_UNG_BENH_NHAN" },
        doPrint: doPrint ? (data) => pdfRef.current?.openModalWithData(data, "phieuTamUng") : () => {},
        duplicateInfoRef: duplicateInfoRef,
        form: form,
        callback: () => {
          console.log(`onAfterSubmit && onAfterSubmit()`,);
          onAfterSubmit && onAfterSubmit()
        }
      }))
      : dispatch(editPhTamUng({
        ...form.getFieldValue(undefined),
        NHANSU_ID: userProfile.NHANSU_ID
      }))
  }

  const handleSubmit = (doPrint) => {
    const needCheckField = ['TEN', 'NGAY_SINH', 'SO_DIEN_THOAI']
    const errorArr = form.getFieldsError(needCheckField);
    const errorField = errorArr?.find(err => err?.errors?.length);
    if (errorField) {
      notification.error({
        message: i18n.t(languageKeys.trung_thong_tin),
        placement: 'bottomLeft'
      })
      return;
    }
    setDoPrint(doPrint)
    form.submit()
  }

  const handleUppercaseName = () => {
    const name = form.getFieldValue("TEN");
    form.setFields([
      {
        name: "TEN",
        value: name ? name.toUpperCase() : "",
      },
    ]);
  }

  const handleFormChange = (values) => {
    const needCheckField = ["TEN", 'NGAY_SINH', 'SO_DIEN_THOAI'];
    if (needCheckField.includes(Object?.keys(values)[0])) {
      const errorArr = form.getFieldsError(needCheckField);
      errorArr.forEach(err => {
        if (err?.errors?.includes(i18n.t(languageKeys.trung_thong_tin))) {
          form.validateFields([err?.name[0]])
        }
      })
    }
  }

  const keydownEvent = (event) => {
    if (event.ctrlKey && event.key === "l" && formRef.current?.isClose && isCreateNew) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmit(true)
    }
    if (event.ctrlKey && event.key === "s" && formRef.current?.isClose && isCreateNew) {
      event.stopPropagation();
      event.preventDefault();
      handleSubmit(false)
    }
    if (event.ctrlKey && event.key === "p" && formRef.current?.isClose && !readOnly && !isCreateNew && checkPermissions.THEM_MOI) {
      event.stopPropagation();
      event.preventDefault();
      pdfRef.current?.openModalWithData(form.getFieldValue(undefined), "phieuTamUng")
    }
    if (event.ctrlKey && event.key === "b" && formRef.current?.isClose && !readOnly && !isCreateNew && checkPermissions.HOAN_TIEN) {
      event.stopPropagation();
      event.preventDefault();
      formRef.current?.open(form.getFieldValue(undefined), true)
    }
  };
  useEventListener("keydown", keydownEvent, window.document, open);

  // Title
  const FormTitle = (
    <Space className={style["spaceDiv"]}>
      <div className={style["title"]}>
        {/* {isCreateNew && <PlusCircleFilled style={{ color: "#6576FF", fontSize: 30 }} />} */}
        <NewBackIcon onClick={handleClose} style={{ cursor: "pointer" }} />
        <h2>{
          (isCreateNew ? i18n.t(languageKeys.vien_phi_Tao) : i18n.t(languageKeys.thong_tin))
          + ' ' + i18n.t(languageKeys.phieu_thu_tam_ung)
        }</h2>
      </div>
      <Space>
        {isCreateNew && !isFromThongTinTTBenhNhan && <InputSearchPatient isRecep={false} onSelect={handleFillForm} />}
        {!isFromThongTinTTBenhNhan && (
          <Button
            type="primary"
            onClick={() => {
              dispatch(snapshotActions.setModeQR(true))
              document.getElementById('QR').focus()
            }}
            icon={<QrcodeOutlined />}
          >
            QR Code
          </Button>
        )}
      </Space>
    </Space>
  );

  // Footer
  const FormFooter = (
    <Space className={style["spaceDiv"]}>
      {(!isFromThongTinTTBenhNhan && isCreateNew) ? (
        <Checkbox checked={continuousInput} onChange={(e) => setContinuousInput(e.target.checked)}>
          {i18n.t(languageKeys.tiepdon_nhap_lien_tuc)}
        </Checkbox>
      ) : (
        <div></div>
      )}
      <Space>
        <Button type="primary" ghost onClick={handleClose}>
          {i18n.t(languageKeys.common_Thoat)} (ESC)
        </Button>
        {isCreateNew && (
          <>
            <Button type="primary" loading={loadingForm} onClick={() => handleSubmit(true)}>
              {i18n.t(languageKeys.common_Luu_va_In)} {!isFromThongTinTTBenhNhan && '(Ctrl+L)'}
            </Button>
            <Button type="primary" loading={loadingForm} onClick={() => handleSubmit(false)}>
              {i18n.t(languageKeys.common_Luu)} {!isFromThongTinTTBenhNhan && '(Ctrl+S)'}
            </Button>
          </>
        )}
        {!isCreateNew && !readOnly && (
          <>
            <Tooltip title={checkPermissions.THEM_MOI ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
              <Button
                type="primary"
                onClick={() => pdfRef.current?.openModalWithData(form.getFieldValue(undefined), "phieuTamUng")}
                disabled={!checkPermissions.THEM_MOI}
              >
                {i18n.t(languageKeys.field_In_phieu)} (Ctrl+P)
              </Button>
            </Tooltip>

            <Tooltip title={checkPermissions.HOAN_TIEN ? null : i18n.t(languageKeys.noti_ban_khong_co_quyen_cho_chuc_nang_nay)}>
              <Button
                type="primary"
                onClick={() => formRef.current?.open(form.getFieldValue(undefined), true)}
                disabled={!checkPermissions.HOAN_TIEN}
              >
                {i18n.t(languageKeys.vien_phi_Hoan_tien)} (Ctrl+B)
              </Button>
            </Tooltip>
          </>
        )}
      </Space>
    </Space>
  );

  // Main
  return (
    <Drawer width={widthDrawerKeys.medium} open={open} onClose={handleClose} title={FormTitle} footer={FormFooter} className={style["drawer"]} closable={false} maskClosable={!isCreateNew} forceRender>
      <Spin spinning={loadingForm} >
        <Form form={form} layout="vertical" onFinish={onFinish} onValuesChange={handleFormChange}>
          <Collapse
            bordered={false}
            expandIcon={({ isActive }) => {
              return isFromThongTinTTBenhNhan ? "" : <CaretRightOutlined rotate={isActive ? 90 : 0} />
            }}
            defaultActiveKey={["TTKH", "TTPTU", "LSGD"]}
          >
            {!isFromThongTinTTBenhNhan ? <Collapse.Panel header={i18n.t(languageKeys.thong_tin_khach_hang)} key="TTKH">
              <div className={style["wrapper"]}>
                <Spin
                  spinning={loadingQR ? loadingQR : modeQR}
                  indicator={(!loadingQR && modeQR) ? <QrcodeOutlined style={{ fontSize: 24 }} /> : undefined}
                >
                  <Row gutter={[10, 3]}>
                    <Col span={4}>
                      <Form.Item label={i18n.t(languageKeys.ma_khach_hang)} name={"MA_BENH_NHAN"}>
                        <Input readOnly />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label={i18n.t(languageKeys.ho_va_ten)}
                        name={"TEN"}
                        rules={[{
                          required: true,
                          message: i18n.t(languageKeys.vui_long_nhap),
                        }]}
                      >
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} onBlur={handleUppercaseName} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label={i18n.t(languageKeys.field_Gioi_tinh)}
                        name={"GIOI_TINH"}
                        rules={[{
                          required: true,
                          message: i18n.t(languageKeys.vui_long_nhap),
                        }]}
                      >
                        <Select
                          dataSource={danhSachGioiTinh}
                          valueKey='key'
                          titleKey='name'
                          disabled={!isCreateNew}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label={i18n.t(languageKeys.field_Ngay_sinh)} name={"NGAY_SINH"} rules={ruleBirth()}>
                        <DateInput tabIndex={[4, 4, 3]} onChange={handleChangeDate} ref={dateRef} readOnly={!isCreateNew} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label={i18n.t(languageKeys.sdt)}
                        name={"SO_DIEN_THOAI"}
                        rules={[
                          {
                            required: true,
                            message: i18n.t(languageKeys.vui_long_nhap),
                          },
                          {
                            pattern: /(84|0[3|5|7|8|9])+([0-9]{8,9})\b/,
                            min: 10,
                            max: 11,
                            message: i18n.t(languageKeys.noti_vui_long_nhap_dung_dinh_dang) + " " + i18n.t(languageKeys.field_So_dien_thoai).toLowerCase(),
                          },
                        ]}
                      >
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item
                        label={i18n.t(languageKeys.field_CMND_CCCD)}
                        name={"CCCD"}
                        rules={[{
                          pattern: /^[0-9]*$/,
                          message: i18n.t(languageKeys.vui_long_nhap_dung_dinh_dang),
                        }]}
                      >
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label={i18n.t(languageKeys.field_Tinh_thanh)} name={"MA_TINH_THANH"}>
                        <Select
                          dataSource={data.tinh}
                          valueKey='MA_TINH_THANH'
                          titleKey='TEN_TINH_THANH'
                          showSearch
                          filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                          onChange={(val) => {
                            dispatch(getDistrictSuccess({
                              result: data.huyen[val].map((item) => ({
                                MA_QUAN_HUYEN: item.MA_QUAN_HUYEN,
                                TEN_QUAN_HUYEN: item.TEN_QUAN_HUYEN,
                              }))
                            }));
                            dispatch(getWardSuccess({ result: [] }))
                            form.setFields([
                              { name: "TEN_TINH_THANH", value: data.tinh.find(i => i.MA_TINH_THANH === val)?.TEN_TINH_THANH },
                              { name: "MA_QUAN_HUYEN", value: null },
                              { name: "MA_PHUONG_XA", value: null },
                            ]);
                          }}
                          loading={loading}
                          disabled={!isCreateNew}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label={i18n.t(languageKeys.field_Quan_huyen)} name={"MA_QUAN_HUYEN"}>
                        <Select
                          dataSource={district}
                          showSearch
                          valueKey='MA_QUAN_HUYEN'
                          titleKey='TEN_QUAN_HUYEN'
                          filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                          onChange={(val) => {
                            dispatch(getWardSuccess({
                              result: data.xa[val].map((item) => ({
                                MA_PHUONG_XA: item.MA_PHUONG_XA,
                                TEN_PHUONG_XA: item.TEN_PHUONG_XA,
                                TEN_VIET_TAT: item.TEN_VIET_TAT,
                              }))
                            }));
                            form.setFields([
                              { name: "TEN_QUAN_HUYEN", value: district.find(i => i.MA_QUAN_HUYEN === val)?.TEN_QUAN_HUYEN },
                              { name: "MA_PHUONG_XA", value: null },
                            ]);
                          }}
                          loading={loading}
                          disabled={!isCreateNew}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={4}>
                      <Form.Item label={i18n.t(languageKeys.field_Xa_phuong)} name={"MA_PHUONG_XA"}>
                        <Select
                          dataSource={ward}
                          valueKey='MA_PHUONG_XA'
                          titleKey='TEN_PHUONG_XA'
                          showSearch
                          filterOption={(input, option) => locdau(option.children?.toString()).indexOf(input.toLowerCase()) >= 0}
                          loading={loading}
                          disabled={!isCreateNew}
                          onChange={(val) => {
                            form.setFields([
                              { name: "TEN_PHUONG_XA", value: ward.find(i => i.MA_PHUONG_XA === val)?.TEN_PHUONG_XA },
                              { name: "MA_KHU_VUC", value: ward.find(i => i.MA_PHUONG_XA === val)?.TEN_VIET_TAT },
                            ]);
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={i18n.t(languageKeys.field_Dia_chi_chi_tiet)}
                        name={"DIA_CHI_CHI_TIET"}
                      // rules={[{
                      //   required: true,
                      //   message: i18n.t(languageKeys.vui_long_nhap),
                      // }]}
                      >
                        <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} />
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>

              </div>
            </Collapse.Panel> : ""}
            <Collapse.Panel header={i18n.t(languageKeys.thong_tin_phieu_tam_ung)} key="TTPTU">
              <div className={style["wrapper"]}>
                <Row gutter={[10, 3]}>
                  <Col span={4}>
                    <Form.Item label={i18n.t(languageKeys.ma_phieu_tam_ung)} name={"MA_PHIEU"}>
                      <Input readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label={i18n.t(languageKeys.thoi_gian_tao_phieu)} name={"NGAY_TAO"} initialValue={moment().format("HH:mm - DD/MM/YYYY")}>
                      <Input readOnly />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item label={i18n.t(languageKeys.txt_nguoi_tao_phieu)} name={"NHANSU_ID"} initialValue={userProfile.NHANSU_ID}>
                      <Select
                        dataSource={personnel.map((item) => ({
                          value: item.NHANSU_ID,
                          label: item.HO + ' ' + item.TEN
                        }))
                        }
                        titleKey="label"
                        valueKey="value"
                        disabled
                      />
                    </Form.Item>
                  </Col>
                  <Col span={12} />
                  <Col span={4}>
                    <Form.Item
                      label={i18n.t(languageKeys.nguoi_nop_tien)}
                      name={"NGUOI_NOP_TIEN"}
                      rules={[{
                        required: true,
                        message: i18n.t(languageKeys.vui_long_nhap),
                      }]}
                    >
                      <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label={i18n.t(languageKeys.so_tien_tam_ung)}
                      name={"SO_TIEN"}
                      rules={[{
                        required: true,
                        message: i18n.t(languageKeys.vui_long_nhap),
                      }]}
                    >
                      <InputNumber
                        placeholder={i18n.t(languageKeys.common_Nhap)}
                        controls={false}
                        formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        readOnly={!isCreateNew}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label={i18n.t(languageKeys.field_hinh_thuc_thanh_toan)}
                      name={"HINH_THUC_THANH_TOAN"}
                      rules={[{
                        required: true,
                        message: i18n.t(languageKeys.please_chon),
                      }]}
                    >
                      <Select
                        dataSource={paymentMethod.filter(i => ![paymentMethodProto.TRA_GOP,paymentMethodProto.CONG_NO,paymentMethodProto.TIEN_DU,paymentMethodProto.TAM_UNG].some(txt => i?.PHAN_LOAI_HINH_THUC?.includes?.(txt)))}
                        valueKey='ID'
                        titleKey='TEN_HINH_THUC_THANH_TOAN'
                        onSelect={(val) => form.setFieldValue("TEN_HINH_THUC_THANH_TOAN",
                          paymentMethod.find(i => i.ID === val)?.TEN_HINH_THUC_THANH_TOAN)}
                        disabled={!isCreateNew}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item
                      label={i18n.t(languageKeys.so_tam_ung)}
                      name={"SO_HOADON_ID"}
                      rules={[{
                        required: true,
                        message: i18n.t(languageKeys.please_chon),
                      }]}
                    >
                      <Select
                        dataSource={advanceBook}
                        valueKey='ID'
                        titleKey='TEN_SO'
                        disabled={!isCreateNew}
                      // loading={loading}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item
                      label={i18n.t(languageKeys.ly_do_tam_ung)}
                      name={"LY_DO"}
                      rules={[{
                        required: true,
                        message: i18n.t(languageKeys.vui_long_nhap),
                      }]}
                    >
                      <Input placeholder={i18n.t(languageKeys.common_Nhap)} readOnly={!isCreateNew} />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </Collapse.Panel>
            {!isCreateNew && (
              <Collapse.Panel header={i18n.t(languageKeys.lich_su_giao_dich)} key="LSGD">
                <Table
                  columns={columnsLSGD}
                  dataSource={dsPhHoanUng.map((item, index) => ({ ...item, STT: index < 9 ? `0${index + 1}` : index + 1 }))}
                  rowKey={'STT'}
                  scroll={{ x: "max-content" }}
                />
              </Collapse.Panel>
            )}
          </Collapse>

          <QRInput isCreateNew={isCreateNew} open={open} form={form} dateRef={dateRef} handleFillForm={handleFillForm} isRedux={true} />

        </Form>
      </Spin>

      <FormHoanTien ref={formRef} />

      <ModalPDF ref={pdfRef} />
      <ModalTTBN
        ref={duplicateInfoRef}
        onOk={handleChooseOldPatient}
        onCancel={() => form.setFields([
          {
            name: 'TEN',
            errors: [i18n.t(languageKeys.trung_thong_tin)]
          },
          {
            name: 'SO_DIEN_THOAI',
            errors: [i18n.t(languageKeys.trung_thong_tin)]
          },
          {
            name: 'NGAY_SINH',
            errors: [i18n.t(languageKeys.trung_thong_tin)]
          },
        ])}
      />
    </Drawer>
  );
});

export default FormTamUng;

const columnsLSGD = [
  {
    title: i18n.t(languageKeys.stt),
    dataIndex: "STT",
    key: "STT",
    width: 50,
  },
  {
    title: i18n.t(languageKeys.ngay_giao_dich),
    dataIndex: "NGAY_TAO",
    key: "NGAY_TAO",
    width: 140,
    render: (DATE) => moment(DATE, "YYYYMMDD").format("DD/MM/YYYY")
  },
  {
    title: i18n.t(languageKeys.field_Ma_phieu),
    dataIndex: "MA_PHIEU",
    key: "MA_PHIEU",
    width: 110,
  },
  {
    title: i18n.t(languageKeys.loai_phieu),
    dataIndex: "LOAI_PHIEU",
    key: "LOAI_PHIEU",
    width: 150,
    render: (data) => LOAI_PHIEU_TamUng.find(item => item.value === data)?.label
  },
  {
    title: i18n.t(languageKeys.txt_So_tien),
    dataIndex: "SO_TIEN",
    key: "SO_TIEN",
    width: 140,
    render: (MONEY) => formatCurrency(MONEY, "", true)
  },
  {
    title: i18n.t(languageKeys.common_noi_dung),
    dataIndex: "LY_DO",
    key: "LY_DO",
    width: 250,
  },
  {
    title: i18n.t(languageKeys.field_hinh_thuc_thanh_toan),
    dataIndex: "TEN_HINH_THUC_THANH_TOAN",
    key: "TEN_HINH_THUC_THANH_TOAN",
    width: 180,
  },
  {
    title: i18n.t(languageKeys.txt_nguoi_tao_phieu),
    dataIndex: "NGUOI_TAO",
    key: "NGUOI_TAO",
    width: 170,
  },
]
